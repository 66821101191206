import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { DatabaseService } from '../../services/database.service';
import { AlertsService } from '../../services/alerts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GENERAL_REF } from '../../dbReferences';


@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {
  genRef;  //contains general references needed for the database/app
  dbRef; //contains text, phrases and other referencces needed for the database/app - pulled in the user's preferred language
  authUser;
  originalRoute: string;

  constructor(
    private authService: AuthService,
    private databaseService: DatabaseService,
    private alertsService: AlertsService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    //Get a reference to the general and language specific database references
    this.genRef = GENERAL_REF;
    this.databaseService.dbRef.subscribe(ref => {
      this.dbRef = ref;
    });

    this.route.queryParams.subscribe(params => {
      this.originalRoute = this.genRef.ROUTES_MAIN_APP_NO_END_SLASH + params.originalRoute;
    });

    this.authService.getAuthState().subscribe(user => {
      if(user) {
        this.authUser = user;
        if(user.emailVerified) {
          this.router.navigate([this.genRef.ROUTES_DASHBOARD]);
        }
      } else {
        this.router.navigate([this.genRef.ROUTES_HOME]);
      }
    })

  }

  sendEmailVerification() {
    if(!this.authUser.emailVerified) {
      this.authUser.sendEmailVerification({url: this.originalRoute}).then(res => {
        this.alertsService.showNewAlert({
          message: this.dbRef.VERIFY_EMAIL_ALERT_EMAIL_SENT,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_SUCCESS
        });
      }).catch(err => {
        this.alertsService.showNewAlert({
          message: this.dbRef.VERIFY_EMAIL_ALERT_EMAIL_NOT_SENT,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_DANGER
        });
      });
    }
  }

  /** Calls the auth service to sign the user out of authentication */
  logOut() {
    this.authService.signout();
    this.router.navigate([this.genRef.ROUTES_HOME]);
  }

}
