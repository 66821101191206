import { Component, OnInit } from '@angular/core';
import { GENERAL_REF } from '../../dbReferences';
import { Router, ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/services/database.service';
import { AlertsService } from '../../services/alerts.service';
import { User } from '../../models/User';
import { Notification } from '../../models/Notification';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css']
})
export class InviteComponent implements OnInit {
  genRef;  //contains general references needed for the database/app
  dbRef; //contains text, phrases and other referencces needed for the database/app - pulled in the user's preferred language
  isLoggedIn: boolean = false;  //denotes if a user is logged in, which hides the navbar if not
  user: User; //the currently logged in user
  notificationId: string; //the id for the notification
  inviteType: string; //indicates what the type of invite the link is for, can be friend, admin, or giver
  inviteId: string; //indicates what the invite is for, can be a listId for the list the user was invited to; or a userId for the friend that invited the user
  invitedUser: string; //the userId or the email address of the user that was invited
  notification: Notification; //the actual notification object pulled by notification ID
  invitationInvalid: boolean = false;
  originalInvitedUserEmail: string;  //the email address of the invited user IF they signed up/in with a different email address


  constructor(
    private alertsService: AlertsService,
    private route: ActivatedRoute,
    private router: Router,
    private databaseService: DatabaseService
  ) { }

  ngOnInit() {
    //Get a reference to the general and language specific database references
    this.genRef = GENERAL_REF;
    this.databaseService.dbRef.subscribe(ref => {
      this.dbRef = ref;
    });

    //Get the relevant notification ID from the route parameter
    this.notificationId = this.route.snapshot.params[this.genRef.ROUTES_PARAM_NOTIFICATION_ID];
    //Get the relevant invite type from the route parameter
    this.inviteType = this.route.snapshot.params[this.genRef.ROUTES_PARAM_INVITE_TYPE];
    //Get the relevant invite ID from the route parameter
    this.inviteId = this.route.snapshot.params[this.genRef.ROUTES_PARAM_INVITE_ID];
    //Get the relevant invited user from the route parameter
    this.invitedUser = this.route.snapshot.params[this.genRef.ROUTES_PARAM_INVITED_USER];

    //get the user Object from the User Behavior Subject
    this.databaseService.activeUser.subscribe(user => {
      if(user) {
        this.user = user;
        //Get the notification document so the message can be displayed
        if(this.invitedUser.includes('@') &&  this.invitedUser !== this.user.email) {
          this.originalInvitedUserEmail = this.invitedUser;
          let invitedUserSub = this.databaseService.getInvitedUser(this.invitedUser).subscribe(invitedUserDoc => {
            if(invitedUserDoc.exists) {
              let invitedUser = invitedUserDoc.data();
              if(invitedUser.notifications) {
                let numNotifications = invitedUser.notifications.length;
                invitedUser.notifications.forEach(notification => {
                  this.databaseService.addNotification(this.user.userId, notification);
                  if(notification.notificationId == this.notificationId) {
                    this.notification = notification;
                  }
                });
                this.user.notifications = invitedUser.notifications.slice(0,3);
                this.databaseService.updateUserField(this.user.userId, {
                  notifications: this.user.notifications,
                  numNotifications: numNotifications
                });
              }

              this.databaseService.deleteInvitedUser(this.originalInvitedUserEmail);
            } else {
              this.databaseService.getNotificationDocSnapshot(this.user.userId, this.notificationId).subscribe(doc => {
                if(doc.exists) {
                //If the notification doc exists, set it to the notification object variable          
                  this.notification = doc.data() as Notification;
                } else {
                //If the notification doc does not exist, show invalid message
                  this.invitationInvalid = true;
                }
              });
            }

            invitedUserSub.unsubscribe();
          });
        } else {
          this.databaseService.getNotificationDocSnapshot(this.user.userId, this.notificationId).subscribe(doc => {
            if(doc.exists) {
            //If the notification doc exists, set it to the notification object variable          
              this.notification = doc.data() as Notification;
            } else {
            //If the notification doc does not exist, show invalid message
              this.invitationInvalid = true;
            }
          });
        }
      }
    });
  }

  /**
   * Executes the invitation accept actions, depending on the invite type 
   */
  acceptInvite() {
    //If the invitation is to add a friend...
    if(this.inviteType == this.genRef.ROUTES_FRIEND) {
      //Check if the inviting user is already a friend
      let alreadyFriend: boolean = false;
      if(this.user.friends) {
        let friendName: string;
        for (let i = 0; i < this.user.friends.length; i++) {
          if(this.user.friends[i].userId == this.inviteId) {
            alreadyFriend = true;
            friendName = this.user.friends[i].name;
            break;
          }
        }

        //If the inviting user is already a friend of the current user, show a confirmation they are friends and navigate to friends page
        if(alreadyFriend) {
          this.alertsService.showNewAlert({
            message: friendName + " has been added to your friends list!",
            duration: this.genRef.ALERTS_DURATION_STANDARD,
            class: this.genRef.ALERTS_CLASS_SUCCESS
          })
          this.router.navigate([this.genRef.ROUTES_FRIENDS]);
        } 
      }

      //If the inviting user is not already a friend of the current user, add them and show confirmation
      if(!alreadyFriend) {
        //Get the inviting user's doc from the database
        let friendUserSub = this.databaseService.getUserByUID(this.inviteId).subscribe(user => {
          if(user) {
            //Create a friend object with the inviting user's info
            let newFriend = {
              userId: user.userId,
              name: user.name,
              email: user.email,
              numLists: 0 
            }
            //Add the new friend to the current user's friends array if it exists, or create it if it doesn't
            if(this.user.friends) {
              this.user.friends.unshift(newFriend);
            } else {
              this.user.friends = [newFriend];
            }

            //If the user was invited by a different email address than what they signed up for, add them as a friend to the inviting user
            if(this.invitedUser.includes('@') && this.invitedUser !== this.user.email) {
              console.log("The user was invited from a different email address...")
              //Ensure the current user is not already a friend of the inviting user
              let alreadyInvitingUsersFriend: boolean = false;
              for (let i = 0; i < user.friends.length; i++) {
                if(user.friends[i].userId == this.user.userId) {
                  alreadyInvitingUsersFriend = true;
                  break;
                }
              }
              //If the current user is not already a friend of the inviting user...
              if(!alreadyInvitingUsersFriend) {
                //Create a friend object with the current user's info
                let newUserFriend = {
                  userId: this.user.userId,
                  name: this.user.name,
                  email: this.user.email,
                  numLists: 0 
                }
                //Add the friend object to the inviting user's friends array if it exists, or create it if it doesn't
                if(user.friends) {
                  user.friends.unshift(newUserFriend);
                } else {
                  user.friends = [newUserFriend];
                }

                if(user.invitedFriends) {
                  for (let i = 0; i < user.invitedFriends.length; i++) {
                    if(user.invitedFriends[i] == this.invitedUser) {
                      user.invitedFriends.splice(i,1);
                      break;
                    }
                  }
                  for (let i = 0; i < user.invitedFriends.length; i++) {
                    if(user.invitedFriends[i] == this.originalInvitedUserEmail) {
                      user.invitedFriends.splice(i,1);
                      break;
                    }
                  }
                } else {
                  user.invitedFriends = [];
                }

                if(this.user.listedAsFriend) {
                  this.user.listedAsFriend.push(user.userId);
                } else {
                  this.user.listedAsFriend = [user.userId];
                }
              }
            }

            //Add the current user to the new friend's listedAsFriend array if it exists, or create it if it doesn't
            if(user.listedAsFriend) {
              user.listedAsFriend.push(this.user.userId);
            } else {
              user.listedAsFriend = [this.user.userId];
            }

            if(user.friends == undefined) {
              user.friends == [];
            }

            if(user.invitedFriends == undefined) {
              user.invitedFriends = [];
            }

            //Create the notification object 
            let notificationId = this.databaseService.generateNotificationId();
            let newNotification: Notification = {
              notificationId: notificationId,
              date: new Date(),
              message: this.user.name 
                + " (" 
                + this.user.email 
                + ") has accepted your invitation to be friends on Presently!",
              routerLink: this.genRef.ROUTES_FRIENDS,
              email: user.email,
              languageIndex: user.languageIndex,
              notificationIndex: user.notificationIndex
            }

            if(user.notifications) {
              user.notifications.unshift(newNotification);
              if(user.notifications.length > 3) {
                user.notifications.pop();
              }
            } else {
              user.notifications = [newNotification];
            }
            user.numTotalNotifications += 1;

            //Update the new friend's doc with the updated listedAsFriend array
            this.databaseService.updateUserField(user.userId, {
              listedAsFriend: user.listedAsFriend,
              friends: user.friends,
              invitedFriends: user.invitedFriends,
              notifications: user.notifications,
              numTotalNotifications: user.numTotalNotifications
            });

            this.databaseService.addNotification(user.userId, newNotification);

            if(this.user.listedAsFriend == undefined) {
              this.user.listedAsFriend = [];
            }

            //Update the current user's doc with the updated friends array and show confirmation
            this.databaseService.updateUserField(
              this.user.userId,
              {
                friends: this.user.friends,
                listedAsFriend: this.user.listedAsFriend
              },
              {
                message: newFriend.name + " has been added to your friends list!",
                duration: this.genRef.ALERTS_DURATION_STANDARD,
                class: this.genRef.ALERTS_CLASS_SUCCESS
              },
              {
                message: "There was a problem adding to your friends list.",
                duration: this.genRef.ALERTS_DURATION_STANDARD,
                class: this.genRef.ALERTS_CLASS_DANGER
              }
            );

            this.router.navigate([this.genRef.ROUTES_FRIENDS]);
            //Unsubscribe from the inviting user's doc
            friendUserSub.unsubscribe(); 
          }
        });
      }
    } else if (this.inviteType == this.genRef.ROUTES_ADMIN) {
//If the invitation is to be added as an admin
      //Check if the user was invited by email or userId
      let userInvitedBy: string;
      if(this.invitedUser.includes('@')) {
        //The user was invited by email
        userInvitedBy = "email";
      } else {
        //The user was invited by userId
        userInvitedBy = "userId";
      }
      //Pull the list that the invite was for from the database by the list Id
      let listSub = this.databaseService.getListByListId(this.inviteId).subscribe(list => {
        if(list) {
          this.invitationInvalid = false;  //If the list is found, ensure the invalid message is not showing (yet)
          let userInvited: boolean = false; //Will be used to indicate if the user is still on the invitedAdmin array
          let invitedAdminIndex: number;  //Will be set to the index placement of the invited user in the invitedAdmin array
          let invitedAdminNickName: string = null;
          if(list.invitedAdmin) {
            //If the user was invited by their email, check the invited admin by email
            if(userInvitedBy == "email") {
              for (let i = 0; i < list.invitedAdmin.length; i++) {
                if(list.invitedAdmin[i].email == this.invitedUser) {
                  userInvited = true;
                  invitedAdminIndex = i;
                  if(list.invitedAdmin[i].nickName) {
                    invitedAdminNickName = list.invitedAdmin[i].nickName;
                  }
                  break;
                }
              }
            } else if(userInvitedBy == "userId") {
              for (let i = 0; i < list.invitedAdmin.length; i++) {
                if(list.invitedAdmin[i].userId == this.invitedUser) {
                  userInvited = true;
                  invitedAdminIndex = i;
                  if(list.invitedAdmin[i].nickName) {
                    invitedAdminNickName = list.invitedAdmin[i].nickName;
                  }
                  break;
                }
              }
            }
          }

          //If the user is invited to the list, add them as an admin
          if(userInvited) {
            //Create a new admin object with the current user's info
            let newAdmin = {
              userId: this.user.userId,
              name: this.user.name,
              email: this.user.email,
              nickName: invitedAdminNickName
            }

            //Add the newAdmin object to the admin array of the list, or create it if it doesn't exist
            if(list.admin) {
              list.admin.unshift(newAdmin);
            } else {
              list.admin = [newAdmin];
            }
            //Remove the invited user from the invitedAdmin array
            list.invitedAdmin.splice(invitedAdminIndex,1);
            
            //Update the list doc with the updated admin and invitedAdmin arrays
            this.databaseService.updateListField(
              list.listId,
              {
                admin: list.admin,
                invitedAdmin: list.invitedAdmin
              },
              {
                message: "You are now an admin on " + list.name,
                duration: this.genRef.ALERTS_DURATION_STANDARD,
                class: this.genRef.ALERTS_CLASS_SUCCESS
              },
              {
                message: "There was a problem adding you as an admin to the list.",
                duration: this.genRef.ALERTS_DURATION_STANDARD,
                class: this.genRef.ALERTS_CLASS_DANGER
              }
            );

            //Update this user's adminLists with the new list info
            //Get the nickname of the owner, if it exists on the user's friends list
            let ownerNickName: string = null;
            if(this.user.friends) {
              for (let i = 0; i < this.user.friends.length; i++) {
                if(this.user.friends[i].userId == list.owner.userId) {
                  if(this.user.friends[i].nickName) {
                    ownerNickName = this.user.friends[i].nickName;
                  }
                  break;
                }
              }
            }
            //Create a new admin list object with the new list info
            let newAdminList = {
              listId: list.listId,
              listName: list.name,
              ownerUserId: list.owner.userId,
              ownerName: list.owner.name,
              ownerNickName: ownerNickName,
              surpriseIndex: list.surpriseIndex
            }
            //Add the new admin list object to the adminLists array or create it if it doesn't exist
            if(this.user.adminLists) {
              this.user.adminLists.unshift(newAdminList);
            } else {
              this.user.adminLists = [newAdminList];
            }

            let newFriend = {
              userId: list.owner.userId,
              name: list.owner.name,
              email: list.owner.email,
              numLists: 0
            }

            let alreadyFriend: boolean = false;
            if(this.user.friends) {
              for (let i = 0; i < this.user.friends.length; i++) {
                if(this.user.friends[i].userId == list.owner.userId) {
                  alreadyFriend = true;
                  break;
                }
              }
              if(!alreadyFriend) {
                this.user.friends.push(newFriend);
              }
            } else {
              this.user.friends = [newFriend];
            }

            if(!alreadyFriend) {
              if(this.user.listedAsFriend) {
                this.user.listedAsFriend.push(newFriend.userId);
              } else {
                this.user.listedAsFriend = [newFriend.userId];
              }
            }

            //Update the current user's doc with the new adminLists array
            this.databaseService.updateUserField(this.user.userId, {
              adminLists: this.user.adminLists,
              friends: this.user.friends,
              listedAsFriend: this.user.listedAsFriend
            })

            //Add a notification to the list owner's notifications array that the invite was accepted
            let listOwnerSub = this.databaseService.getUserByUID(list.owner.userId).subscribe(owner => {

              //Create the notification object 
              let notificationId = this.databaseService.generateNotificationId();
              let newNotification: Notification = {
                notificationId: notificationId,
                date: new Date(),
                message: this.user.name 
                  + " (" 
                  + this.user.email 
                  + ") has accepted your invitation to be an admin on " 
                  + list.name 
                  + "!",
                routerLink: this.genRef.ROUTES_LIST
                  + '/' + list.listId,
                email: owner.email,
                languageIndex: owner.languageIndex,
                notificationIndex: owner.notificationIndex
              }

              if(owner.notifications) {
                owner.notifications.unshift(newNotification);
                if(owner.notifications.length > 3) {
                  owner.notifications.pop();
                }
              } else {
                owner.notifications = [newNotification];
              }
              owner.numTotalNotifications += 1;

              let newFriend = {
                userId: this.user.userId,
                name: this.user.name,
                email: this.user.email,
                numLists: 0
              }
  
              let alreadyFriend: boolean = false;
              if(owner.friends) {
                for (let i = 0; i < owner.friends.length; i++) {
                  if(owner.friends[i].userId == this.user.userId) {
                    alreadyFriend = true;
                    break;
                  }
                }
                if(!alreadyFriend) {
                  owner.friends.push(newFriend);
                }
              } else {
                owner.friends = [newFriend];
              }

              if(owner.invitedFriends) {
                for (let i = 0; i < owner.invitedFriends.length; i++) {
                  if(owner.invitedFriends[i] == this.user.email) {
                    owner.invitedFriends.splice(i,1);
                    break;
                  }
                }
              } else {
                owner.invitedFriends = [];
              }

              if(!alreadyFriend) {
                if(owner.listedAsFriend) {
                  owner.listedAsFriend.push(this.user.userId);
                } else {
                  owner.listedAsFriend = [this.user.userId];
                }
              }

              this.databaseService.updateUserField(list.owner.userId, {
                notifications: owner.notifications,
                numTotalNotifications: owner.numTotalNotifications,
                friends: owner.friends,
                invitedFriends: owner.invitedFriends,
                listedAsFriend: owner.listedAsFriend
              });

              //Add a notification to the list owners notifications collection that the invite was accepted
              this.databaseService.addNotification(list.owner.userId, newNotification);

              listOwnerSub.unsubscribe();
            });

            //Navigate to the list
            this.router.navigate([this.genRef.ROUTES_LIST + '/' + list.listId]);
          } else {
          //If the user is no longer on the invitedAdmin list, show the invalid message
          this.invitationInvalid = true;
          }
        } else {
          //If the list can't be found, show the invalid message
          this.invitationInvalid = true;
        }
        listSub.unsubscribe();
      });
    } else if (this.inviteType == this.genRef.ROUTES_GIVER) {
//If the invitation is to be added as a giver...
      //Check if the user was invited by email or userId
      let userInvitedBy: string;
      if(this.invitedUser.includes('@')) {
        //The user was invited by email
        userInvitedBy = "email";
      } else {
        //The user was invited by userId
        userInvitedBy = "userId";
      }
      //Pull the list that the invite was for from the database by the list Id
      let listSub = this.databaseService.getListByListId(this.inviteId).subscribe(list => {
        if(list) {
          this.invitationInvalid = false;  //If the list is found, ensure the invalid message is not showing (yet)
          let userInvited: boolean = false; //Will be used to indicate if the user is still on the invitedGivers array
          let invitedGiversIndex: number;  //Will be set to the index placement of the invited user in the invitedGivers array
          let invitedGiverNickName: string = null;
          if(list.invitedGivers) {
            //If the user was invited by their email, check the invited giver by email
            if(userInvitedBy == "email") {
              for (let i = 0; i < list.invitedGivers.length; i++) {
                if(list.invitedGivers[i].email == this.invitedUser) {
                  userInvited = true;
                  invitedGiversIndex = i;
                  if(list.invitedGivers[i].nickName) {
                    invitedGiverNickName = list.invitedGivers[i].nickName;
                  }
                  break;
                }
              }
            } else if(userInvitedBy == "userId") {
              for (let i = 0; i < list.invitedGivers.length; i++) {
                if(list.invitedGivers[i].userId == this.invitedUser) {
                  userInvited = true;
                  invitedGiversIndex = i;
                  if(list.invitedGivers[i].nickName) {
                    invitedGiverNickName = list.invitedGivers[i].nickName;
                  }
                  break;
                }
              }
            }
          }

          //If the user is invited to the list, add them as a giver
          if(userInvited) {
            //Create a new giver object with the current user's info
            let newGiver = {
              userId: this.user.userId,
              name: this.user.name,
              email: this.user.email,
              nickName: invitedGiverNickName
            }

            //Add the newGiver object to the givers array of the list, or create it if it doesn't exist
            if(list.givers) {
              list.givers.unshift(newGiver);
            } else {
              list.givers = [newGiver];
            }
            //Remove the invited user from the invitedGivers array
            list.invitedGivers.splice(invitedGiversIndex,1);
            
            //Update the list doc with the updated givers and invitedGivers arrays
            this.databaseService.updateListField(
              list.listId,
              {
                givers: list.givers,
                invitedGivers: list.invitedGivers
              },
              {
                message: "You are now a giver on " + list.name,
                duration: this.genRef.ALERTS_DURATION_STANDARD,
                class: this.genRef.ALERTS_CLASS_SUCCESS
              },
              {
                message: "There was a problem adding you as a giver to the list.",
                duration: this.genRef.ALERTS_DURATION_STANDARD,
                class: this.genRef.ALERTS_CLASS_DANGER
              }
            );

            //Update this user's friendsLists with the new list info
            //Get the nickname of the owner, if it exists on the user's friends list
            let ownerNickName: string = null;
            if(this.user.friends) {
              for (let i = 0; i < this.user.friends.length; i++) {
                if(this.user.friends[i].userId == list.owner.userId) {
                  if(this.user.friends[i].nickName) {
                    ownerNickName = this.user.friends[i].nickName;
                  }
                  break;
                }
              }
            }
            //Create a new friends list object with the new list info
            let newFriendsList = {
              listId: list.listId,
              listName: list.name,
              ownerUserId: list.owner.userId,
              ownerName: list.owner.name,
              ownerNickName: ownerNickName,
            }

            //Add the new friend list object to the friendsLists array or create it if it doesn't exist
            if(this.user.friendsLists) {
              this.user.friendsLists.unshift(newFriendsList);
            } else {
              this.user.friendsLists = [newFriendsList];
            }

            let newFriend = {
              userId: list.owner.userId,
              name: list.owner.name,
              email: list.owner.email,
              numLists: 1
            }

            let alreadyFriend: boolean = false;
            if(this.user.friends) {
              for (let i = 0; i < this.user.friends.length; i++) {
                if(this.user.friends[i].userId == list.owner.userId) {
                  alreadyFriend = true;
                  this.user.friends[i].numLists += 1;
                  break;
                }
              }
              if(!alreadyFriend) {
                this.user.friends.push(newFriend);
              }
            } else {
              this.user.friends = [newFriend];
            }

            if(!alreadyFriend) {
              if(this.user.listedAsFriend) {
                this.user.listedAsFriend.push(newFriend.userId);
              } else {
                this.user.listedAsFriend = [newFriend.userId];
              }
            }

            //Update the current user's doc with the new friendsLists array
            this.databaseService.updateUserField(this.user.userId, {
              friendsLists: this.user.friendsLists,
              friends: this.user.friends,
              listedAsFriend: this.user.listedAsFriend
            });

            //Update the list owner that the invite was accepted
            let listOwnerSub = this.databaseService.getUserByUID(list.owner.userId).subscribe(owner => {

              //Create the notification object 
              let notificationId = this.databaseService.generateNotificationId();
              let newNotification: Notification = {
                notificationId: notificationId,
                date: new Date(),
                message: this.user.name 
                  + " (" 
                  + this.user.email 
                  + ") has accepted your invitation to be a giver on " 
                  + list.name 
                  + "!",
                routerLink: this.genRef.ROUTES_LIST
                  + '/' + list.listId,
                email: owner.email,
                languageIndex: owner.languageIndex,
                notificationIndex: owner.notificationIndex
              }

              //Add the notification to the list owner's notifications array
              if(owner.notifications) {
                owner.notifications.unshift(newNotification);
                if(owner.notifications.length > 3) {
                  owner.notifications.pop();
                }
              } else {
                owner.notifications = [newNotification];
              }

              owner.numTotalNotifications += 1;

              //Increment the numGivers in the myLists array
              if(owner.myLists) {
                for (let i = 0; i < owner.myLists.length; i++) {
                  if(owner.myLists[i].listId == list.listId) {
                    owner.myLists[i].numGivers += 1;
                    break;
                  }
                }
              } else {
                owner.myLists = [];
              }

              let newFriend = {
                userId: this.user.userId,
                name: this.user.name,
                email: this.user.email,
                numLists: 0
              }
  
              let alreadyFriend: boolean = false;
              if(owner.friends) {
                for (let i = 0; i < owner.friends.length; i++) {
                  if(owner.friends[i].userId == this.user.userId) {
                    alreadyFriend = true;
                    break;
                  }
                }
                if(!alreadyFriend) {
                  owner.friends.push(newFriend);
                }
              } else {
                owner.friends = [newFriend];
              }

              if(owner.invitedFriends) {
                for (let i = 0; i < owner.invitedFriends.length; i++) {
                  if(owner.invitedFriends[i] == this.user.email) {
                    owner.invitedFriends.splice(i,1);
                    break;
                  }
                }
              } else {
                owner.invitedFriends = [];
              }

              if(!alreadyFriend) {
                if(owner.listedAsFriend) {
                  owner.listedAsFriend.push(this.user.userId);
                } else {
                  owner.listedAsFriend = [this.user.userId];
                }
              }

              //Update the list owner's doc with the notification and myLists arrays
              this.databaseService.updateUserField(list.owner.userId, {
                notifications: owner.notifications,
                numTotalNotifications: owner.numTotalNotifications,
                myLists: owner.myLists,
                friends: owner.friends,
                invitedFriends: owner.invitedFriends,
                listedAsFriend: owner.listedAsFriend
              });

              //Add a notification to the list owners notifications collection that the invite was accepted
              this.databaseService.addNotification(list.owner.userId, newNotification);

              //Unsubscribe from the list owner doc
              listOwnerSub.unsubscribe();
            });
            
            //Update the list admin that the invite was accepted
            if(list.admin) {
              list.admin.forEach(listAdmin => {
                let adminSub = this.databaseService.getUserByUID(listAdmin.userId).subscribe(admin => {
 
                //Create the notification object 
                let notificationId = this.databaseService.generateNotificationId();
                let newNotification: Notification = {
                  notificationId: notificationId,
                  date: new Date(),
                  message: this.user.name 
                    + " (" 
                    + this.user.email 
                    + ") has accepted your invitation to be a giver on " 
                    + list.name 
                    + "!",
                  routerLink: this.genRef.ROUTES_LIST
                    + '/' + list.listId,
                  email: admin.email,
                  languageIndex: admin.languageIndex,
                  notificationIndex: admin.notificationIndex
                }

                  //Add the notification to the list admin's notifications array
                  if(admin.notifications) {
                    admin.notifications.unshift(newNotification);
                    if(admin.notifications.length > 3) {
                      admin.notifications.pop();
                    }
                  } else {
                    admin.notifications = [newNotification];
                  }
                  admin.numTotalNotifications += 1;
                  //Update the list owner's doc with the notification and myLists arrays
                  this.databaseService.updateUserField(admin.userId, {
                    notifications: admin.notifications,
                    numTotalNotifications: admin.numTotalNotifications
                  });

                  //Add a notification to the list admins notifications collection that the invite was accepted
                  this.databaseService.addNotification(listAdmin.userId, newNotification);
  
                  //Unsubscribe from the admin's user doc
                  adminSub.unsubscribe();
                });

              });
            }

            //Navigate to the list
            this.router.navigate([this.genRef.ROUTES_LIST + '/' + list.listId]);
          } else {
          //If the user is no longer on the invitedGivers list, show the invalid message
          this.invitationInvalid = true;
          }
        } else {
          //If the list can't be found, show the invalid message
          this.invitationInvalid = true;
        }
        listSub.unsubscribe();
      });
    }
  }

  ignoreInvite() {
    this.router.navigate([this.genRef.ROUTES_DASHBOARD]);
  }

}
