import { Component, OnInit } from '@angular/core';
import { GENERAL_REF } from '../../dbReferences';
import { DatabaseService } from 'src/app/services/database.service';
import { Router, ActivatedRoute } from '@angular/router';
import { List } from '../../models/List';
import { User } from '../../models/User'

@Component({
  selector: 'app-thank-you-list',
  templateUrl: './thank-you-list.component.html',
  styleUrls: ['./thank-you-list.component.css']
})
export class ThankYouListComponent implements OnInit {
  genRef;  //contains general references needed for the database/app
  dbRef; //contains text, phrases and other referencces needed for the database/app - pulled in the user's preferred language
  user: User;  //the User object for the current user  
  list: List; //the original List object directly from the database that can't be changed via the UI unless changes are sent to the database first.
  listId: string; //the unique ID of the list that is being displayed.  Equal to the firebase doc ID for this list document.
  listDate: Date;
  thankYouList: {
    purchaserUserId?: string,
    purchaserName?: string,
    purchaserNickName?: string,
    purchaserEmail?: string,
    purchases?: {
      itemId?: string,
      itemTitle?: string,
      itemUrl?: string,
      quantityPurchased?: number
    }[]
  }[];
  listPrivilege: string;  //a variable that indicates the current privilege level of the current user for the current list (Owner, Admin, Giver, or NotAuth)


  constructor(
    private databaseService: DatabaseService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    //Get a reference to the general and language specific database references
    this.genRef = GENERAL_REF;
    let dbRefSub = this.databaseService.dbRef.subscribe(ref => {
      this.dbRef = ref;
    });
    dbRefSub.unsubscribe();

    //get the user Object from the User Behavior Subject
    let activeUserSub = this.databaseService.activeUser.subscribe(user => {
      if(user != null) {
        this.user = user;
        
        //Get the relevant listId from the route parameter
        this.listId = this.route.snapshot.params[this.genRef.ROUTES_PARAM_LIST_ID];
      
        //Pull the List object from the Behavior Subject, if it exists or query the database if needed
        //Anytime the database list is updated, the activeList Behavior Subject will be updated as well
        this.databaseService.activeList.subscribe(list => {
          //If there is no activeList object, then query the database for the List by listId
          if(list == null && this.listId !== "blocked"){
            console.log('THE ACTIVE LIST IS NULL')
            this.getUpdatedList();
          } else if(list.listId == this.listId || this.listId == "blocked") { 
            //If there is an activeList and the listId is the same as the listId from the route parameter, set that as the current list
            //Set the returned List object to the list variable
            this.listId = "blocked";
            console.log('THE LIST ID MATCHES*****')
            this.list = list;
            this.generateThankYouList();
            this.setListPrivilege();
            console.log(this.thankYouList);
          } else if(list.listId !== this.listId && this.listId !== "blocked") {
            //If there is an activeList but it is not the same as the listId from the route parameter, query the database for the List by listId
            console.log('THE LIST ID DOES NOT MATCH**********')
            this.getUpdatedList();
          }
        });
      }
    });    

    this.listDate = new Date();
        
  }

  getUpdatedList() {
    if(this.listId !== "blocked"){
      let listSub = this.databaseService.getListByListId(this.listId).subscribe(list => {
        this.databaseService.updateActiveList(list);
        listSub.unsubscribe();
      });
    }
  }

  generateThankYouList() {
    this.thankYouList = [];
    if(this.list.items) {

      //For each item on the list
      for (let i = 0; i < this.list.items.length; i++) {
        let currentItem = this.list.items[i];

        //Check and see if there are any purchases
        if(currentItem.purchasedBy){

          //For each purchase entry
          for (let x = 0; x < currentItem.purchasedBy.length; x++) {
            let currentPurchaser = currentItem.purchasedBy[x];

            //If the thankYouList is empty, add the purchaser and item to the list
            if(this.thankYouList.length == 0) {
              this.thankYouList.push({
                purchaserUserId: currentPurchaser.userId,
                purchaserName: currentPurchaser.name,
                purchaserNickName: currentPurchaser.nickName,
                purchaserEmail: currentPurchaser.email,
                purchases: [{
                  itemId: currentItem.itemId,
                  itemTitle: currentItem.title,
                  itemUrl: currentItem.url,
                  quantityPurchased: currentPurchaser.quantityPurchased
                }]
              });
            } else {
              //Check to see if the purchaser is already listed for a different item
              let alreadyOnList: boolean = false;
              let thankYouListIndex: number;
              for (let y = 0; y < this.thankYouList.length; y++) {
                if(this.thankYouList[y].purchaserUserId == currentPurchaser.userId) {
                  thankYouListIndex = y;
                  alreadyOnList = true;
                  break;     
                } 
              }
              
              if(alreadyOnList){
                //If purchaser is already on thankYouList, add the current item to their purchased list
                this.thankYouList[thankYouListIndex].purchases.push({
                  itemId: currentItem.itemId,
                  itemTitle: currentItem.title,
                  itemUrl: currentItem.url,
                  quantityPurchased: currentPurchaser.quantityPurchased
                })
              } else {
                //If purchaser is NOT already on thankYouList, add them to list along with the item purchased
                this.thankYouList.push({
                  purchaserUserId: currentPurchaser.userId,
                  purchaserName: currentPurchaser.name,
                  purchaserNickName: currentPurchaser.nickName,
                  purchaserEmail: currentPurchaser.email,
                  purchases: [{
                    itemId: currentItem.itemId,
                    itemTitle: currentItem.title,
                    itemUrl: currentItem.url,
                    quantityPurchased: currentPurchaser.quantityPurchased
                  }]
                })
              }
            }
          }
        }
      }
    }
  }

    /**
   * Determines the privilege level of the current user for the current list
   * Can be either Owner, Admin, Giver, or NotAuth
   */
  setListPrivilege() {
    if(this.user.userId === this.list.owner.userId) {
      //Check if the current userId is equal to the list owner userId and if so set the privilege to Owner
      this.listPrivilege = this.genRef.LIST_PRIVILEGES_OWNER;
      return;
    } else { //Check if the current userId is equal to any of the list admin userId's and if so set the privilege to Admin
      let isAdmin: boolean = false;
      for (let i = 0; i < this.list.admin.length; i++) {
        if (this.user.userId == this.list.admin[i].userId) {
          isAdmin = true;
          break;
        }
      }
      if (isAdmin) {
        this.listPrivilege = this.genRef.LIST_PRIVILEGES_ADMIN;
        return;
      } else {  //Check if the current userId is equal to any of the list Givers userId's and if so set the privilege to Giver
        let isGiver: boolean = false;
        for (let i = 0; i < this.list.givers.length; i++) {
          if (this.user.userId == this.list.givers[i].userId) {
            isGiver = true;
            break;
          }
        }
        if (isGiver) {
          this.listPrivilege = this.genRef.LIST_PRIVILEGES_GIVER;
          return;
        } else {  //If the userId does not match any of the above privileges, set to NotAuth which will not allow the list to be shown
          this.listPrivilege = this.genRef.LIST_PRIVILEGES_NOT_AUTH;
          return;
        }
      }
    }
  }

}
