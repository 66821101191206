import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';


@Injectable({
  providedIn: 'root'
})
export class StorageService {
  task: AngularFireUploadTask;  // Main task 


  constructor(
    private storage: AngularFireStorage
  ) { }


  // Sends a file to upload to the specified cloud storage bucket
  startUpload(path, selectedFile) {
    return this.task = this.storage.upload(path, selectedFile)
  }

  // Deletes the specified file from Firebase Cloud Storage
  deleteFile(path: string) {
    //Check if there is a profile pic to delete
    if(path !== '' || path !== null) {
        //Delete the file
      this.storage.ref(path).delete().subscribe(res => {
        console.log("The file was successfully deleted " + res);
      }, err => {
        console.log("There was a problem deleting the file.");
        console.log(err);
      });
    } else {
      // Notify user can't delete
    }
  }



}
