import { Component, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/services/alerts.service';
import { Alert } from '../../models/Alert';

/**
 * Shows an alert whenever a new Alert object is passed in to the currentAlert behavior subject - see AlertsService
 */

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {
  alert: Alert; //The alert object to display

  constructor(private alertsService: AlertsService) { }

  ngOnInit() {
    //Subscribe to the currentAlert behavior subject from Alerts Service so that any time a new alert is passed in, it is displayed
    this.alertsService.currentAlert.subscribe(alert => {
      this.alert = alert;
    })
  }

}
