import { Component, OnInit } from '@angular/core';
import { GENERAL_REF } from '../../dbReferences';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DatabaseService } from 'src/app/services/database.service';
import { AlertsService } from '../../services/alerts.service';
import { User } from 'src/app/models/User';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  genRef;  //contains general references needed for the database/app
  dbRef; //contains text, phrases and other referencces needed for the database/app - pulled in the user's preferred language
  isLoggedIn: boolean = false;  //denotes if a user is logged in, which hides the navbar if not
  user: User; //the currently logged in user
  currentRoute: string //the current url route

  constructor(
    private authService: AuthService,
    private alertsService: AlertsService,
    private router: Router,
    private route: ActivatedRoute,
    private databaseService: DatabaseService
  ) { }

  ngOnInit() {
    //Get a reference to the general and language specific database references
    this.genRef = GENERAL_REF;
    this.databaseService.dbRef.subscribe(ref => {
      this.dbRef = ref;
    });

    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          this.currentRoute = this.router.url;
        }
      }
    );

    // If the user is not logged in, then the navbar will not display.  This section is also responsible for 
    // pulling the user information from the database and updating the active user behavior subject with that data for use 
    //throughout the app.
    this.authService.getAuthState().subscribe(auth => {
      if(auth && auth.emailVerified) {
        this.isLoggedIn = true;
        this.databaseService.activeUser.subscribe(user => {
          if(user !== null && this.user !== null && this.user !== undefined) {
            if(user.notifications) {
              if(user.notifications[0].message !== this.user.notifications[0].message) {
                this.alertsService.showNewAlert({
                  message: user.notifications[0].message,
                  duration: this.genRef.ALERTS_DURATION_STANDARD,
                  class: this.genRef.ALERTS_CLASS_PRIMARY
                });
              }
            }
          }
          if(user == null && auth){
            this.databaseService.getUserByUID(auth.uid).subscribe(user => {
              if(user) {
                this.databaseService.updateActiveUser(user);
              }
            });
            this.databaseService.getUserMeta();
          } else {
            this.user = user;
          }
        })
      } else if (auth && !auth.emailVerified) {
        this.router.navigate([this.genRef.ROUTES_VERIFY_EMAIL], {queryParams: {originalRoute: this.currentRoute}});
      } else {
        this.isLoggedIn = false;
      }
    });
  }

  /** Calls the auth service to sign the user out of authentication */
  logOut() {
    this.isLoggedIn = false;
    this.authService.signout();
  }

  newList() {
    //refresh component in case List Component is already open so that data refreshes
    this.router.navigateByUrl('/RefreshComponent', {skipLocationChange: true}).then(()=> {
      this.router.navigate([this.genRef.ROUTES_NEW_LIST]);
    }); 
  }

}
