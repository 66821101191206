import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GENERAL_REF } from '../dbReferences';

/**
 * Prevents any component from loading and redirects to the sign in page if there is no active authenticated session
 */

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private afAuth: AngularFireAuth
        ) { }

    canActivate(): Observable<boolean> {
        return this.afAuth.user.pipe(map(user => {
            if(!user) {
                this.router.navigate([GENERAL_REF.ROUTES_HOME]);
                return false;
            } else {
                if(!user.emailVerified) {
                    this.router.navigate([GENERAL_REF.ROUTES_VERIFY_EMAIL]);
                    return false;
                } else {
                    return true;
                }
            }
        }));
    }
}