import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from '../../services/storage.service';
import { DatabaseService } from '../../services/database.service';
import { GENERAL_REF } from '../../dbReferences';

@Component({
  selector: 'app-uploads',
  templateUrl: './uploads.component.html',
  styleUrls: ['./uploads.component.css']
})
export class UploadsComponent implements OnInit {
  genRef;  //contains general references needed for the database/app
  dbRef; //contains text, phrases and other referencces needed for the database/app - pulled in the user's preferred language
  @Input() uploadIdentifier: string;  // the unique ID for this image (i.e. listId/itemId)
  @Input() uploadFolder: string;  // the bucket folder to save this particular upload to in Storage Bucket
  selectedFile: File; // the file object of the document uploaded by the user
  selectedFileName: string; // The file name of the document uploaded by the user (initialized with default verbiage).
  percentage: Observable<number>;  // the current percentage complete of the upload progress 
  readyForUpload: boolean = true; // indicates an upload is not currently in progress or canceled
  @Output() cancelUpload = new EventEmitter<boolean>();  // An event emitter that indicates the upload process is canceled by user
  @Output() uploadStarted = new EventEmitter<boolean>();  // An event emitter that indicates the upload process is started by user



  constructor(
    private databaseService: DatabaseService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    //Get a reference to the general and language specific database references
    this.genRef = GENERAL_REF;
    this.databaseService.dbRef.subscribe(ref => {
      this.dbRef = ref;
      this.selectedFileName = this.dbRef.UPLOAD_FILE_PLACEHOLDER;
    });
  }

  // Detect when a file is added to the input area and apply the file and name to appropriate variables
  detectFiles(event) {
    this.selectedFile = event.target.files[0];
    this.selectedFileName = this.selectedFile.name;
  }

  // Creates the upload data and completes upload service request
  async startUpload() {
    try {
      // Verify the file type is an image
      if (this.selectedFile.type.split('/')[0] !== 'image') { 
        console.error('unsupported file type :( ')
        return;
      } else {
        this.readyForUpload = false;
        // Create a timestamp to ensure the filename is unique
        const timestamp = (new Date).getTime();
        // Create a path to upload the selected file to
        const uploadPath = this.uploadFolder 
          + "/" 
          + this.genRef.STORAGE_PREFIX_UNSIZED
          + this.uploadIdentifier
          + timestamp;
        // Create the upload task
        const uploadTask = this.storageService.startUpload(uploadPath, this.selectedFile);
        // Subscribe to the percentage changes as the upload progresses
        this.percentage = uploadTask.percentageChanges();
        this.percentage.subscribe(data => {
          // Get percentages as the file uploads - this data is read by the html file to show progress
        });
        // Start the upload task
        await uploadTask;
        this.uploadStarted.emit(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  cancelUploadFile() {
    this.cancelUpload.emit(true);
  }

}
