import { Component, OnInit, Input } from '@angular/core';
import { GENERAL_REF } from '../../dbReferences';
import { DatabaseService } from '../../services/database.service';
import { AuthService } from '../../services/auth.service';
import { AlertsService } from '../../services/alerts.service'
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../../models/User';

/**
 * This component is used to log in or create a new account.  Can also change the current language preference.
 */

@Component({
  selector: 'app-enter-app',
  templateUrl: './enter-app.component.html',
  styleUrls: ['./enter-app.component.css']
})
export class EnterAppComponent implements OnInit {
  isCreatingAccount: boolean = false; //denotes if the user is creating a new account or signing in
  genRef;  //contains general references needed for the database/app  
  dbRef; //contains text, phrases and other referencces needed for the database/app - pulled in the user's preferred language
  user: User; //the User object for the current user
  selectedLanguagePref: number;  //An index number referring to which language the user prefers from PROFILE_TEXT_LANGUAGE_PREFERENCE_OPTIONS array
  isResettingPassword: boolean = false; //used to indicate whether to show password reset form or not
//The following variables are used to create a route param to pass along with the email verification link in case the user 
//creates an account from the invite or give components; so they can be routed back to the invite or give component as needed  
  @Input() parentComponent: string; //indicates which component the enter-app component is opened from
  notificationId: string; //the id for the notification if routed from an invite
  inviteType: string; //indicates what the type of invite the link is for, can be friend, admin, or giver if routed from an invite
  inviteId: string; //indicates what the invite is for, can be a listId for the list the user was invited to; or a userId for the friend that invited the user if routed from an invite
  inviteUser: string; //the userId or the email address of the user that was invited if routed from an invite
  shareCode: string //the share code of the list
  originalRoute: string;  //the complete invite route

  constructor(
    private databaseService: DatabaseService,
    private authService: AuthService,
    private alertsService: AlertsService,
    private router: Router,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    //Get a reference to the language specific database reference
    this.genRef = GENERAL_REF;    
    this.databaseService.dbRef.subscribe(ref => {
      this.dbRef = ref;
    })
    //Set the language preference to the default 
    this.selectedLanguagePref = this.genRef.DB_DEFAULT_LANGUAGE_PREF;
    //Set the originalRoute variable to equal the router link that was used to navigate to this page, this can be determined
    //based on which parent component initialized this component.
    //If the parent component is Home, set the original route to the home component route
    if(this.parentComponent == this.genRef.ENTERAPP_PARENT_HOME) {
      this.originalRoute = this.genRef.ROUTES_MAIN_APP + this.genRef.ROUTES_DASHBOARD;
    } else if (this.parentComponent == this.genRef.ENTERAPP_PARENT_INVITE) {
    //If the parent component is Invite, set the original route to the invite component route, based on the route params    
      //Get the relevant notification ID from the route parameter, if it exists
      this.notificationId = this.route.snapshot.params[this.genRef.ROUTES_PARAM_NOTIFICATION_ID];
      //Get the relevant invite type from the route parameter, if it exists
      this.inviteType = this.route.snapshot.params[this.genRef.ROUTES_PARAM_INVITE_TYPE];
      //Get the relevant invite ID from the route parameter, if it exists
      this.inviteId = this.route.snapshot.params[this.genRef.ROUTES_PARAM_INVITE_ID];
      //Get the relevant invited user from the route parameter, if it exists
      this.inviteUser = this.route.snapshot.params[this.genRef.ROUTES_PARAM_INVITED_USER];
      //re-create the complete invite route from the route parameters, if they exist
      this.originalRoute = this.genRef.ROUTES_MAIN_APP + this.genRef.ROUTES_INVITE 
      + '/' + this.notificationId
      + '/' + this.inviteType
      + '/' + this.inviteId
      + '/' + this.inviteUser;
    } else if(this.parentComponent == this.genRef.ENTERAPP_PARENT_GIVE) {
    //If the parent component is Give, set the original route to the give component route, based on the shareCode param
      //Get the relevant share code from the route parameter
      this.shareCode = this.route.snapshot.params[this.genRef.ROUTES_PARAM_SHARE_CODE];
      if(this.shareCode) {
        this.originalRoute = this.genRef.ROUTES_MAIN_APP + this.genRef.ROUTES_GIVE
        + '/' + this.shareCode;
      } else {
        this.originalRoute = this.genRef.ROUTES_MAIN_APP + this.genRef.ROUTES_GIVE
      }
    }
  }

  //Logs the user in with the given email & password via Firebase Auth service
  logInSubmit({value}: {value: {email: string, password: string}}) {
    this.authService.signin(value.email, value.password).then(res => {
      this.databaseService.updateActiveUser(null);

    }).catch(err => {
      //Show an alert if login fails
      this.alertsService.showNewAlert({
        message: this.dbRef.ENTERAPP_ALERT_ERROR_LOGGING_IN, 
        duration: this.genRef.ALERTS_DURATION_STANDARD,
        class: this.genRef.ALERTS_CLASS_DANGER
      });
    });
    
  }

  //Creates a new user in Firebase Auth with the given email, password and user data
  createAccount({value, valid}: {value: {email: string, password: string, name: string}, valid: boolean}): void {
    if(valid){
      //Create a new user object based on the input values and defaults
      this.user = {
        userId: this.genRef.ENTERAPP_TEXT_TEMP_USER_ID,
        name: value.name,
        email: value.email.toLowerCase(),
        languageIndex: this.selectedLanguagePref,
        notificationIndex: this.genRef.PROFILE_DEFAULT_NOTIFICATION_PREF_INDEX,
        numTotalNotifications: this.genRef.ENTERAPP_DEFAULT_NUM_NOTIFICATIONS
      }
      //Send the new user object along with the password to create the user in Firebase Auth
      this.authService.signup(this.user, value.password, this.originalRoute).then(res => {
        //Once the Auth user is created, they are logged in and the auth guard automatically routes user to the verify email component
      }).catch(err => {
        //Show an alert if there is an error creating the account
        let message: string;
        //Show a specific alert if the email address provided already has an account with Firebase Auth
        if(err.code == "auth/email-already-in-use") {
          message = this.dbRef.ENTERAPP_ALERT_ERROR_EMAIL_ALREADY_IN_USE;
        } else {
          message = this.dbRef.ENTERAPP_ALERT_ERROR_CREATING_ACCOUNT;
        }
        this.alertsService.showNewAlert({
          message: message, 
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_DANGER
        });
      });
   }
  }

  /**
   * Changes the selectedLanguagePref variable and updates the dbRef variable accordingly
   * If the user goes on to create a new account after switching languages, the selected language will be used in their profile
   * If the user already exists, switching the language pref from this screen will NOT update their actual language pref on their profile.
   * @param languageIndex indicates which language preference was selected
   */
  switchLanguage(languageIndex: number) {
    this.selectedLanguagePref = languageIndex;
    this.databaseService.updateDbRef(languageIndex);

    //Show this alert if the language pref is changed to spanish until a proper spanish translation is added to the app. Once added, this alert can be deleted.
    if(languageIndex == this.genRef.DB_LANGUAGE_PREF_SPANISH) {
      this.alertsService.showNewAlert({
        message: "Has elegido el español como tu idioma preferido. Estamos trabajando en el desarrollo de la traducción al español para esta aplicación, y tan pronto como esté disponible, notará el cambio sin que sea necesaria ninguna otra acción. Esperamos que esté disponible en las próximas semanas.",
        duration: 25000,
        class: this.genRef.ALERTS_CLASS_WARNING
      });
    }
  }

  /**
   * Requests that the given user's password be reset with Firebase auth.
   * The original route is passed in as well so the user can eventually be rerouted back to where they started after paswword is reset
   * @param email is the email address provided by the user that the password reset instructions should be sent to
   */
  resetPasswordSubmit({value}: {value: {email: string}}) {
    this.authService.sendPasswordReset(value.email, this.originalRoute, this.dbRef.ENTERAPP_ALERT_RESETTING_PASSWORD, this.dbRef.ENTERAPP_ALERT_NOT_RESETTING_PASSWORD);
    this.isResettingPassword = false;
  }


}
