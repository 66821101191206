import { Component, OnInit } from '@angular/core';
import { GENERAL_REF } from '../../dbReferences';
import { DatabaseService } from '../../services/database.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  genRef;  //contains general references needed for the database/app  
  dbRef; //contains text, phrases and other referencces needed for the database/app - pulled in the user's preferred language
  collapseDescriptions;  //An object array of headers and descriptions for app features

  constructor(
    private databaseService: DatabaseService
  ) { }

  ngOnInit() {
    //Get a reference to the language specific database reference
    this.genRef = GENERAL_REF;    
    this.databaseService.dbRef.subscribe(ref => {
      this.dbRef = ref;
      //Set the collapseDescriptions variable
      this.collapseDescriptions = this.dbRef.ABOUT_COLLAPSE_DESCRIPTIONS;
    });
  }

  //When the user clicks on one of the description headers, update the isExpanded boolean for that 
  //description object to indicate if it should be expanded to show the description details or not
  collapse(index: number) {
    this.collapseDescriptions[index].isExpanded = !this.collapseDescriptions[index].isExpanded;
  }

}
