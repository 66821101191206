import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { GENERAL_REF } from './dbReferences';

//Import Components
import { HomeComponent } from './components/home/home.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ProfileComponent } from './components/profile/profile.component';
import { FriendsComponent } from './components/friends/friends.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { ListComponent } from './components/list/list.component';
import { ListsComponent } from './components/lists/lists.component';
import { ItemComponent } from './components/item/item.component';
import { InviteComponent } from './components/invite/invite.component';
import { ThankYouListComponent } from './components/thank-you-list/thank-you-list.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { GiveComponent } from './components/give/give.component';
import { HelpComponent } from './components/help/help.component';


//Import Services
import { AuthGuard } from './guards/auth.guard';


const routes: Routes = [
  //Home
  {path: GENERAL_REF.ROUTES_HOME, component: HomeComponent},
  //Verify Email
  {path: GENERAL_REF.ROUTES_VERIFY_EMAIL, component: VerifyEmailComponent},
  //Invite Landing Page
  {path: GENERAL_REF.ROUTES_INVITE + '/:' + GENERAL_REF.ROUTES_PARAM_NOTIFICATION_ID + '/:' + GENERAL_REF.ROUTES_PARAM_INVITE_TYPE + '/:' + GENERAL_REF.ROUTES_PARAM_INVITE_ID + '/:' + GENERAL_REF.ROUTES_PARAM_INVITED_USER, component: InviteComponent},
  //Give Landing Page
  {path: GENERAL_REF.ROUTES_GIVE, component: GiveComponent},
  //Give Landing Page with shareCode
  {path: GENERAL_REF.ROUTES_GIVE + '/:' + GENERAL_REF.ROUTES_PARAM_SHARE_CODE, component: GiveComponent},
  //Help Page
  {path: GENERAL_REF.ROUTES_HELP, component: HelpComponent},
  //Dashboard
  {path: GENERAL_REF.ROUTES_DASHBOARD, component: DashboardComponent, canActivate: [AuthGuard]},
  //Profile
  {path: GENERAL_REF.ROUTES_PROFILE, component: ProfileComponent, canActivate: [AuthGuard]},
  //All Lists
  {path: GENERAL_REF.ROUTES_LISTS, component: ListsComponent, canActivate: [AuthGuard]},
  //Friends
  {path: GENERAL_REF.ROUTES_FRIENDS, component: FriendsComponent, canActivate: [AuthGuard]},
  //Notifications
  {path: GENERAL_REF.ROUTES_NOTIFICATIONS, component: NotificationsComponent, canActivate: [AuthGuard]},
  //A List
  {path: GENERAL_REF.ROUTES_LIST + '/:' + GENERAL_REF.ROUTES_PARAM_LIST_ID, component: ListComponent, canActivate: [AuthGuard]},
  //An Item
  {path: GENERAL_REF.ROUTES_LIST + '/:' + GENERAL_REF.ROUTES_PARAM_LIST_ID + '/' + GENERAL_REF.ROUTES_ITEM + '/:' + GENERAL_REF.ROUTES_PARAM_ITEM_ID, component: ItemComponent, canActivate: [AuthGuard]},  
  //Thank you list
  {path: GENERAL_REF.ROUTES_THANK_YOU_LIST + '/:' + GENERAL_REF.ROUTES_PARAM_LIST_ID, component: ThankYouListComponent, canActivate: [AuthGuard]},
  //Not Found
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  declarations: [],
  exports: [RouterModule],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
