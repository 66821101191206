import { Component, OnInit } from '@angular/core';
import { GENERAL_REF } from '../../dbReferences';
import { DatabaseService } from 'src/app/services/database.service';
import { User } from '../../models/User';

/**
 * The initial screen to show upon login.  Shows a summary of lists, notifications, and friends.
 */

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  genRef;  //contains general references needed for the database/app
  dbRef; //contains text, phrases and other references needed for the database/app - pulled in the user's preferred language
  user: User;  //the User object for the current user
  hasLists: boolean = false;  //Indicates if the user has any lists in their myLists array for use with the UI
  private listString: string; //variable for singular list for html doc
  private listsString: string; //variable for plural list for html doc
  private forString: string; //variable for html doc
  private giverString: string; //variable for singular giver for html doc
  private giversString: string; //variable for plural giver for html doc


  constructor(
    private databaseService: DatabaseService
  ) { }

  ngOnInit() {
    //Get a reference to the general and language specific database references
    this.genRef = GENERAL_REF;
    this.databaseService.dbRef.subscribe(ref => {
      this.dbRef = ref;
      //Set the string variables for use in the UI
      this.listString = this.dbRef.DASHBOARD_TEXT_LIST_SINGULAR;
      this.listsString = this.dbRef.DASHBOARD_TEXT_LISTS_PLURAL;
      this.forString = this.dbRef.DASHBOARD_TEXT_FOR;
      this.giverString = this.dbRef.DASHBOARD_TEXT_GIVER_SUBSCRIBED_SINGULAR;
      this.giversString = this.dbRef.DASHBOARD_TEXT_GIVERS_SUBSCRIBED_PLURAL;
    });
    //Load the current User object
    this.databaseService.activeUser.subscribe(user => {
      this.user = user;
      if(this.user) {
        //Determine if the user has any myLists and set the hasLists variable accordingly
        if(user.myLists && user.myLists.length !== 0 
          || user.adminLists && user.adminLists.length !== 0 
          || user.friendsLists && user.friendsLists.length !== 0) {
            this.hasLists = true;
        }
      }
    });
  }

}
