//dbReferences.ts file contains constants to be used throughout the application as well as the firebase console.

//Constants to be used throughout the appication as well as the firebase console, but never appear to the user
//These constants do not require translation since they are never shown to the user
export const GENERAL_REF = {
    APP_NAME: "Presently", //The name of the app
    DB_COLLECTION_USERS: "users", //the id of the firebase collection representing users
    DB_COLLECTION_LISTS: "lists", //the id of the firebase collection representing lists
    DB_COLLECTION_INVITED_USERS: "invitedUsers",
    DB_COLLECTION_NOTIFICATIONS: "notifications",
    DB_COLLECTION_DELETED_USERS: "deleted-users",

    DB_FIELD_LIST_ITEM_QUANTITY_PURCHASED: "quantityPurchased",
    DB_FIELD_LIST_ITEM_QUANTITY_REMAINING: "quantityRemaining",

    DB_DEFAULT_LANGUAGE_PREF: 0,
    DB_LANGUAGE_PREF_ENGLISH: 0,
    DB_LANGUAGE_PREF_SPANISH: 1,

    DB_ITEM_IMG: "itemImage",  // must manually change elseware in app: \functions\src\index.ts;
    DB_ITEM_IMG_DEFAULT: "https://firebasestorage.googleapis.com/v0/b/presently-d8522.appspot.com/o/item-image%2Fdefault.PNG?alt=media&token=f9d4800c-cbd2-45f0-8208-6f6f82948ec5",  // The url to the default profile pic that is used when a user doesn"t have their own,
    // DB_USER_PROFILE_PIC_OPTIMIZING_PLACEHOLDER: 'https://firebasestorage.googleapis.com/v0/b/nom-strategy.appspot.com/o/profile-image%2Foptimizing_image_placeholder.PNG?alt=media&token=2f575766-6434-4a6a-867e-d521ff9389d2',
    
    STORAGE_FOLDER_ITEM_IMAGE: "item-image",
    STORAGE_PREFIX_UNSIZED: "unsized@", // indicates that the image is the original and has not been resized
    
    //Constants for application routes
    ROUTES_MAIN_APP: "https://presently.us/",
    ROUTES_MAIN_APP_NO_END_SLASH: "https://presently.us",
    ROUTES_HOME: "", 
    ROUTES_DASHBOARD: "dashboard",
    ROUTES_PROFILE: "profile",
    ROUTES_FRIENDS: "friends",
    ROUTES_LIST: "list",
    ROUTES_NEW_LIST: "list/new",
    ROUTES_LISTS: "lists",
    ROUTES_ITEM: "item",
    ROUTES_NEW_ITEM: "item/new",
    ROUTES_NOTIFICATIONS: "notifications",
    ROUTES_INVITE: "invite",
    ROUTES_FRIEND: "friend",
    ROUTES_ADMIN: "admin",
    ROUTES_GIVER: "giver",
    ROUTES_GIVE: "give",
    ROUTES_THANK_YOU_LIST: "thank-you-list",
    ROUTES_VERIFY_EMAIL: "verify-email",
    ROUTES_NEW: "new",
    ROUTES_HELP: "help",

    //Constants for application route parameters
    ROUTES_PARAM_LIST_ID: "listId",
    ROUTES_PARAM_ITEM_ID: "itemId",
    ROUTES_PARAM_NOTIFICATION_ID: "notificationId",
    ROUTES_PARAM_INVITE_TYPE: "inviteType",
    ROUTES_PARAM_INVITE_ID: "inviteId", //Could be the list ID that the invite is for or the userID of the user that invited them, will need to count digits
    ROUTES_PARAM_INVITED_USER: "inviteEmail", //
    ROUTES_PARAM_SHARE_CODE: "shareCode",

    //Constants for Alerts Service
    ALERTS_DURATION_STANDARD: 3000, // 3 seconds
    ALERTS_CLASS_PRIMARY: "alert-primary",
    ALERTS_CLASS_WARNING: "alert-warning",
    ALERTS_CLASS_DANGER: "alert-danger",
    ALERTS_CLASS_SUCCESS: "alert-success",
    ALERTS_CLASS_SECONDARY: "alert-secondary",
    
    //Constants for Enter App 
    ENTERAPP_BUTTON_LANG_ENGLISH: "In English",
    ENTERAPP_BUTTON_LANG_SPANISH: "En Español",
    ENTERAPP_PARENT_HOME: "Home",
    ENTERAPP_PARENT_INVITE: "Invite",
    ENTERAPP_PARENT_GIVE: "Give",
    ENTERAPP_TEXT_TEMP_USER_ID: "TBD",
    ENTERAPP_DEFAULT_NUM_NOTIFICATIONS: 0,

    //Constants for About
    ABOUT_CONTACT_EMAIL: "info@presently.us",
    ABOUT_CONTACT_ADDRESS: "PO Box 1234, Canton, GA 30114",
    ABOUT_COPYRIGHT: "© Formster, LLC 2019",

    //Constants for list documents
    LIST_PRIVILEGES_OWNER: "Owner",  //The privilege constant representing the owner of a list; has access to change anything on the list
    LIST_PRIVILEGES_ADMIN: "Admin", //The privilege constant representing the admin of a ist; has access to change private and public list details, invite/remove givers, add/remove custom list categories, and add/remove items from a list (can not add/remove other admin)
    LIST_PRIVILEGES_GIVER: "Giver", //The privilege constant representing the giver of a list; has access to view public list details, view items, and mark items purhcased/unpurchased
    LIST_PRIVILEGES_NOT_AUTH: "NotAuth", //The privilege constant representing a user that is not a owner/admin/givers of a list; does not have access to view/edit any part of a list
    LIST_DEFAULT_TYPE_INDEX: 4, //The default list type index; see EN_DBREFERENCES.LIST_TEXT_LIST_TYPES for the list types array
    LIST_DEFAULT_PRIVACY_INDEX: 1, //The default list privacy index; see EN_DBREFERENCES.LIST_TEXT_PRIVACY_TYPES for the privacy types array
    LIST_DEFAULT_SURPRISE_LEVEL_INDEX: 0,  //The default list surprise level index; see EN_DBREFERENCES.LIST_TEXT_SURPRISE_LEVELS for the surprise levels array
    LIST_TYPE_INDEX_BABY: 0,
    LIST_TYPE_INDEX_WEDDING: 1,
    LIST_TYPE_INDEX_BIRTHDAY: 2,
    LIST_TYPE_INDEX_HOLIDAY: 3,
    LIST_TYPE_INDEX_OTHER_EVENT: 4,
    LIST_TYPE_INDEX_WISH: 5,
    LIST_TYPE_INDEX_SHOPPING: 6,
    LIST_TYPE_INDEX_CHARITY: 7,
    LIST_TYPE_INDEX_DISASTER_RESP: 8,
    LIST_PRIVACY_INDEX_PRIVATE: 0,
    LIST_PRIVACY_INDEX_PUBLIC: 1,
    LIST_SURPRISE_INDEX_TOTALLY: 0,
    LIST_SURPRISE_INDEX_MILDLY: 1,
    LIST_SURPRISE_INDEX_NOT: 2,
    LIST_DEFAULT_SORT_INDEX: 3, //The default sort option of a list; see EN_DBREFERENCES.LIST_TEXT_SORT_OPTIONS for the sort options array - this value points to the array string that should be used as the default sort option
    LIST_DEFAULT_PRICE_RANGE_INDEX: 0,
    LIST_DEFAULT_PURCHASED_OPTION_GIVER: 0,
    LIST_DEFAULT_PURCHASED_OPTION_OWNER_ADMIN: 2,
    LIST_PURCHASED_INDEX_NEEDED: 0,
    LIST_PURCHASED_INDEX_MY_PURCHASES: 1,
    LIST_PURCHASED_INDEX_ALL: 2,
    LIST_PRICE_RANGE_VALUES: [ //Hard values for the given price ranges that can be selected to filter lists by, used to compare actual prices to for filter function; see EN_DBREFERENCES.LIST_TEXT_PRICE_RANGE_OPTIONS for the comparable text value range that can be selected in UI.
        {low: 0, high: 99999999999999},
        {low: 0, high: 25.99},
        {low: 25, high: 50.99},
        {low: 50, high: 100.99},
        {low: 100, high: 200.99},
        {low: 200, high: 99999999999999}
    ],
    LIST_TEXT_SORT_INDEX_PRICE_LOW_HIGH: 0,
    LIST_TEXT_SORT_INDEX_PRICE_HIGH_LOW: 1,
    LIST_TEXT_SORT_INDEX_ALPHABETICAL: 2,
    LIST_TEXT_SORT_INDEX_CATEGORY: 3,
    LIST_TEXT_LIST_ID_INVALID: "invalid",
    LIST_TEXT_LIST_ID_BLOCKED: "blocked",
    LIST_TEXT_LIST_ID_DELETED: "deleted",
    LIST_DEFAULT_LIST_ID: "TBD",
    LIST_DEFAULT_NUM_UNIQUE_ITEMS: 0,
    LIST_DEFAULT_NUM_QUANTITY_ITEMS: 0,
    LIST_DEFAULT_NUM_QUANTITY_PURCHASED: 0,
    LIST_DEFAULT_COST_TOTAL: 0,
    LIST_DEFAULT_COST_PURCHASED: 0,



    //constants for item component
    ITEM_DEFAULT_AVAILABILITY: 0,
    ITEM_AVAILABILITY_ONLINE: 0,
    ITEM_AVAILABILITY_LOCAL: 1,
    ITEM_DEFAULT_QUANTITY_REQUESTED: 1,
    ITEM_DEFAULT_QUANTITY_PURCHASED: 0,
    ITEM_DEFAULT_QUANTITY_REMAINING: 0,
    ITEM_DEFAULT_PRICE: 0,
    ITEM_DEFAULT_AVAILABILITY_INDEX: 0,
    ITEM_DEFAULT_THUMBNAIL_URL: "https://firebasestorage.googleapis.com/v0/b/presently-d8522.appspot.com/o/item-image%2Fpresently-logo-light.png?alt=media&token=653f8996-f349-41ea-adbc-64e25675fcc8",

    //Constants for profile documents
    PROFILE_DEFAULT_NOTIFICATION_PREF_INDEX: 0, //The default notification preference option for the list;  see EN_DBREFERENCES.PROFILE_TEXT_NOTIFICATION_PREFERENCE_OPTIONS for the notification preferences array
    PROFILE_NOTIFICATION_INDEX_INSTANT: 0,
    PROFILE_NOTIFICATION_INDEX_NONE: 1,


}

//Constants to be used throughout the appication UI to the user
//These constants do require translation since they are shown to the user
//These constants are in the English Language (en)
export const EN_DBREFERENCES = {
//Labels shown on the UI - all components
    LABEL_EMAIL: "Email:",
    LABEL_PASSWORD: "Password:",
    LABEL_NEW_PASSWORD: "New Password:",
    LABEL_CONFIRM_PASSWORD: "Confirm New Password:",
    LABEL_CURRENT_PASSWORD: "Please confirm your current password to change email or password:",
    LABEL_NAME: "Name:",
    LABEL_PROFILE: "Profile",
    LABEL_NOTIFICATIONS: "Notifications",
    LABEL_FRIENDS: "Friends",
    LABEL_INVITED_FRIENDS: "Invited Friends",
    LABEL_MY_LISTS: "My Lists",
    LABEL_MY_ADMIN_LISTS: "My Admin Lists",
    LABEL_FRIENDS_LISTS: "Friends' Lists",
    LABEL_LIST_NAME: "List Name:",
    LABEL_EVENT_DATE: "Event Date:",
    LABEL_DESCRIPTION: "Description:",
    LABEL_NOTES: "Notes:",
    LABEL_SHIPPING_ADDRESS: "Shipping Address:",
    LABEL_SHOW_SHIPPING_ADDRESS: "Show Shipping Address:",
    LABEL_LIST_TYPE: "List Type:",
    LABEL_LIST_EXPIRES_ON: "List Expires On:",
    LABEL_PRIVACY: "Privacy:",
    LABEL_SHARE_CODE: "Share Code:",
    LABEL_SHARE_LINK: "Share Link:",
    LABEL_SURPRISE_LEVEL: "Surprise Level:",
    LABEL_NOTIFICATION_PREFERENCE: "Email Notifications:",
    LABEL_LANGUAGE_PREFERENCE: "Language Preference:",
    LABEL_CUSTOM_CATEGORIES: "Custom Categories:",
    LABEL_ADMIN: "Admin:",
    LABEL_GIVERS_SUBSCRIBED: "Givers:",
    LABEL_PRICE_RANGE: "Price Range:",
    LABEL_PURCHASED_OPTIONS: "Purchase Status:",
    LABEL_CATEGORIES: "Categories:",
    LABEL_URL: "URL:",
    LABEL_TITLE: "Title:",
    LABEL_CATEGORY: "Category:",
    LABEL_MERCHANT: "Merchant:",
    LABEL_AVAILABILITY: "Availability:",
    LABEL_PRICE: "Price:",
    LABEL_PRICE_EACH: "Price/each:",
    LABEL_QUANTITY_REQUESTED: "Quantity Requested:",
    LABEL_QUANTITY_PURCHASED: "Quantity Purchased:",
    LABEL_QUANTITY_NEEDED: "Quantity Needed:",
    LABEL_QUANTITY_TO_UNPURCHASE: "Quantity to Unpurchase:",
    LABEL_COLOR: "Color:",
    LABEL_SIZE: "Size:",
    LABEL_SPEC: "Spec:",
    LABEL_IMAGE_URL: "Image URL:",
    LABEL_INVITE_FRIENDS: "Invite Friends by Email:",

//Profile component texts
    //selection options
    PROFILE_TEXT_NOTIFICATION_PREFERENCE_OPTIONS: ["Instant","None"],
    PROFILE_TEXT_LANGUAGE_PREFERENCE_OPTIONS: ["English","Español"],  //Do not translate

    //placeholders
    PROFILE_TEXT_NAME_PLACEHOLDER: "Your Name",
    PROFILE_TEXT_EMAIL_PLACEHOLDER: "newemail@example.com",
    PROFILE_TEXT_FAKE_PASSWORD: "***********",
    PROFILE_TEXT_NEW_PASSWORD_PLACEHOLDER: "New password",
    PROFILE_TEXT_CONFIRM_PASSWORD_PLACEHOLDER: "Confirm new password",
    PROFILE_TEXT_CURRENT_PASSWORD_PLACEHOLDER: "Current password",

    //button labels
    PROFILE_BUTTON_EDIT_DETAILS: "Edit Profile",
    PROFILE_BUTTON_CANCEL_EDIT: "Cancel Edit",
    PROFILE_BUTTON_SAVE_CHANGES: "Save Changes",
    PROFILE_BUTTON_EDIT_PASSWORD_EMAIL: "Change Email or Password",
    PROFILE_BUTTON_CHANGE_PASSWORD: "Change Password",
    PROFILE_BUTTON_SAVE_PASSWORD: "Save New Password",
    PROFILE_BUTTON_CANCEL_PASSWORD: "Cancel Password Change",
    PROFILE_BUTTON_DELETE_USER: "Delete Account",

    //inline texts
    PROFILE_TEXT_EXP_NOTIFICATION_PREF: "Choose when you would like to receive email notifications about changes to your lists, invites, etc.",
    PROFILE_TEXT_EXP_LANGUAGE_PREF: "Choose which language you would like to view the app in.",
    PROFILE_ALERT_PROFILE_UPDATED: "Profile has been updated",
    PROFILE_ALERT_PROFILE_NOT_UPDATED: "There was a problem updating profile",

//Navbar component texts
    //link labels
    NAVBAR_LINK_LOGOUT: "Log Out",
    NAVBAR_LINK_DASHBOARD: "Dashboard",
    NAVBAR_LINK_NOTIFICATIONS: "Notifications",
    NAVBAR_LINK_LISTS: "Lists",
    NAVBAR_LINK_NEW_LIST: "Create New List",
    NAVBAR_LINK_FRIENDS: "Friends",
    NAVBAR_LINK_PROFILE: "Profile",
    NAVBAR_LINK_HELP: "Help",

//Enter App component texts
    //Button labels
    ENTERAPP_BUTTON_LOG_IN: "Log In",
    ENTERAPP_BUTTON_SHOW_CREATE_ACCOUNT_FORM: "Create New Account",
    ENTERAPP_BUTTON_CREATE_ACCOUNT: "Create Account",
    ENTERAPP_BUTTON_SHOW_LOG_IN_FORM: "Log In With Existing Account",
    ENTERAPP_BUTTON_RESET_PASSWORD: "Reset Password",
    ENTERAPP_BUTTON_CANCEL: "Cancel",
    ENTERAPP_BUTTON_HELP: "Help/FAQ",
    //placeholders
    ENTERAPP_PLACEHOLDER_EMAIL: "email@example.com",
    ENTERAPP_PLACEHOLDER_PASSWORD: "password",
    ENTERAPP_PLACEHOLDER_NAME: "Your Name",
    //Error verbiage
    ENTERAPP_ERROR_ENTER_NAME: "Please enter a name",
    ENTERAPP_ERROR_ENTER_EMAIL: "Please enter a valid email address",
    ENTERAPP_ERROR_ENTER_PASSWORD: "Please enter a valid password",
    ENTERAPP_ERROR_ENTER_PHONE: "Please enter a 10-digit phone number (###-###-####)",
    //Inline texts
    ENTERAPP_TEXT_RESET_PASSWORD: "Enter your email address to reset your password:",
    //Alerts
    ENTERAPP_ALERT_ERROR_LOGGING_IN: "The username or password does not match our records",
    ENTERAPP_ALERT_ERROR_CREATING_ACCOUNT: "There was a problem creating an account",
    ENTERAPP_ALERT_ERROR_EMAIL_ALREADY_IN_USE: "The email address provided is already in use",
    ENTERAPP_ALERT_RESETTING_PASSWORD: "Please check your email for instructions to reset your password.",
    ENTERAPP_ALERT_NOT_RESETTING_PASSWORD: "There was a problem resetting your password, please try again.",
//About component texts
    //Inline texts
    ABOUT_TAGLINE: "THE PRESENT OF GIVING",
    ABOUT_APP_DESCRIPTION: "Create gift lists and registries to share with your friends and family",
    ABOUT_COLLAPSE_DESCRIPTIONS: [
        {header: "Registries for any item, from any store",
        detail: [
            "No more registering with multiple sites or settling only for items available at the biggest retailers.",
            "Presently allows you to add items from any store."
        ],
        isExpanded: false},
        {header: "Share publicly or privately",
        detail: [
            "Share your list publicly with a share code or make it only viewable to friends you specifically invite."
        ],
        isExpanded: false},
        {header: "Not just for babies and weddings",
        detail: [
            "Forget all the texts and emails asking for gift ideas - just create a list on Presently and share it publicly or privately with your friends.",
            "They can check it out and get the perfect gift before your event or whenever they are feeling generous!",
            "Use it for birthdays, holidays, special events, or even keep a wish list handy for Grandma!"
        ],
        isExpanded: false},
        {header: "Shop local",
        detail: [
            "Support your local shops and artisans by adding items from your favorite local spot.",
            "You can even upload your own photo if they don't have an e-commerce site to link to."
        ],
        isExpanded: false},
        {header: "Collaborate for charities or disaster response",
        detail: [
            "Coordinate what items are needed to support a charity or disaster response drive.",
            "Use Presently to communicate to donors what items are still needed and can make the biggest impact."
        ],
        isExpanded: false},
        {header: "Use as a shopping or supply list",
        detail: [
            "Whether you're redesigning your bedroom or throwing an office party, you can use Presently to keep track of items you need to make it perfect."
        ],
        // isExpanded: false},
        // {header: "Access from any device",
        // detail: [
        //     "Presently is a progressive web app, which means you can use it on any major browser like Chrome, Safari, Firefox, or Edge without installation.",
        //     "Or install directly to any device to use it like a native app for a lightning fast, immersive experience - even when you're offline!"
        // ],
        isExpanded: false},
        {header: "Access from any device",
        detail: [
            "Presently is a web app, which means you can use it on any major browser like Chrome, Safari, Firefox, or Edge without installation."
        ],
        isExpanded: false},
        {header: "And more...",
        detail: [
            "Generate a thank you list after your event based on what your friends purchased for you.",
            "Create custom categories to organize your list.",
            "Choose a different 'surprise level' to hide what's been purchased if you don't want to spoil the surprise.",
            "Add admin to your list to help you manage it.",
            "Sort and filter your list based on category, price range, keyword, or what's been purchased.",
            "Items can be marked as 'purchased' so everyone knows what's still needed."
        ],
        isExpanded: false}
    ],

//Help component texts
    //buttons
    HELP_BUTTON_HOME: "Home",
    //inline texts
    HELP_TEXT_CONTACT_US_1: "Please feel free to contact us at",
    HELP_TEXT_CONTACT_US_2: "with any questions or feedback!",
    HELP_TEXT_TITLE: "Presently Help and FAQs",
    HELP_COLLAPSE_DESCRIPTIONS: [
        {header: "What is Presently?",
        detail: [
            "Presently is a web-based application that allows you to create and share gift lists.",
            "Your friends can join your list and see any items that are still needed.",
            "If your friend wants to purchase an item from your list, they can do so directly with the merchant and then mark the item purchased on our app so your other friends know it's already purchased.",
            "Presently can be used to create lists for anything from baby shower and wedding registries to charity drives and birthday lists."
        ],
        isExpanded: false},
        {header: "What are some ways I can use Presently?",
        detail: [
            "There are countless ways to make use of this application; here are a few ideas:",
            "Baby shower registry",
            "Wedding registry",
            "Birthday list",
            "Wish list",
            "Personal shopping list",
            "Charity donations needed list",
            "Disaster response donations needed list",
            "Any supply list that requires collaboration with you and friends or co-workers",
            "Heck, use it for your next pot-luck! Presently has very few restrictions on what can be included on your lists."
        ],
        isExpanded: false},
        {header: "What does it cost to use?",
        detail: [
            "Presently is completely free to use.",
            "You may see a small advertisement displayed on certain pages of the app."
        ],
        isExpanded: false},
        {header: "Do I have to make an account to use Presently?",
        detail: [
            "Yes. Everyone that uses Presently must have an account, which simply includes:",
            "Your name (can be any name you choose - this is simply what will be displayed on your list and to your friends).",
            "Your email address - this is your login ID and is used to email you about invites from your friends and other notifications.  We respect your privacy and will never sell your information!",
            "A password of your choice that must be between 6-12 characters long."
        ],
        isExpanded: false},
        {header: "How do I invite my friends and family to join me on Presently?",
        detail: [
            "Simply log in and click on 'Friends' from the navigation bar at the top.",
            "From your friends page, you can see all your current friends, and can invite new friends by adding their email address."
        ],
        isExpanded: false},
        {header: "How do I make a new list?",
        detail: [
            "To make a new list, log in and select 'Create New List' from the navigation bar at the top.",
            "Add any details you like, such as a name for your list, an event date, a description, a shipping address, and any custom categories you may want.",
            "Be sure to choose the options you want for your list privacy and surprise level, and then click the button that says 'Save and Add Items' to start inviting friends and adding items to your new list.",
            "You can edit any of your list settings or descriptions at any time by going into your list and clicking the button that says 'Edit List' at the top right of the page."
        ],
        isExpanded: false},
        {header: "How do I share my list?",
        detail: [
            "If you make your list 'Public' you will be given a share code that you can share via email, social media, or right on an invitation. Your friends can go to presently.us/give and enter your share code to join your list.",
            "If you make you list 'Private' only people that you specifically invite can view your list.",
            "Just log in and go to your list to see your share code or to invite friends to be 'givers' on your list."
        ],
        isExpanded: false},
        {header: "How do I add items to my list?",
        detail: [
            "From your list, click on the button that says 'Add Item'.",
            "If your item is online, copy and paste the url into the URL box.",
            "Click on the 'Auto-fill Item' button and Presently will attempt to pull in any available info directly from the merchant's site. You can then edit that info or add to it as needed.",
            "Be sure to have a name/title for your item in the Title box, as this field is required.",
            "Add any other important details such as price, how many of the item you need, the category, the color or size you need, and a description.",
            "If a thumbnail URL couldn't be pulled automatically from the merchant site, add a picture of your item by selecting a picture from the website, copy the image url and paste it into the Image URL box.  You will see the picture displayed at the top of the page if it is a valid image URL. You can usually copy an image by right-clicking, ctrl-clicking, or pressing and holding on the image and selecting copy image address, or share image - all depending on what type of device you are using.",
            "If your item is only sold locally, don't worry about adding any URL's. Just be sure to select 'Local' for the Availability option, and you'll be able to upload your own photo.",
            "Then click any of the Save options and your item will be added to your list.  You can edit the item any time by clicking on it and clicking the 'Edit Item' button."
        ],
        isExpanded: false},
        {header: "How do my friends purchase items from my list?",
        detail: [
            "Your friends can view any items that are still needed on your list by logging in and clicking on your list from their Lists page.",
            "When they see an item they'd like to purchase, they can click on the button that says 'Mark Item Purchased' and enter the quantity that they bought.  Once they confirm, your list will be updated to show that the item(s) was purchased."
        ],
        isExpanded: false},
        {header: "How do I make a thank you list?",
        detail: [
            "To generate a thank you list, simply log in, go to your list, and click 'show' next to Private List Details.  In that section, you will see a button that says 'Generate Thank You List'.  Just click the button, and Presently will create a list of everything that's been purchased, who purchased it, and their email address so you can easily be sure to thank them for their generosity!"
        ],
        isExpanded: false},
        {header: "Can anyone see what's been purchased on my list?",
        detail: [
            "No. Only the list owner and admin can see who has purchased what from your list.",
            "If you choose 'Totally' surprised as an option on your list, Presently will hide who has purchased what from you as well so you can truly be surprised for your event.",
            "If you are curious to see your list the same way your friends will, just click on the 'View as Giver' button at the top right of your list page for a preview of how it will look to them."
        ],
        isExpanded: false},
        {header: "Is there a way to see a summary of items on my list?",
        detail: [
            "Yes. Simply go to your list and click 'Show' in the Prive List Details section.",
            "There you will see how many unique items are on your list, how many items have been purchased, and the total amount that's been purchased along with the grand total cost of all items.",
            "Only the list owner and admin can see this information."
        ],
        isExpanded: false},
        {header: "Can I sort and filter a list?",
        detail: [
            "Yes. Just use the toolbar at the top of your list items to search by keyword, or filter by category, price range, or items you have purchased. There are also several options for sorting items."
        ],
        isExpanded: false},
        {header: "How do I change my email, password, or delete my account entirely?",
        detail: [
            "To make any changes to your account or profile, log in and go to 'Profile' from the navigation bar at the top.",
            "There you can change your display name, email address, password, notification preferences, language preference, or delete your entire account and all your lists.",
            "Deleting your account will also delete all of your lists, and remove you from any of your friends' lists as well.  This information can not be recovered."
        ],
        isExpanded: false},
        // {header: "Why is my browser prompting me to install this app?",
        // detail: [
        //     "Presently is a Progressive Web App, which means it can run on any browser just like a normal website, but it can also be installed to your desktop or home screen.",
        //     "The app works exactly the same way whether you install it or not, however, installing it will provide a few benefits such as:",
        //     "A shortcut that launches a full screen app directly from your desktop or home screen.",
        //     "A full screen experience, so it feels and runs just like a native app.",
        //     "Lightning-fast navigation and better reliability when offline or on sketchy networks.",
        //     "To install, simply follow the prompts from your browser. You can tell your browser to stop asking you to install at any time."
        // ],
        // isExpanded: false}
    ],
    
//Verify Email component texts
    //inline texts
    VERIFY_EMAIL_ALERT_EMAIL_SENT: "Verification email sent",
    VERIFY_EMAIL_ALERT_EMAIL_NOT_SENT: "Error sending verification email",
//Give component texts
    //placeholders
    GIVE_PLACEHOLDER_SHARE_CODE: "12SHARE345",
//Dashboard component texts
    //button labels
    DASHBAORD_BUTTON_VIEW_ALL: "View All",
    DASHBOARD_BUTTON_VIEW_ALL_LISTS: "View All Lists",
    DASHBOARD_BUTTON_VIEW_ALL_FRIENDS: "View All Friends",
    DASHBOARD_BUTTON_ADD_FRIENDS: "Add Friends",
    DASHBOARD_BUTTON_CREATE_NEW_LIST: "Create New List",
    //inline texts
    DASHBOARD_TEXT_LIST_SINGULAR: "list",
    DASHBOARD_TEXT_LISTS_PLURAL: "lists",
    DASHBOARD_TEXT_FOR: "for",
    DASHBOARD_TEXT_GIVER_SUBSCRIBED_SINGULAR: "giver subscribed",
    DASHBOARD_TEXT_GIVERS_SUBSCRIBED_PLURAL: "givers subscribed",
    DASHBOARD_TEXT_BY: "by",
    DASHBOARD_TEXT_OF: " of ",
    DASHBOARD_TEXT_ITEMS_PURCHASED: " items purchased",
    DASHBOARD_TEXT_ITEMS_TOTAL: " total items",
    DASHBOARD_TEXT_NO_NOTIFICATIONS: "You don't have any notifications yet.",
    DASHBOARD_TEXT_NO_LISTS: "You don't have any lists yet.",
    DASHBOARD_TEXT_WELCOME: "Welcome",
//List component text
    //selection options
    LIST_TEXT_LIST_TYPES: ["Baby Registry","Wedding Registry","Birthday","Holiday","Other Event","Wish List","Shopping List","Charity","Disaster Response"],
    LIST_TEXT_PRIVACY_TYPES: ["Private","Public"],
    LIST_TEXT_PRIVACY_DESCRIPTIONS: [  //a description of what the different privacy types mean
        "Others must have your access code or link to view your list",
        "Others can search for your list by your email"
    ],
    LIST_TEXT_SURPRISE_LEVELS: ["Totally","Mildly","Not"],
    LIST_TEXT_SURPRISE_DESCRIPTIONS: [  //a description of what the different surprise levels mean
        "Can't see what's been purchased",
        "Can see what's been purchased but not by whom",
        "Can see who has purchased what"
        ],
    LIST_TEXT_DEFAULT_CATEGORY: "General",
    //placeholders
    LIST_TEXT_CATEGORY_PLACEHOLDER: "Custom Category",
    LIST_TEXT_FRIENDS_DROPDOWN_PLACEHOLDER: "Choose from Friends",
    LIST_TEXT_ADMIN_PLACEHOLDER: "newadmin@example.com",
    LIST_TEXT_GIVER_PLACEHOLDER: "newgiver@example.com",
    LIST_TEXT_DESCRIPTION_PLACEHOLDER: "Enter a description for this list (if applicable).",
    LIST_TEXT_EVENT_DATE_PLACEHOLDER: "Event Date",
    LIST_TEXT_ADDRESSEE_PLACEHOLDER: "Ship to name (optional)",
    LIST_TEXT_STREET_ADDRESS_PLACEHOLDER: "Street address (optional)",
    LIST_TEXT_CITY_PLACEHOLDER: "City (optional)",
    LIST_TEXT_STATE_PLACEHOLDER: "St (optional)",
    LIST_TEXT_ZIP_PLACEHOLDER: "Zip (optional)",
    LIST_TEXT_NOTES_PLACEHOLDER: "Enter additional notes for this list (if applicable).",
    LIST_TEXT_EXPIRATION_DATE_PLACEHOLDER: "Expiration Date",
    LIST_TEXT_NONE_PLACEHOLDER: "None",
    LIST_TEXT_SEARCH_KEYWORD_PLACEHOLDER: "Keyword",
    LIST_TEXT_DEFAULT_LIST_NAME_APPEND: "'s List",
    //Sort & Filter options (UI)
    LIST_TEXT_SORT_OPTIONS: [
        "Price low to high",
        "Price high to low",
        "Alphabetically A->Z",
        "By Category"
    ],
    LIST_TEXT_PRICE_RANGE_OPTIONS: [
        "All",
        "$0 - $25",
        "$25 - $50",
        "$50 - $100",
        "$100 - $200",
        "$200 +"
    ],
    LIST_TEXT_PURCHASED_OPTIONS: [
        "Still Needed",
        "My Purchases",
        "All"
    ],
    LIST_TEXT_CATEGORY_SELECT_ALL: "Select All",
    //button labels
    LIST_BUTTON_EDIT_DETAILS: "Edit List",
    LIST_BUTTON_VIEW_AS_GIVER: "View as Giver",
    LIST_BUTTON_VIEW_AS_OWNER_ADMIN: "View as Owner/Admin",
    LIST_BUTTON_DELETE_LIST: "Delete List",
    LIST_BUTTON_CANCEL_EDIT: "Cancel Edit",
    LIST_BUTTON_SAVE_CHANGES: "Save Changes",
    LIST_BUTTON_ADD_REMOVE_ADMIN: "Add/Remove Admin",
    LIST_BUTTON_ADD_REMOVE_GIVER: "Add/Remove Giver",
    LIST_BUTTON_DONE: "Done",
    LIST_BUTTON_HIDE: "Hide",
    LIST_BUTTON_SHOW: "Show",
    LIST_BUTTON_ADD: "Add",
    LIST_BUTTON_ADD_ADMIN: "Add Admin",
    LIST_BUTTON_ADD_GIVER: "Add Giver",
    LIST_BUTTON_INVITE_FRIENDS: "Invite New",
    LIST_BUTTON_VIEW_FRIENDS: "View Friends",
    LIST_BUTTON_SEARCH: "Search",
    LIST_BUTTON_FILTER: "Filter",
    LIST_BUTTON_SORT: "Sort",
    LIST_BUTTON_ADD_ITEM: "Add Item",
    LIST_BUTTON_GENERATE_THANK_YOU_LIST: "Generate Thank You List",
    LIST_BUTTON_CANCEL: "Cancel",
    LIST_BUTTON_SAVE_ADD_ITEMS: "Save and Add Items",
    
    //inline texts
    LIST_TEXT_BY: "by",
    LIST_TEXT_PRIVATE_LIST_DETAILS: "Private List Details",
    LIST_TEXT_PRIVATE_LIST_DETAILS_NOTE: "(This section  can only be seen by list owner and admin)",
    LIST_TEXT_INVITED: "(invited)",
    LIST_TEXT_PER_EACH: "/ each",
    LIST_TEXT_NEEDED: "needed",
    LIST_TEXT_UNIQUE_ITEMS: "unique items",
    LIST_TEXT_OF: "of",
    LIST_TEXT_TOTAL_PURCHASED: "total items purchased",
    LIST_TEXT_PURCHASED: "purchased",
    LIST_TEXT_TOTAL: "total",
    LIST_TEXT_NOT_AUTH: "You are not authorized to view this list",
    LIST_TEXT_NOT_VALID: "The list can not be found or the list ID is invalid.",
    LIST_TEXT_GIVER_VIEW_MODE: "You are viewing this list as a Giver.",
    LIST_TEXT_NO_ITEMS: "No items to display",
    LIST_TEXT_CONFIRM_DELETE: "Are you sure you want to delete this list?",
    LIST_TEXT_EXP_EVENT_DATE: "Event Date (optional) is the date of your event.",
    LIST_TEXT_EXP_SHIPPING_ADDRESS: "Shipping Address (optional) is an address that your friends can use to ship items as needed. Check the 'Show Shipping Address' checkbox if you want your friends to be able to see your address.",
    LIST_TEXT_EXP_LIST_TYPE: "List Type: Choose 'Event' if this list is for an event with a specific date; choose 'Ongoing' if this list is not related to an event (i.e. Wish List).",
    LIST_TEXT_EXP_EXPIRATION: "Choose an expiration date (optional) if you want this list automatically deleted after that date.",
    LIST_TEXT_EXP_PRIVACY: "Privacy: Private lists can only be found by users you invite; Public lists can be found by anyone with the share code or link.  Any admin or giver can be removed from your list at any time, regardless of privacy level.",
    LIST_TEXT_EXP_SHARE_CODE: "Use this code to share your list with your friends without having to invite them individually. Just go to presently.us/give and enter the code to join. Share code only works if your list privacy is set to 'Public'.",
    LIST_TEXT_EXP_SHARE_LINK: "Use this link to share your list with your friends without having to invite them individually. Share code only works if your list privacy is set to 'Public'.",
    LIST_TEXT_EXP_SURPRISE1: "Surprise Level: Choose 'Totally' if you do not want to know what's been purchased or by whom.",
    LIST_TEXT_EXP_SURPRISE2: "Choose 'Mildly' if you want to know what's been purchased, but not who has purchased it.",
    LIST_TEXT_EXP_SURPRISE3: "Choose 'Not' if you want to know what's been purchased and by whom.",
    LIST_TEXT_EXP_SURPRISE4: "Surprise Level can be changed at any time.",
    LIST_TEXT_EXP_CATEGORY: "Add categories to organize your list items with.",
    LIST_TEXT_EXP_ADMIN: "Add an admin to help manage your list. Admin can do anything the list owner can do, except add/remove admin or delete the list entirely.",
    LIST_TEXT_EXP_GIVER: "Invite 'givers' to join your list.  Givers can view all items and mark them as purchased.",
    //alerts
    LIST_ALERT_CHANGES_SAVED: "Changes saved",
    LIST_ALERT_CHANGES_NOT_SAVED: "Unable to save changes",
    LIST_ALERT_LIST_ADDED: " saved",
    LIST_ALERT_LIST_NOT_ADDED: "Unable to save new list",
    LIST_ALERT_LIST_DELETED: "List successfully deleted",
    LIST_ALERT_LIST_NOT_DELETED: "Unable to delete list",
    LIST_ALERT_ADMIN_INVITED: " invited as an admin",
    LIST_ALERT_ADMIN_NOT_INVITED: "Unable to invite admin",
    LIST_ALERT_GIVER_INVITED: " invited as a giver",
    LIST_ALERT_GIVER_NOT_INVITED: "Unable to invite giver",
    LIST_ALERT_ADMIN_UNINVITED: " was removed from admin list",
    LIST_ALERT_ADMIN_NOT_UNINVITED: "Unable to remove admin",
    LIST_ALERT_GIVER_UNINVITED: " was removed from giver list",
    LIST_ALERT_GIVER_NOT_UNINVITED: "Unable to remove giver",

    
//Item component texts
    //inline texts
    ITEM_TEXT_NOT_VALID: "The list or item can not be found or is invalid.",
    ITEM_TEXT_GIVER_VIEW_MODE: "You are viewing this item as a Giver. Switch to Owner/Admin view from the list page.",
    ITEM_TEXT_ADD_ITEM: "Add Item",
    ITEM_TEXT_PER_EACH: " / each",
    ITEM_TEXT_$: "$",
    ITEM_TEXT_PURCHASED: "purchased",
    ITEM_TEXT_USER_PURCHASED_1: "You have purchased",
    ITEM_TEXT_USER_PURCHASED_2: "of this item",
    ITEM_TEXT_CONFIRM_UNPURCHASE: "Are you sure you want to mark this item unpurchased?",
    ITEM_TEXT_PURCHASE_WARNING: "Only mark this item as purchased if you have purchased it, as it will be removed from the list.",
    ITEM_TEXT_PURCHASE_MODAL_WARNING: "Only mark this item as purchased if you have purchased it, as it will be removed from the list.",
    ITEM_TEXT_CONFIRM_PURCHASE: "Are you sure you want to mark this item purchased?",
    ITEM_TEXT_SELECT: "Select",
    ITEM_TEXT_CONFIRM_DELETE: "Are you sure you want to delete this item?",
    ITEM_TEXT_EXP_NAME_REQUIRED: "A name for this item is required.",
    ITEM_TEXT_EXP_SAVE_BEFORE_UPLOADING_IMAGE: "Please save item before uploading an image.",
    //alerts
    ITEM_ALERT_ITEM_PURCHASED: " of this item has been marked purchased",
    ITEM_ALERT_ITEM_NOT_PURCHASED: "There was a problem marking the item purchased",
    ITEM_ALERT_ITEM_UNPURCHASED: " of this item has been marked unpurchased",
    ITEM_ALERT_ITEM_NOT_UNPURCHASED: "There was a problem marking the item unpurchased",
    ITEM_ALERT_CHANGES_SAVED: "Changes saved",
    ITEM_ALERT_CHANGES_NOT_SAVED: "Unable to save changes",
    ITEM_ALERT_ITEM_DELETED: "Item successfully deleted",
    ITEM_ALERT_ITEM_NOT_DELETED: "Unable to delete item",
    //button labels
    ITEM_BUTTON_MARK_PURCHASED: "Mark Item Purchased",
    ITEM_BUTTON_MARK_MORE_PURCHASED: "Mark More Purchased",
    ITEM_BUTTON_CHANGE_IMAGE: "Upload Image",
    ITEM_BUTTON_SET_DEFAULT_IMAGE: "Reset Image to Default",
    ITEM_BUTTON_RETURN: "Return to List",
    ITEM_BUTTON_VIEW_MERCHANT_SITE: "View Item on Merchant Website",
    ITEM_BUTTON_AUTO_FILL: "Auto-fill Item",
    ITEM_BUTTON_UNPURCHASE: "Unpurchase Item",
    ITEM_BUTTON_CANCEL: "Cancel",
    ITEM_BUTTON_EDIT_DETAILS: "Edit Item",
    ITEM_BUTTON_CANCEL_EDIT: "Cancel Edit",
    ITEM_BUTTON_DELETE_ITEM: "Delete Item",
    ITEM_BUTTON_SAVE_ITEM: "Save Item",
    ITEM_BUTTON_SAVE_RETURN: "Save and Return to List",
    ITEM_BUTTON_SAVE_ADD: "Save and Add New Item",
    //selection options
    ITEM_TEXT_AVAILABILITY_OPTIONS: ["Online","Local"],
    //placeholders
    ITEM_PLACEHOLDER_URL: "Copy+paste item URL",
    ITEM_PLACEHOLDER_TITLE: "Item title or name",
    ITEM_PLACEHOLDER_PRICE: "0.00",
    ITEM_PLACEHOLDER_MERCHANT: "Merchant/Store Name",
    ITEM_PLACEHOLDER_QUANTITY_REQUESTED: "0",
    ITEM_PLACEHOLDER_COLOR: "Desired color",
    ITEM_PLACEHOLDER_SIZE: "Desired size",
    ITEM_PLACEHOLDER_SPEC: "Other specification",
    ITEM_PLACEHOLDER_IMAGE_URL: "Copy+paste Image URL",
    ITEM_PLACEHOLDER_DESCRIPTION: "Descrition of item",
    ITEM_PLACEHOLDER_NOTES: "Notes about item",


//Friends Component texts
    //inline texts
    FRIENDS_TEXT_LIST_SINGULAR: "list",
    FRIENDS_TEXT_LISTS_PLURAL: "lists",
    FRIENDS_TEXT_ADD_NICKNAME: "Add Nickname",
    FRIENDS_TEXT_NO_FRIENDS: "Invite friends by email above.",
    FRIENDS_TEXT_NO_INVITED_FRIENDS: "You don't have any invited friends yet.",
    FRIENDS_TEXT_CONFIRM_REMOVE_FRIEND_1: "Are you sure you want to remove this friend from your friends list?",
    FRIENDS_TEXT_CONFIRM_REMOVE_FRIEND_2: "This will not remove them from any of your gift lists, nor you from any of their lists.",
    FRIENDS_TEXT_CONFIRM_REMOVE_INVITED_FRIEND_1: "Are you sure you want to remove this friend from your invited friends list?",
    FRIENDS_TEXT_CONFIRM_REMOVE_INVITED_FRIEND_2: "This will not remove them from any of your gift lists, nor you from any of their lists.",
    //Placeholders
    FRIENDS_PLACEHOLDER_NEW_FRIEND_EMAIL: "newfriend@example.com",
    //Alerts
    FRIENDS_ALERT_FRIEND_REMOVED: " was removed from your friends list",
    FRIENDS_ALERT_FRIEND_NOT_REMOVED: " could not be removed from your friends list due to an error",    
    FRIENDS_ALERT_FRIEND_ADDED: " was added to your friends list",
    FRIENDS_ALERT_FRIEND_NOT_ADDED: " could not be added to your friends list due to an error",
    FRIENDS_ALERT_ALREADY_FRIEND: " is already on your friends list",
    FRIENDS_ALERT_ALREADY_INVITED: " has already been invited to your friends list",
    //Buttons
    FRIENDS_BUTTON_UNFRIEND: "Unfriend ",
    FRIENDS_BUTTON_CANCEL: "Cancel",
    FRIENDS_BUTTON_REMOVE_INVITED_FRIEND: "Remove Invited Friend",

//Notification texts
    NOTIFICATIONS_INVITED_FRIEND: " has invited you to join them on Presently!",

//Upload component texts
    //inline texts
    UPLOAD_FILE_PLACEHOLDER: "Choose File",
    UPLOAD_TEXT_WAIT_MESSAGE: "Please wait a moment while we upload and re-size your photo... this may take a minute, depending on the size of your photo.",
    //buttons
    UPLOAD_BUTTON_UPLOAD: "Upload",
    UPLOAD_BUTTON_CANCEL: "Cancel",

//Notifications component texts
    //pagination
    

};


//Constants to be used throughout the appication UI to the user
//These constants do require translation since they are shown to the user
//These constants are in the Spanish Language (es)
export const ES_DBREFERENCES = {  
    //Labels shown on the UI - all components
    LABEL_EMAIL: "Email:",
    LABEL_PASSWORD: "Password:",
    LABEL_NEW_PASSWORD: "New Password:",
    LABEL_CONFIRM_PASSWORD: "Confirm New Password:",
    LABEL_CURRENT_PASSWORD: "Please confirm your current password to change email or password:",
    LABEL_NAME: "Name:",
    LABEL_PROFILE: "Profile",
    LABEL_NOTIFICATIONS: "Notifications",
    LABEL_FRIENDS: "Friends",
    LABEL_INVITED_FRIENDS: "Invited Friends",
    LABEL_MY_LISTS: "My Lists",
    LABEL_MY_ADMIN_LISTS: "My Admin Lists",
    LABEL_FRIENDS_LISTS: "Friends' Lists",
    LABEL_LIST_NAME: "List Name:",
    LABEL_EVENT_DATE: "Event Date:",
    LABEL_DESCRIPTION: "Description:",
    LABEL_NOTES: "Notes:",
    LABEL_SHIPPING_ADDRESS: "Shipping Address:",
    LABEL_SHOW_SHIPPING_ADDRESS: "Show Shipping Address:",
    LABEL_LIST_TYPE: "List Type:",
    LABEL_LIST_EXPIRES_ON: "List Expires On:",
    LABEL_PRIVACY: "Privacy:",
    LABEL_SHARE_CODE: "Share Code:",
    LABEL_SHARE_LINK: "Share Link:",
    LABEL_SURPRISE_LEVEL: "Surprise Level:",
    LABEL_NOTIFICATION_PREFERENCE: "Email Notifications:",
    LABEL_LANGUAGE_PREFERENCE: "Language Preference:",
    LABEL_CUSTOM_CATEGORIES: "Custom Categories:",
    LABEL_ADMIN: "Admin:",
    LABEL_GIVERS_SUBSCRIBED: "Givers:",
    LABEL_PRICE_RANGE: "Price Range:",
    LABEL_PURCHASED_OPTIONS: "Purchase Status:",
    LABEL_CATEGORIES: "Categories:",
    LABEL_URL: "URL:",
    LABEL_TITLE: "Title:",
    LABEL_CATEGORY: "Category:",
    LABEL_MERCHANT: "Merchant:",
    LABEL_AVAILABILITY: "Availability:",
    LABEL_PRICE: "Price:",
    LABEL_PRICE_EACH: "Price/each:",
    LABEL_QUANTITY_REQUESTED: "Quantity Requested:",
    LABEL_QUANTITY_PURCHASED: "Quantity Purchased:",
    LABEL_QUANTITY_NEEDED: "Quantity Needed:",
    LABEL_QUANTITY_TO_UNPURCHASE: "Quantity to Unpurchase:",
    LABEL_COLOR: "Color:",
    LABEL_SIZE: "Size:",
    LABEL_SPEC: "Spec:",
    LABEL_IMAGE_URL: "Image URL:",
    LABEL_INVITE_FRIENDS: "Invite Friends by Email:",

//Profile component texts
    //selection options
    PROFILE_TEXT_NOTIFICATION_PREFERENCE_OPTIONS: ["Instant","None"],
    PROFILE_TEXT_LANGUAGE_PREFERENCE_OPTIONS: ["English","Español"],  //Do not translate

    //placeholders
    PROFILE_TEXT_NAME_PLACEHOLDER: "Your Name",
    PROFILE_TEXT_EMAIL_PLACEHOLDER: "newemail@example.com",
    PROFILE_TEXT_FAKE_PASSWORD: "***********",
    PROFILE_TEXT_NEW_PASSWORD_PLACEHOLDER: "New password",
    PROFILE_TEXT_CONFIRM_PASSWORD_PLACEHOLDER: "Confirm new password",
    PROFILE_TEXT_CURRENT_PASSWORD_PLACEHOLDER: "Current password",

    //button labels
    PROFILE_BUTTON_EDIT_DETAILS: "Edit Profile",
    PROFILE_BUTTON_CANCEL_EDIT: "Cancel Edit",
    PROFILE_BUTTON_SAVE_CHANGES: "Save Changes",
    PROFILE_BUTTON_EDIT_PASSWORD_EMAIL: "Change Email or Password",
    PROFILE_BUTTON_CHANGE_PASSWORD: "Change Password",
    PROFILE_BUTTON_SAVE_PASSWORD: "Save New Password",
    PROFILE_BUTTON_CANCEL_PASSWORD: "Cancel Password Change",
    PROFILE_BUTTON_DELETE_USER: "Delete Account",

    //inline texts
    PROFILE_TEXT_EXP_NOTIFICATION_PREF: "Choose when you would like to receive email notifications about changes to your lists, invites, etc.",
    PROFILE_TEXT_EXP_LANGUAGE_PREF: "Choose which language you would like to view the app in.",
    PROFILE_ALERT_PROFILE_UPDATED: "Profile has been updated",
    PROFILE_ALERT_PROFILE_NOT_UPDATED: "There was a problem updating profile",

//Navbar component texts
    //link labels
    NAVBAR_LINK_LOGOUT: "Log Out",
    NAVBAR_LINK_DASHBOARD: "Dashboard",
    NAVBAR_LINK_NOTIFICATIONS: "Notifications",
    NAVBAR_LINK_LISTS: "Lists",
    NAVBAR_LINK_NEW_LIST: "Create New List",
    NAVBAR_LINK_FRIENDS: "Friends",
    NAVBAR_LINK_PROFILE: "Profile",
    NAVBAR_LINK_HELP: "Help",

//Enter App component texts
    //Button labels
    ENTERAPP_BUTTON_LOG_IN: "Log In",
    ENTERAPP_BUTTON_SHOW_CREATE_ACCOUNT_FORM: "Create New Account",
    ENTERAPP_BUTTON_CREATE_ACCOUNT: "Create Account",
    ENTERAPP_BUTTON_SHOW_LOG_IN_FORM: "Log In With Existing Account",
    ENTERAPP_BUTTON_RESET_PASSWORD: "Reset Password",
    ENTERAPP_BUTTON_CANCEL: "Cancel",
    ENTERAPP_BUTTON_HELP: "Help/FAQ",
    //placeholders
    ENTERAPP_PLACEHOLDER_EMAIL: "email@example.com",
    ENTERAPP_PLACEHOLDER_PASSWORD: "password",
    ENTERAPP_PLACEHOLDER_NAME: "Your Name",
    //Error verbiage
    ENTERAPP_ERROR_ENTER_NAME: "Please enter a name",
    ENTERAPP_ERROR_ENTER_EMAIL: "Please enter a valid email address",
    ENTERAPP_ERROR_ENTER_PASSWORD: "Please enter a valid password",
    ENTERAPP_ERROR_ENTER_PHONE: "Please enter a 10-digit phone number (###-###-####)",
    //Inline texts
    ENTERAPP_TEXT_RESET_PASSWORD: "Enter your email address to reset your password:",
    //Alerts
    ENTERAPP_ALERT_ERROR_LOGGING_IN: "The username or password does not match our records",
    ENTERAPP_ALERT_ERROR_CREATING_ACCOUNT: "There was a problem creating an account",
    ENTERAPP_ALERT_ERROR_EMAIL_ALREADY_IN_USE: "The email address provided is already in use",
    ENTERAPP_ALERT_RESETTING_PASSWORD: "Please check your email for instructions to reset your password.",
    ENTERAPP_ALERT_NOT_RESETTING_PASSWORD: "There was a problem resetting your password, please try again.",
//About component texts
    //Inline texts
    ABOUT_COLLAPSE_DESCRIPTIONS: [
        {header: "Registries for any item, from any store",
        detail: [
            "No more registering with multiple sites or settling only for items available at the biggest retailers.",
            "Presently allows you to add items from any store."
        ],
        isExpanded: false},
        {header: "Share publicly or privately",
        detail: [
            "Share your list publicly with a share code or make it only viewable to friends you specifically invite."
        ],
        isExpanded: false},
        {header: "Not just for babies and weddings",
        detail: [
            "Forget all the texts and emails asking for gift ideas - just create a list on Presently and share it publicly or privately with your friends.",
            "They can check it out and get the perfect gift before your event or whenever they are feeling generous!",
            "Use it for birthdays, holidays, special events, or even keep a wish list handy for Grandma!"
        ],
        isExpanded: false},
        {header: "Shop local",
        detail: [
            "Support your local shops and artisans by adding items from your favorite local spot.",
            "You can even upload your own photo if they don't have an e-commerce site to link to."
        ],
        isExpanded: false},
        {header: "Collaborate for charities or disaster response",
        detail: [
            "Coordinate what items are needed to support a charity or disaster response drive.",
            "Use Presently to communicate to donors what items are still needed and can make the biggest impact."
        ],
        isExpanded: false},
        {header: "Use as a shopping or supply list",
        detail: [
            "Whether you're redesigning your bedroom or throwing an office party, you can use Presently to keep track of items you need to make it perfect."
        ],
        isExpanded: false},
        {header: "Access from any device",
        detail: [
            "Presently is a progressive web app, which means you can use it on any major browser like Chrome, Safari, Firefox, or Edge without installation.",
            "Or install directly to any device to use it like a native app for a lightning fast, immersive experience - even when you're offline!"
        ],
        isExpanded: false},
        {header: "And more...",
        detail: [
            "Generate a thank you list after your event based on what your friends purchased for you.",
            "Create custom categories to organize your list.",
            "Choose a different 'surprise level' to hide what's been purchased if you don't want to spoil the surprise.",
            "Add admin to your list to help you manage it.",
            "Sort and filter your list based on category, price range, keyword, or what's been purchased.",
            "Items can be marked as 'purchased' so everyone knows what's still needed."
        ],
        isExpanded: false}
    ],

//Help component texts
    //buttons
    HELP_BUTTON_HOME: "Home",
    //inline texts
    HELP_TEXT_CONTACT_US_1: "Please feel free to contact us at",
    HELP_TEXT_CONTACT_US_2: "with any questions or feedback!",
    HELP_TEXT_TITLE: "Presently Help and FAQs",
    HELP_COLLAPSE_DESCRIPTIONS: [
        {header: "What is Presently?",
        detail: [
            "Presently is a web-based application that allows you to create and share gift lists.",
            "Your friends can join your list and see any items that are still needed.",
            "If your friend wants to purchase an item from your list, they can do so directly with the merchant and then mark the item purchased on our app so your other friends know it's already purchased.",
            "Presently can be used to create lists for anything from baby shower and wedding registries to charity drives and birthday lists."
        ],
        isExpanded: false},
        {header: "What are some ways I can use Presently?",
        detail: [
            "There are countless ways to make use of this application; here are a few ideas:",
            "Baby shower registry",
            "Wedding registry",
            "Birthday list",
            "Wish list",
            "Personal shopping list",
            "Charity donations needed list",
            "Disaster response donations needed list",
            "Any supply list that requires collaboration with you and friends or co-workers",
            "Heck, use it for your next pot-luck! Presently has very few restrictions on what can be included on your lists."
        ],
        isExpanded: false},
        {header: "What does it cost to use?",
        detail: [
            "Presently is completely free to use.",
            "You may see a small advertisement displayed on certain pages of the app."
        ],
        isExpanded: false},
        {header: "Do I have to make an account to use Presently?",
        detail: [
            "Yes. Everyone that uses Presently must have an account, which simply includes:",
            "Your name (can be any name you choose - this is simply what will be displayed on your list and to your friends).",
            "Your email address - this is your login ID and is used to email you about invites from your friends and other notifications.  We respect your privacy and will never sell your information!",
            "A password of your choice that must be between 6-12 characters long."
        ],
        isExpanded: false},
        {header: "How do I invite my friends and family to join me on Presently?",
        detail: [
            "Simply log in and click on 'Friends' from the navigation bar at the top.",
            "From your friends page, you can see all your current friends, and can invite new friends by adding their email address."
        ],
        isExpanded: false},
        {header: "How do I make a new list?",
        detail: [
            "To make a new list, log in and select 'Create New List' from the navigation bar at the top.",
            "Add any details you like, such as a name for your list, an event date, a description, a shipping address, and any custom categories you may want.",
            "Be sure to choose the options you want for your list privacy and surprise level, and then click the button that says 'Save and Add Items' to start inviting friends and adding items to your new list.",
            "You can edit any of your list settings or descriptions at any time by going into your list and clicking the button that says 'Edit List' at the top right of the page."
        ],
        isExpanded: false},
        {header: "How do I share my list?",
        detail: [
            "If you make your list 'Public' you will be given a share code that you can share via email, social media, or right on an invitation. Your friends can go to presently.us/give and enter your share code to join your list.",
            "If you make you list 'Private' only people that you specifically invite can view your list.",
            "Just log in and go to your list to see your share code or to invite friends to be 'givers' on your list."
        ],
        isExpanded: false},
        {header: "How do I add items to my list?",
        detail: [
            "From your list, click on the button that says 'Add Item'.",
            "If your item is online, copy and paste the url into the URL box so your friends can easily go to the website to purchase it.",
            "Add a name/title for your item in the Title box",
            "Add any other important details such as price, how many of the item you need, the color or size you need, and a description.",
            "Add a picture of your item by selecting a picture from the website, copy the image url and paste it into the Image URL box.  You will see the picture displayed at the top of the page. You can usually copy an image by right-clicking, ctrl-clicking, or pressing and holding on the image and selecting copy image address, or share image - all depending on what type of device you are using.",
            "If your item is only sold locally, don't worry about adding any URL's. Just be sure to select 'Local' for the Availability option, and you'll be able to upload your own photo.",
            "Then click any of the Save options and your item will be added to your list.  You can edit the item any time by clicking on it and clicking the 'Edit Item' button."
        ],
        isExpanded: false},
        {header: "How do my friends purchase items from my list?",
        detail: [
            "Your friends can view any items that are still needed on your list by logging in and clicking on your list from their Lists page.",
            "When they see an item they'd like to purchase, they can click on the button that says 'Mark Item Purchased' and enter the quantity that they bought.  Once they confirm, your list will be updated to show that the item(s) was purchased."
        ],
        isExpanded: false},
        {header: "How do I make a thank you list?",
        detail: [
            "To generate a thank you list, simply log in, go to your list, and click 'show' next to Private List Details.  In that section, you will see a button that says 'Generate Thank You List'.  Just click the button, and Presently will create a list of everything that's been purchased, who purchased it, and their email address so you can easily be sure to thank them for their generosity!"
        ],
        isExpanded: false},
        {header: "Can anyone see what's been purchased on my list?",
        detail: [
            "No. Only the list owner and admin can see who has purchased what from your list.",
            "If you choose 'Totally' surprised as an option on your list, Presently will hide who has purchased what from you as well so you can truly be surprised for your event.",
            "If you are curious to see your list the same way your friends will, just click on the 'View as Giver' button at the top right of your list page for a preview of how it will look to them."
        ],
        isExpanded: false},
        {header: "Is there a way to see a summary of items on my list?",
        detail: [
            "Yes. Simply go to your list and click 'Show' in the Prive List Details section.",
            "There you will see how many unique items are on your list, how many items have been purchased, and the total amount that's been purchased along with the grand total cost of all items.",
            "Only the list owner and admin can see this information."
        ],
        isExpanded: false},
        {header: "Can I sort and filter a list?",
        detail: [
            "Yes. Just use the toolbar at the top of your list items to search by keyword, or filter by category, price range, or items you have purchased. There are also several options for sorting items."
        ],
        isExpanded: false},
        {header: "How do I change my email, password, or delete my account entirely?",
        detail: [
            "To make any changes to your account or profile, log in and go to 'Profile' from the navigation bar at the top.",
            "There you can change your display name, email address, password, notification preferences, language preference, or delete your entire account and all your lists.",
            "Deleting your account will also delete all of your lists, and remove you from any of your friends' lists as well.  This information can not be recovered."
        ],
        isExpanded: false},
        {header: "Why is my browser prompting me to install this app?",
        detail: [
            "Presently is a Progressive Web App, which means it can run on any browser just like a normal website, but it can also be installed to your desktop or home screen.",
            "The app works exactly the same way whether you install it or not, however, installing it will provide a few benefits such as:",
            "A shortcut that launches a full screen app directly from your desktop or home screen.",
            "A full screen experience, so it feels and runs just like a native app.",
            "Lightning-fast navigation and better reliability when offline or on sketchy networks.",
            "To install, simply follow the prompts from your browser. You can tell your browser to stop asking you to install at any time."
        ],
        isExpanded: false}
    ],
    
//Verify Email component texts
    //inline texts
    VERIFY_EMAIL_ALERT_EMAIL_SENT: "Verification email sent",
    VERIFY_EMAIL_ALERT_EMAIL_NOT_SENT: "Error sending verification email",
//Give component texts
    //placeholders
    GIVE_PLACEHOLDER_SHARE_CODE: "12SHARE345",
//Dashboard component texts
    //button labels
    DASHBAORD_BUTTON_VIEW_ALL: "View All",
    DASHBOARD_BUTTON_VIEW_ALL_LISTS: "View All Lists",
    DASHBOARD_BUTTON_VIEW_ALL_FRIENDS: "View All Friends",
    DASHBOARD_BUTTON_ADD_FRIENDS: "Add Friends",
    DASHBOARD_BUTTON_CREATE_NEW_LIST: "Create New List",
    //inline texts
    DASHBOARD_TEXT_LIST_SINGULAR: "list",
    DASHBOARD_TEXT_LISTS_PLURAL: "lists",
    DASHBOARD_TEXT_FOR: "for",
    DASHBOARD_TEXT_GIVER_SUBSCRIBED_SINGULAR: "giver subscribed",
    DASHBOARD_TEXT_GIVERS_SUBSCRIBED_PLURAL: "givers subscribed",
    DASHBOARD_TEXT_BY: "by",
    DASHBOARD_TEXT_OF: " of ",
    DASHBOARD_TEXT_ITEMS_PURCHASED: " items purchased",
    DASHBOARD_TEXT_ITEMS_TOTAL: " total items",
    DASHBOARD_TEXT_NO_NOTIFICATIONS: "You don't have any notifications yet.",
    DASHBOARD_TEXT_NO_LISTS: "You don't have any lists yet.",

//List component text
    //selection options
    LIST_TEXT_LIST_TYPES: ["Baby Registry","Wedding Registry","Birthday","Holiday","Other Event","Wish List","Shopping List","Charity","Disaster Response"],
    LIST_TEXT_PRIVACY_TYPES: ["Private","Public"],
    LIST_TEXT_PRIVACY_DESCRIPTIONS: [  //a description of what the different privacy types mean
        "Others must have your access code or link to view your list",
        "Others can search for your list by your email"
    ],
    LIST_TEXT_SURPRISE_LEVELS: ["Totally","Mildly","Not"],
    LIST_TEXT_SURPRISE_DESCRIPTIONS: [  //a description of what the different surprise levels mean
        "Can't see what's been purchased",
        "Can see what's been purchased but not by whom",
        "Can see who has purchased what"
        ],
    LIST_TEXT_DEFAULT_CATEGORY: "General",
    //placeholders
    LIST_TEXT_CATEGORY_PLACEHOLDER: "Custom Category",
    LIST_TEXT_FRIENDS_DROPDOWN_PLACEHOLDER: "Choose from Friends",
    LIST_TEXT_ADMIN_PLACEHOLDER: "newadmin@example.com",
    LIST_TEXT_GIVER_PLACEHOLDER: "newgiver@example.com",
    LIST_TEXT_DESCRIPTION_PLACEHOLDER: "Enter a description for this list (if applicable).",
    LIST_TEXT_EVENT_DATE_PLACEHOLDER: "Event Date",
    LIST_TEXT_ADDRESSEE_PLACEHOLDER: "Ship to name (optional)",
    LIST_TEXT_STREET_ADDRESS_PLACEHOLDER: "Street address (optional)",
    LIST_TEXT_CITY_PLACEHOLDER: "City (optional)",
    LIST_TEXT_STATE_PLACEHOLDER: "St (optional)",
    LIST_TEXT_ZIP_PLACEHOLDER: "Zip (optional)",
    LIST_TEXT_NOTES_PLACEHOLDER: "Enter additional notes for this list (if applicable).",
    LIST_TEXT_EXPIRATION_DATE_PLACEHOLDER: "Expiration Date",
    LIST_TEXT_NONE_PLACEHOLDER: "None",
    LIST_TEXT_SEARCH_KEYWORD_PLACEHOLDER: "Keyword",
    LIST_TEXT_DEFAULT_LIST_NAME_APPEND: "'s List",
    //Sort & Filter options (UI)
    LIST_TEXT_SORT_OPTIONS: [
        "Price low to high",
        "Price high to low",
        "Alphabetically A->Z",
        "By Category"
    ],
    LIST_TEXT_PRICE_RANGE_OPTIONS: [
        "All",
        "$0 - $25",
        "$25 - $50",
        "$50 - $100",
        "$100 - $200",
        "$200 +"
    ],
    LIST_TEXT_PURCHASED_OPTIONS: [
        "Still Needed",
        "My Purchases",
        "All"
    ],
    LIST_TEXT_CATEGORY_SELECT_ALL: "Select All",
    //button labels
    LIST_BUTTON_EDIT_DETAILS: "Edit List",
    LIST_BUTTON_VIEW_AS_GIVER: "View as Giver",
    LIST_BUTTON_VIEW_AS_OWNER_ADMIN: "View as Owner/Admin",
    LIST_BUTTON_DELETE_LIST: "Delete List",
    LIST_BUTTON_CANCEL_EDIT: "Cancel Edit",
    LIST_BUTTON_SAVE_CHANGES: "Save Changes",
    LIST_BUTTON_ADD_REMOVE_ADMIN: "Add/Remove Admin",
    LIST_BUTTON_ADD_REMOVE_GIVER: "Add/Remove Giver",
    LIST_BUTTON_DONE: "Done",
    LIST_BUTTON_HIDE: "Hide",
    LIST_BUTTON_SHOW: "Show",
    LIST_BUTTON_ADD: "Add",
    LIST_BUTTON_ADD_ADMIN: "Add Admin",
    LIST_BUTTON_ADD_GIVER: "Add Giver",
    LIST_BUTTON_INVITE_FRIENDS: "Invite New",
    LIST_BUTTON_VIEW_FRIENDS: "View Friends",
    LIST_BUTTON_SEARCH: "Search",
    LIST_BUTTON_FILTER: "Filter",
    LIST_BUTTON_SORT: "Sort",
    LIST_BUTTON_ADD_ITEM: "Add Item",
    LIST_BUTTON_GENERATE_THANK_YOU_LIST: "Generate Thank You List",
    LIST_BUTTON_CANCEL: "Cancel",
    LIST_BUTTON_SAVE_ADD_ITEMS: "Save and Add Items",
    
    //inline texts
    LIST_TEXT_BY: "by",
    LIST_TEXT_PRIVATE_LIST_DETAILS: "Private List Details",
    LIST_TEXT_PRIVATE_LIST_DETAILS_NOTE: "(This section  can only be seen by list owner and admin)",
    LIST_TEXT_INVITED: "(invited)",
    LIST_TEXT_PER_EACH: "/ each",
    LIST_TEXT_NEEDED: "needed",
    LIST_TEXT_UNIQUE_ITEMS: "unique items",
    LIST_TEXT_OF: "of",
    LIST_TEXT_TOTAL_PURCHASED: "total items purchased",
    LIST_TEXT_PURCHASED: "purchased",
    LIST_TEXT_TOTAL: "total",
    LIST_TEXT_NOT_AUTH: "You are not authorized to view this list",
    LIST_TEXT_NOT_VALID: "The list can not be found or the list ID is invalid.",
    LIST_TEXT_GIVER_VIEW_MODE: "You are viewing this list as a Giver.",
    LIST_TEXT_NO_ITEMS: "No items to display",
    LIST_TEXT_CONFIRM_DELETE: "Are you sure you want to delete this list?",
    LIST_TEXT_EXP_EVENT_DATE: "Event Date (optional) is the date of your event.",
    LIST_TEXT_EXP_SHIPPING_ADDRESS: "Shipping Address (optional) is an address that your friends can use to ship items as needed. Check the 'Show Shipping Address' checkbox if you want your friends to be able to see your address.",
    LIST_TEXT_EXP_LIST_TYPE: "List Type: Choose 'Event' if this list is for an event with a specific date; choose 'Ongoing' if this list is not related to an event (i.e. Wish List).",
    LIST_TEXT_EXP_EXPIRATION: "Choose an expiration date (optional) if you want this list automatically deleted after that date.",
    LIST_TEXT_EXP_PRIVACY: "Privacy: Private lists can only be found by users you invite; Public lists can be found by anyone with the share code or link.  Any admin or giver can be removed from your list at any time, regardless of privacy level.",
    LIST_TEXT_EXP_SHARE_CODE: "Use this code to share your list with your friends without having to invite them individually. Just go to presently.us/give and enter the code to join. Share code only works if your list privacy is set to 'Public'.",
    LIST_TEXT_EXP_SHARE_LINK: "Use this link to share your list with your friends without having to invite them individually. Share code only works if your list privacy is set to 'Public'.",
    LIST_TEXT_EXP_SURPRISE1: "Surprise Level: Choose 'Totally' if you do not want to know what's been purchased or by whom.",
    LIST_TEXT_EXP_SURPRISE2: "Choose 'Mildly' if you want to know what's been purchased, but not who has purchased it.",
    LIST_TEXT_EXP_SURPRISE3: "Choose 'Not' if you want to know what's been purchased and by whom.",
    LIST_TEXT_EXP_SURPRISE4: "Surprise Level can be changed at any time.",
    LIST_TEXT_EXP_CATEGORY: "Add categories to organize your list items with.",
    LIST_TEXT_EXP_ADMIN: "Add an admin to help manage your list. Admin can do anything the list owner can do, except add/remove admin or delete the list entirely.",
    LIST_TEXT_EXP_GIVER: "Invite 'givers' to join your list.  Givers can view all items and mark them as purchased.",
    //alerts
    LIST_ALERT_CHANGES_SAVED: "Changes saved",
    LIST_ALERT_CHANGES_NOT_SAVED: "Unable to save changes",
    LIST_ALERT_LIST_ADDED: " saved",
    LIST_ALERT_LIST_NOT_ADDED: "Unable to save new list",
    LIST_ALERT_LIST_DELETED: "List successfully deleted",
    LIST_ALERT_LIST_NOT_DELETED: "Unable to delete list",
    LIST_ALERT_ADMIN_INVITED: " invited as an admin",
    LIST_ALERT_ADMIN_NOT_INVITED: "Unable to invite admin",
    LIST_ALERT_GIVER_INVITED: " invited as a giver",
    LIST_ALERT_GIVER_NOT_INVITED: "Unable to invite giver",
    LIST_ALERT_ADMIN_UNINVITED: " was removed from admin list",
    LIST_ALERT_ADMIN_NOT_UNINVITED: "Unable to remove admin",
    LIST_ALERT_GIVER_UNINVITED: " was removed from giver list",
    LIST_ALERT_GIVER_NOT_UNINVITED: "Unable to remove giver",

    
//Item component texts
    //inline texts
    ITEM_TEXT_NOT_VALID: "The list or item can not be found or is invalid.",
    ITEM_TEXT_GIVER_VIEW_MODE: "You are viewing this item as a Giver. Switch to Owner/Admin view from the list page.",
    ITEM_TEXT_ADD_ITEM: "Add Item",
    ITEM_TEXT_PER_EACH: " / each",
    ITEM_TEXT_$: "$",
    ITEM_TEXT_PURCHASED: "purchased",
    ITEM_TEXT_USER_PURCHASED_1: "You have purchased",
    ITEM_TEXT_USER_PURCHASED_2: "of this item",
    ITEM_TEXT_CONFIRM_UNPURCHASE: "Are you sure you want to mark this item unpurchased?",
    ITEM_TEXT_PURCHASE_WARNING: "Only mark this item as purchased if you have purchased it, as it will be removed from the list.",
    ITEM_TEXT_PURCHASE_MODAL_WARNING: "Only mark this item as purchased if you have purchased it, as it will be removed from the list.",
    ITEM_TEXT_CONFIRM_PURCHASE: "Are you sure you want to mark this item purchased?",
    ITEM_TEXT_SELECT: "Select",
    ITEM_TEXT_CONFIRM_DELETE: "Are you sure you want to delete this item?",
    ITEM_TEXT_EXP_NAME_REQUIRED: "A name for this item is required.",
    ITEM_TEXT_EXP_SAVE_BEFORE_UPLOADING_IMAGE: "Please save item before uploading an image.",
    //alerts
    ITEM_ALERT_ITEM_PURCHASED: " of this item has been marked purchased",
    ITEM_ALERT_ITEM_NOT_PURCHASED: "There was a problem marking the item purchased",
    ITEM_ALERT_ITEM_UNPURCHASED: " of this item has been marked unpurchased",
    ITEM_ALERT_ITEM_NOT_UNPURCHASED: "There was a problem marking the item unpurchased",
    ITEM_ALERT_CHANGES_SAVED: "Changes saved",
    ITEM_ALERT_CHANGES_NOT_SAVED: "Unable to save changes",
    ITEM_ALERT_ITEM_DELETED: "Item successfully deleted",
    ITEM_ALERT_ITEM_NOT_DELETED: "Unable to delete item",
    //button labels
    ITEM_BUTTON_MARK_PURCHASED: "Mark Item Purchased",
    ITEM_BUTTON_MARK_MORE_PURCHASED: "Mark More Purchased",
    ITEM_BUTTON_CHANGE_IMAGE: "Upload Image",
    ITEM_BUTTON_SET_DEFAULT_IMAGE: "Reset Image to Default",
    ITEM_BUTTON_RETURN: "Return to List",
    ITEM_BUTTON_VIEW_MERCHANT_SITE: "View Item on Merchant Website",
    ITEM_BUTTON_AUTO_FILL: "Auto-fill Item",
    ITEM_BUTTON_UNPURCHASE: "Unpurchase Item",
    ITEM_BUTTON_CANCEL: "Cancel",
    ITEM_BUTTON_EDIT_DETAILS: "Edit Item",
    ITEM_BUTTON_CANCEL_EDIT: "Cancel Edit",
    ITEM_BUTTON_DELETE_ITEM: "Delete Item",
    ITEM_BUTTON_SAVE_ITEM: "Save Item",
    ITEM_BUTTON_SAVE_RETURN: "Save and Return to List",
    ITEM_BUTTON_SAVE_ADD: "Save and Add New Item",
    //selection options
    ITEM_TEXT_AVAILABILITY_OPTIONS: ["Online","Local"],
    //placeholders
    ITEM_PLACEHOLDER_URL: "Copy+paste item URL",
    ITEM_PLACEHOLDER_TITLE: "Item title or name",
    ITEM_PLACEHOLDER_PRICE: "0.00",
    ITEM_PLACEHOLDER_MERCHANT: "Merchant/Store Name",
    ITEM_PLACEHOLDER_QUANTITY_REQUESTED: "0",
    ITEM_PLACEHOLDER_COLOR: "Desired color",
    ITEM_PLACEHOLDER_SIZE: "Desired size",
    ITEM_PLACEHOLDER_SPEC: "Other specification",
    ITEM_PLACEHOLDER_IMAGE_URL: "Copy+paste Image URL",
    ITEM_PLACEHOLDER_DESCRIPTION: "Descrition of item",
    ITEM_PLACEHOLDER_NOTES: "Notes about item",


//Friends Component texts
    //inline texts
    FRIENDS_TEXT_LIST_SINGULAR: "list",
    FRIENDS_TEXT_LISTS_PLURAL: "lists",
    FRIENDS_TEXT_ADD_NICKNAME: "Add Nickname",
    FRIENDS_TEXT_NO_FRIENDS: "Invite friends by email above.",
    FRIENDS_TEXT_NO_INVITED_FRIENDS: "You don't have any invited friends yet.",
    FRIENDS_TEXT_CONFIRM_REMOVE_FRIEND_1: "Are you sure you want to remove this friend from your friends list?",
    FRIENDS_TEXT_CONFIRM_REMOVE_FRIEND_2: "This will not remove them from any of your gift lists, nor you from any of their lists.",
    FRIENDS_TEXT_CONFIRM_REMOVE_INVITED_FRIEND_1: "Are you sure you want to remove this friend from your invited friends list?",
    FRIENDS_TEXT_CONFIRM_REMOVE_INVITED_FRIEND_2: "This will not remove them from any of your gift lists, nor you from any of their lists.",
    //Placeholders
    FRIENDS_PLACEHOLDER_NEW_FRIEND_EMAIL: "newfriend@example.com",
    //Alerts
    FRIENDS_ALERT_FRIEND_REMOVED: " was removed from your friends list",
    FRIENDS_ALERT_FRIEND_NOT_REMOVED: " could not be removed from your friends list due to an error",    
    FRIENDS_ALERT_FRIEND_ADDED: " was added to your friends list",
    FRIENDS_ALERT_FRIEND_NOT_ADDED: " could not be added to your friends list due to an error",
    FRIENDS_ALERT_ALREADY_FRIEND: " is already on your friends list",
    FRIENDS_ALERT_ALREADY_INVITED: " has already been invited to your friends list",
    //Buttons
    FRIENDS_BUTTON_UNFRIEND: "Unfriend ",
    FRIENDS_BUTTON_CANCEL: "Cancel",
    FRIENDS_BUTTON_REMOVE_INVITED_FRIEND: "Remove Invited Friend",

//Notification texts
    NOTIFICATIONS_INVITED_FRIEND: " has invited you to join them on Presently!",

//Upload component texts
    //inline texts
    UPLOAD_FILE_PLACEHOLDER: "Choose File",
    UPLOAD_TEXT_WAIT_MESSAGE: "Please wait a moment while we upload and re-size your photo... this may take a minute, depending on the size of your photo.",
    //buttons
    UPLOAD_BUTTON_UPLOAD: "Upload",
    UPLOAD_BUTTON_CANCEL: "Cancel",

//Notifications component texts
    //pagination
    



};