import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable, of } from 'rxjs';
import { DatabaseService } from './database.service';
import { AlertsService } from '../services/alerts.service';
import { User } from '../models/User';
import { Notification } from '../models/Notification';
import { GENERAL_REF } from '../dbReferences';

/**
 * Service used to access Firebase Authentication services throughout the app
 */

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private afAuth: AngularFireAuth,
    private databaseService: DatabaseService,
    private alertsService: AlertsService
    ) { }

   // Attempts to sign in to Firebase Auth with the given credentials
   signin(email: string, password: string) {
    console.log("FBAUTH: signin");
    // Create a promise to be returned when the function is called - specifically returns the userID
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(email, password).then((user) => {
        resolve(user.user.uid)
      }, 
      (err) => {
        reject(err)
        // Notify user 
      })
    });
  }

  // Signs the current user out of Firebase Authentication
  signout() {
    console.log("FBAUTH: signout");
    this.afAuth.auth.signOut();
  }

  // Creates a new firebase authenticated user with the given email and password
  signup(user: User, password: string, originalRoute: string) {
    console.log("FBAUTH: signup");
    // Create a promise to be returned when the function is called - specifically returns the userData object
    return new Promise((resolve, reject) => {
      this.afAuth.auth.createUserWithEmailAndPassword(user.email, password).then(res => {
        user.userId = res.user.uid;
        res.user.sendEmailVerification({url: originalRoute}).then(res => {}).catch(err => {console.log(err)});
        
        let newUser: User = Object.assign({}, user);

        let newFriends: {
          userId: string,
          name: string,
          email: string,
          numLists: number
        }[] = [];
        let listedAsFriendArray: string[] = [];

        let invitedUserSub = this.databaseService.getInvitedUser(newUser.email).subscribe(doc => {
          if(doc.exists) {
            let notifications: Notification[] = doc.data().notifications;
            let userNotifications: Notification[] = notifications.slice(0,3);
            let invitedAsFriendOf: string[] = doc.data().invitedAsFriendOf;
            let numInvites = invitedAsFriendOf.length;

            for (let x = 0; x < numInvites; x++) {
              let userFriendSub = this.databaseService.getUserByUID(invitedAsFriendOf[x]).subscribe(userFriend => {
                if(userFriend.invitedFriends) {
                  for (let i = 0; i < userFriend.invitedFriends.length; i++) {
                    if(userFriend.invitedFriends[i] == newUser.email) {
                      let newUserFriend = {
                        userId: newUser.userId,
                        name: newUser.name,
                        email: newUser.email,
                        numLists: 0
                      }

                      if(userFriend.friends) {
                        userFriend.friends.push(newUserFriend);
                      } else {
                        userFriend.friends = [newUserFriend];
                      }

                      if(userFriend.listedAsFriend) {
                        userFriend.listedAsFriend.push(newUser.userId);
                      } else {
                        userFriend.listedAsFriend = [newUser.userId];
                      }

                      userFriend.invitedFriends.splice(i,1);

                      //Create the notification object 
                      let notificationId = this.databaseService.generateNotificationId();
                      let newNotification: Notification = {
                        notificationId: notificationId,
                        date: new Date(),
                        message: newUser.name 
                          + " (" 
                          + newUser.email 
                          + ") is now your friend on Presently!",
                        routerLink: GENERAL_REF.ROUTES_FRIENDS,
                        email: userFriend.email,
                        languageIndex: userFriend.languageIndex,
                        notificationIndex: userFriend.notificationIndex
                      }

                      if(userFriend.notifications) {
                        userFriend.notifications.unshift(newNotification);
                        if(userFriend.notifications.length > 3) {
                          userFriend.notifications.pop();
                        }
                      } else {
                        userFriend.notifications = [newNotification];
                      }

                      userFriend.numTotalNotifications += 1;

                      this.databaseService.updateUserField(userFriend.userId, {
                        friends: userFriend.friends,
                        invitedFriends: userFriend.invitedFriends,
                        listedAsFriend: userFriend.listedAsFriend,
                        notifications: userFriend.notifications,
                        numTotalNotifications: userFriend.numTotalNotifications
                      });

                      this.databaseService.addNotification(userFriend.userId, newNotification);

                      let newFriend = {
                        userId: userFriend.userId,
                        name: userFriend.name,
                        email: userFriend.email,
                        numLists: 0 
                      }

                      newFriends.push(newFriend);
                      listedAsFriendArray.push(userFriend.userId);

                      break;
                    }
                  }
                }

                if(x == numInvites - 1) {
                  newUser.notifications = userNotifications;
                  newUser.numTotalNotifications = notifications.length;
                  newUser.friends = [...newFriends];
                  newUser.listedAsFriend = [...listedAsFriendArray];
      
                  this.databaseService.addUser(newUser); 
                }
                userFriendSub.unsubscribe();
              });
            };

            notifications.forEach(notification => {
              notification.copiedFromInvitedUser = true;
              this.databaseService.addNotification(newUser.userId, notification)
            }); 

            invitedUserSub.unsubscribe();
            this.databaseService.deleteInvitedUser(newUser.email);
            
          } else {
            this.databaseService.addUser(newUser);
            invitedUserSub.unsubscribe();
          }
        });

        this.databaseService.addUserMeta({email: newUser.email, userId: newUser.userId});

      }, err => reject(err))
    });
  }


  // Returns the active firebase.User auth object 
  getAuthState(): Observable<firebase.User> {
    console.log("FBAUTH: getAuthState");
    return this.afAuth.authState.pipe(auth => auth);
  }

  // Re-verifies the users credentials given the current auth object and the user input password
  reAuthenticateWithPassword(user: firebase.User, password: string) {
    console.log("FBAUTH: reAuthenticateWithPassword");
    // Create a Firebase Credential object 
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, password);
    // Create a promise to return with the userData or error and call the reauth function
    return new Promise((resolve, reject) => {
      user.reauthenticateWithCredential(credential).then((userdata) => {
        resolve()
        //need to show a success flag
      }, 
      (err) => {
        //need to add a flag/message showing error
        reject(err)
      })
    });
  }

  // Send an email change request to Firebase Auth
  changeEmail(user: firebase.User, newEmail: string) {
    console.log("FBAUTH: changeEmail");
    return new Promise((resolve, reject) => {
      user.updateEmail(newEmail).then(() => {
        resolve()
        this.sendEmailVerification(user);
      },(err) => {
        reject(err)
      })
    });
  }

  // Send a password change request to Firebase Auth
  changePassword(user: firebase.User, newPassword: string) {
    console.log("FBAUTH: changePassword");
    return new Promise((resolve, reject) => {
      user.updatePassword(newPassword).then(data => {
        resolve()
      },(err) => {
        reject(err)
      })
    });
  }

  // Deletes a user from Firebase Auth
  deleteUser(user: firebase.User) {
    console.log("FBAUTH: deleteUser");
    return new Promise((resolve, reject) => {
      user.delete().then(() => {
        resolve()
      },(err) => {
        reject(err)
      })
    });
  }

  sendEmailVerification(user: firebase.User) {
    user.sendEmailVerification().then(res => {
      console.log("Email verificaiton sent")
    }).catch(err => {
      console.log("Problem sending email verification", err);
    });
  }

  sendPasswordReset(email: string, originalRoute: string, messageSuccess: string, messageError: string) {
    this.afAuth.auth.sendPasswordResetEmail(email, {url: originalRoute}).then(res => {
      this.alertsService.showNewAlert({
        message: messageSuccess,
        duration: GENERAL_REF.ALERTS_DURATION_STANDARD,
        class: GENERAL_REF.ALERTS_CLASS_SUCCESS
      });
    }).catch(err => {
      this.alertsService.showNewAlert({
        message: messageError,
        duration: GENERAL_REF.ALERTS_DURATION_STANDARD,
        class: GENERAL_REF.ALERTS_CLASS_SUCCESS
      });
    });
  }


}
