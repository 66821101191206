import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Product, DiffbotResponse } from '../models/Product';

@Injectable({
  providedIn: 'root'
})
export class ProductApiService {
  apiEndpoint: string = 'https://api.diffbot.com/v3/product?';
  apiTokenArgument: string = 'token=4026af630d5ca6cbab2a403e089b66f4';
  apiRequestUrl: string;
  apiOptionalArguments: string = '&discussion=false';
  apiUrlArgument:string = '&url=';


  constructor(private http: HttpClient) { }

  getProductDetails(url: string): Promise<Product> {
    this.apiRequestUrl = this.apiEndpoint + this.apiTokenArgument + this.apiOptionalArguments 
      + this.apiUrlArgument + encodeURIComponent(url);

    let product: Product = {};

    return this.http.get<DiffbotResponse>(this.apiRequestUrl).toPromise().then(response => {

      if(response.errorCode) {
        return null;
      } else {
        if(response.objects[0].title) {
          product.title = response.objects[0].title;
        }
        if(response.objects[0].text) {
          product.description = response.objects[0].text;
        }
        if(response.objects[0].offerPriceDetails) {
          product.price = response.objects[0].offerPriceDetails.amount;
        }
        if(response.objects[0].images) {
          product.imageUrls = [];
          response.objects[0].images.forEach(imageData => {
            product.imageUrls.push(imageData.url);
          });
        }
      } 
      return product;
    }).catch(err => {
      return null;
    }); 
  }
}
