import { Component, OnInit } from '@angular/core';
import { GENERAL_REF } from '../../dbReferences';
import { Router, ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/services/database.service';
import { AlertsService } from '../../services/alerts.service';
import { User } from '../../models/User';
import { List } from '../../models/List';
import { Notification } from '../../models/Notification';

@Component({
  selector: 'app-give',
  templateUrl: './give.component.html',
  styleUrls: ['./give.component.css']
})
export class GiveComponent implements OnInit {
  genRef;  //contains general references needed for the database/app
  dbRef; //contains text, phrases and other referencces needed for the database/app - pulled in the user's preferred language
  isLoggedIn: boolean = false;  //denotes if a user is logged in, which hides the navbar if not
  user: User; //the currently logged in user
  shareCode: string; //the share code provided either through input or route parameter
  listArray: List[];
  ownerAdminListArray: string[] = [];  //a string variable used to display a readable list combining the owner name and any admin names
  publicListMatch: boolean = false;
  shareCodeSearched: boolean = false;


  constructor(
    private alertsService: AlertsService,
    private route: ActivatedRoute,
    private router: Router,
    private databaseService: DatabaseService
  ) { }

  ngOnInit() {
    //Get a reference to the general and language specific database references
    this.genRef = GENERAL_REF;
    this.databaseService.dbRef.subscribe(ref => {
      this.dbRef = ref;
    });

    this.databaseService.activeUser.subscribe(user => {
      if(user) {
        this.user = user;
        if(this.shareCode) {
          this.getListByShareCode();
        }
      }
    });

    //Get the relevant share code from the route parameter
    this.shareCode = this.route.snapshot.params[this.genRef.ROUTES_PARAM_SHARE_CODE];
  }

  getListByShareCode() {
    this.shareCode = this.shareCode.toUpperCase();
    this.ownerAdminListArray = [];
    this.publicListMatch = false;
    this.databaseService.getListByShareCode(this.shareCode).then(listArray => {
      this.listArray = listArray;
      this.listArray.forEach(list => {
        if(list.privacyIndex == this.genRef.LIST_PRIVACY_INDEX_PUBLIC) {
          this.publicListMatch = true;
        }
        this.createOwnerAdminList(list);
      });
      this.shareCodeSearched = true;
    });
  }

  searchShareCode({value}: {value: {shareCodeInput: string}}): void {
    this.shareCode = value.shareCodeInput;
    this.getListByShareCode();
  }

    /**
   * Creates a readable list of the owner and any admin (if appicable) to be used for display in the UI
   */
  createOwnerAdminList(list: List) {
    if(list.admin){
      //If the admin list is empty, the ownerAdminList should only include the owner name
      if(list.admin.length == 0) {
        this.ownerAdminListArray.push(list.owner.name);
      } else { //If there are admin, the ownerAdminList should include the owner and all admin names
        let ownerAdminList = list.owner.name;
        for( let i = 0; i < list.admin.length - 1; i++) {
          ownerAdminList += (", " + list.admin[i].name);
        }
        ownerAdminList += (" & " + list.admin[list.admin.length - 1].name);
        this.ownerAdminListArray.push(ownerAdminList);
      }
    } else {
      this.ownerAdminListArray.push(list.owner.name);
    }
  }

  /**
   * Adds the user as a giver to the list and updates all necessary database objects 
   */
  joinAsGiver(index: number) {
    //Check and make sure the list privacy is set to public
    if (this.listArray[index].privacyIndex == this.genRef.LIST_PRIVACY_INDEX_PUBLIC) {
      let list = this.listArray[index];
      let alreadyGiver: boolean = false;
      //Verify that the user is not already a giver on the list
      if(list.givers) {
        for (let i = 0; i < list.givers.length; i++) {
          if(list.givers[i].userId == this.user.userId) {
            alreadyGiver = true;
            break;
          }
        }
      }

      //If the user is already a giver on the list, show an alert
      if(alreadyGiver) {
        this.alertsService.showNewAlert({
          message: "You are already a giver on " + list.name,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_SUCCESS
        });
      }

      if(!alreadyGiver) {
        //Create a new giver object with the current user's info
        let newGiver = {
          userId: this.user.userId,
          name: this.user.name,
          email: this.user.email
        }

        //Add the newGiver object to the givers array of the list, or create it if it doesn't exist
        if(list.givers) {
          list.givers.unshift(newGiver);
        } else {
          list.givers = [newGiver];
        }

        //Check and make sure the user isn't on the invited givers list, and remove them if so
        if(list.invitedGivers) {
          for (let i = 0; i < list.invitedGivers.length; i++) {
            if(list.invitedGivers[i].userId == this.user.userId) {
              list.invitedGivers.splice(i,1);
              break;
            }
          }
        } else {
          list.invitedGivers = [];
        }

        //Update the list doc with the updated givers and invitedGivers arrays
        this.databaseService.updateListField(
          list.listId,
          {
            givers: list.givers,
            invitedGivers: list.invitedGivers
          },
          {
            message: "You are now a giver on " + list.name,
            duration: this.genRef.ALERTS_DURATION_STANDARD,
            class: this.genRef.ALERTS_CLASS_SUCCESS
          },
          {
            message: "There was a problem adding you as a giver to the list.",
            duration: this.genRef.ALERTS_DURATION_STANDARD,
            class: this.genRef.ALERTS_CLASS_DANGER
          }
        );

        //Update this user's friendsLists with the new list info
        //Get the nickname of the owner, if it exists on the user's friends list
        let ownerNickName: string = null;
        if(this.user.friends) {
          for (let i = 0; i < this.user.friends.length; i++) {
            if(this.user.friends[i].userId == list.owner.userId) {
              if(this.user.friends[i].nickName) {
                ownerNickName = this.user.friends[i].nickName;
              }
              break;
            }
          }
        }
        //Create a new friends list object with the new list info
        let newFriendsList = {
          listId: list.listId,
          listName: list.name,
          ownerUserId: list.owner.userId,
          ownerName: list.owner.name,
          ownerNickName: ownerNickName,
        }
        //Add the new friend list object to the friendsLists array or create it if it doesn't exist
        if(this.user.friendsLists) {
          this.user.friendsLists.unshift(newFriendsList);
        } else {
          this.user.friendsLists = [newFriendsList];
        }

        //Update the current user's doc with the new friendsLists array
        this.databaseService.updateUserField(this.user.userId, {friendsLists: this.user.friendsLists});

        //Update the list owner that the invite was accepted
        let listOwnerSub = this.databaseService.getUserByUID(list.owner.userId).subscribe(owner => {

          //Create the notification object 
          let notificationId = this.databaseService.generateNotificationId();
          let newNotification: Notification = {
            notificationId: notificationId,
            date: new Date(),
            message: this.user.name 
              + " (" 
              + this.user.email 
              + ") has joined " 
              + list.name 
              + " as a giver!",
            routerLink: this.genRef.ROUTES_LIST
              + '/' + list.listId,
            email: owner.email,
            languageIndex: owner.languageIndex,
            notificationIndex: owner.notificationIndex
          }

          //Add the notification to the list owner's notifications array
          if(owner.notifications) {
            owner.notifications.unshift(newNotification);
            if(owner.notifications.length > 3) {
              owner.notifications.pop();
            }
          } else {
            owner.notifications = [newNotification];
          }

          //Increment the numGivers in the myLists array
          if(owner.myLists) {
            for (let i = 0; i < owner.myLists.length; i++) {
              if(owner.myLists[i].listId == list.listId) {
                owner.myLists[i].numGivers += 1;
                break;
              }
            }
          }

          owner.numTotalNotifications += 1;

          //Update the list owner's doc with the notification and myLists arrays
          this.databaseService.updateUserField(list.owner.userId, {
            notifications: owner.notifications,
            numTotalNotifications: owner.numTotalNotifications,
            myLists: owner.myLists
          });

          //Add a notification to the list owners notifications collection that the invite was accepted
          this.databaseService.addNotification(list.owner.userId, newNotification);

          //Unsubscribe from the list owner doc
          listOwnerSub.unsubscribe();
        });

        //Update the list admin that the invite was accepted
        if(list.admin) {
          list.admin.forEach(listAdmin => {
            let adminSub = this.databaseService.getUserByUID(listAdmin.userId).subscribe(admin => {
   
              //Create the notification object 
              let notificationId = this.databaseService.generateNotificationId();
              let newNotification: Notification = {
                notificationId: notificationId,
                date: new Date(),
                message: this.user.name 
                  + " (" 
                  + this.user.email 
                  + ") has joined " 
                  + list.name 
                  + " as a giver!",
                routerLink: this.genRef.ROUTES_LIST
                  + '/' + list.listId,
                email: admin.email,
                languageIndex: admin.languageIndex,
                notificationIndex: admin.notificationIndex
              }
            
              //Add the notification to the list admin's notifications array
              if(admin.notifications) {
                admin.notifications.unshift(newNotification);
                if(admin.notifications.length > 3) {
                  admin.notifications.pop();
                }
              } else {
                admin.notifications = [newNotification];
              }
              admin.numTotalNotifications += 1;
              //Update the list owner's doc with the notification and myLists arrays
              this.databaseService.updateUserField(admin.userId, {
                notifications: admin.notifications,
                numTotalNotifications: admin.numTotalNotifications
              });

              //Add a notification to the list admins notifications collection that the invite was accepted
              this.databaseService.addNotification(listAdmin.userId, newNotification);

              //Unsubscribe from the admin's user doc
              adminSub.unsubscribe();
            });

          });
        }

        //Navigate to the list
        this.router.navigate([this.genRef.ROUTES_LIST + '/' + list.listId]);
      }
    }
  }

  goToDashboard() {
    this.router.navigate([this.genRef.ROUTES_DASHBOARD]);
  }

}
