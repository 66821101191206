import { Component, OnInit } from '@angular/core';
import { GENERAL_REF } from '../../dbReferences';
import { DatabaseService } from '../../services/database.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  genRef;  //contains general references needed for the database/app  
  dbRef; //contains text, phrases and other referencces needed for the database/app - pulled in the user's preferred language
  collapseDescriptions;
 

  constructor(private databaseService: DatabaseService) { }

  ngOnInit() {
    //Get a reference to the language specific database reference
    this.genRef = GENERAL_REF;    
    this.databaseService.dbRef.subscribe(ref => {
      this.dbRef = ref;
      this.collapseDescriptions = this.dbRef.HELP_COLLAPSE_DESCRIPTIONS;
    });

  }

  collapse(index: number) {
    this.collapseDescriptions[index].isExpanded = !this.collapseDescriptions[index].isExpanded;
  }

}
