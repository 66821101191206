import { Component, OnInit } from '@angular/core';
import { AuthService} from '../../services/auth.service';
import { Router } from '@angular/router';
import { GENERAL_REF } from '../../dbReferences';
import { DatabaseService } from '../../services/database.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  genRef;  //contains general references needed for the database/app
  dbRef; //contains text, phrases and other referencces needed for the database/app - pulled in the user's preferred language
  

  constructor(
    private authService: AuthService,
    private router: Router,
    private databaseService: DatabaseService
  ) { }

  ngOnInit() {
    //Get a reference to the general and language specific database references
    this.genRef = GENERAL_REF;
    this.databaseService.dbRef.subscribe(ref => {
      this.dbRef = ref;
    })
    // Check to see if the user is already authenticated
    this.authService.getAuthState().subscribe(auth => {
      // If authenticated, no sign in required, redirects to Dashboard Component.
      if(auth) {
        this.router.navigate(['/'+ this.genRef.ROUTES_DASHBOARD]);
      } else {
      }
    });
  }

}
