import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

function padNumber(value: number) {
    if (isNumber(value)) {
        return `0${value}`.slice(-2);
    } else {
        return "";
    }
}

function isNumber(value: any): boolean {
    return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
}


@Injectable()
export class NgbDateFRParserFormatter extends NgbDateParserFormatter {

    //parses an entered date in the following formats and creates a date object:
        //year only: year
        //month and year only: month/year
        //full date: month/day/year 
    parse(value: string): NgbDateStruct {
        if (value) {
            const dateParts = value.trim().split(/\D/);
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                if(toInteger(dateParts[0]) == 0){
                    return {year: 2000, month: 1, day: 1};
                } else {
                    return {year: toInteger(dateParts[0]), month: 1, day: 1};
                }
            } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                if(toInteger(dateParts[1])==0) {
                    return {year: 2000, month: toInteger(dateParts[0]), day: 1};
                } else {
                    return {year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: 1};
                }
            } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                if(toInteger(dateParts[2]) == 0){
                    return {year: 2000, month: toInteger(dateParts[0]), day: toInteger(dateParts[1])};
                } else {
                    return {year: toInteger(dateParts[2]), month: toInteger(dateParts[0]), day: toInteger(dateParts[1])};
                }
            }
        }   
        return null;
    }

    format(date): string {
        let stringDate: string = ""; 
        let formattedDate: string;
        if(date) {
            stringDate += isNumber(date.month) ? padNumber(date.month) + "/" : "";
            stringDate += isNumber(date.day) ? padNumber(date.day) + "/" : "";
            stringDate += date.year;
            let monthNames = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
            let dateFormat = new Date(stringDate);
            formattedDate = monthNames[dateFormat.getMonth()] + " " + dateFormat.getDate() + ", " + dateFormat.getFullYear(); 
            return formattedDate;
        } else {
            return null;
        }
    }
}