import { Component, OnInit } from '@angular/core';
import { GENERAL_REF } from '../../dbReferences';
import { DatabaseService } from 'src/app/services/database.service';
import { ProductApiService } from '../../services/product-api.service';
import { StorageService } from '../../services/storage.service';
import { AlertsService } from '../../services/alerts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { List } from '../../models/List';
import { User } from '../../models/User';
import { ListView } from '../../models/ListView';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

/**
 * Displays a list item and allows user to edit/delete/add it
 */

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {
  genRef;  //contains general references needed for the database/app
  dbRef; //contains text, phrases and other referencces needed for the database/app - pulled in the user's preferred language
  user: User;  //the User object for the current user  
  listId: string; //the unique ID of the list that is being displayed.  Equal to the firebase doc ID for this list document.
  list: List; //the original List object directly from the database that can't be changed via the UI unless changes are sent to the database first.
  listView: ListView //Contains several view settings for the list, needed in Item component to indicate if in Giver View Mode
  itemId: string; //the ID of the item that is being displayed.  Equal to the array index number of the item in the list document.
  itemIndex: number; //the index number of the item in the list
  editedItem?: {  //the item that is being displayed or edited
    itemId?: string;  //The unique ID for the item
    title?: string;  //the name or title of the item
    url?: string; //the url of the item - can be used to pull product details from web
    price?: number;  //the price of the item
    merchant?: string; //The merchant or vendor where the item can be purchased
    availabilityIndex?: number; //Indicates if the item can be purchased online or locally
    category?: string; //the category the item falls into (out of the list custom categories)
    description?: string; //a description for the item
    notes?: string; //general notes about the item
    color?: string; //the preferred color of the item
    size?: string; //the preferred size of the item
    specification?: string;  //any specification for the item that should be referenced
    quantityRequested?: number; //the quantity requested
    quantityPurchased?: number;  //the total quantity purhcasesd by givers
    quantityRemaining?: number;  //the difference between quantity requested and quanity purchased
    purchasedBy?: { //the user(s) that purchased the item
        userId?: string,
        name?: string,
        nickName?: string,
        email?: string
    }[]; 
    thumbnailUrl?: string;  //the URL for the image associated with the item
};
  editMode: boolean = false; //Indicates if the item is being edited
  isNewItem: boolean = false; //indicates if this is a new item to be added or if viewing an existing saved item from the list
  listPrivilege: string;  //a variable that indicates the current privilege level of the current user for the current list (Owner, Admin, Giver, or NotAuth)
  editedItemCategoryDropdownCollapsed: boolean = false;  //Indicates if the Category dropdown element is collapsed or not
  availabilityDropdownCollapsed: boolean = false;  //Indicates if the Availability dropdown element is collapsed or not
  showInitialPurchaseButtton: boolean = true; //Indicates if the first Mark Item Purchased button should be shown
  showPurchaseItemForm: boolean = false; //Indicates if the form to mark an item purchased should be shown
  quantityPurchased: number = 1;  //Represents the quantity of the item the user is purchasing
  userHasPurchased: boolean = false; //Indicates if the current user has already purchased this item or not
  userQtyAlreadyPurchased: number;  //The quantity of the item that the current user has already purchased
  showInitialUnpurchaseButtton: boolean = true;  //Indicates if the first Mark Item Unpurchased button should be shown
  showUnpurchaseItemForm: boolean = false; //Indicates if the form to mark an item unpurchased should be shown
  quantityUnpurchased: number = 1;  //Represents the quantity of the item the user is unpurchasing
  closeResult: string;  // Used by modal to indicate why/how it was closed
  editItemImage: boolean = false;  //Indicates if the item can have an image uploaded to it or not
  showEditImageAlert: boolean = false;  //Indicates whether or not the alert/explanation message should be shown for the item image
  requestingProductApi: boolean = false;
  productUrls: string[];
  currentProductUrlIndex: number = 0;
  showInvalidUrlExp: boolean = false;

  constructor(
    private databaseService: DatabaseService,
    private storageService: StorageService,
    private alertsService: AlertsService,
    private productApiService: ProductApiService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    //Get a reference to the general and language specific database references
    this.genRef = GENERAL_REF;
    this.databaseService.dbRef.subscribe(ref => {
      this.dbRef = ref;
    });
    //get the user Object from the User Behavior Subject
    this.databaseService.activeUser.subscribe(user => {
      this.user = user;
    });
    //Get the relevant listId from the route parameter
    this.listId = this.route.snapshot.params[this.genRef.ROUTES_PARAM_LIST_ID];
    //Get the relevant itemId from the route parameter
    this.itemId = this.route.snapshot.params[this.genRef.ROUTES_PARAM_ITEM_ID];
    if(this.itemId == "new"){
      this.isNewItem = true;
      this.editMode = true;
    }
    //Pull the List object from the Behavior Subject, if it exists or query the database if needed
    //Anytime the database list is updated, the activeList Behavior Subject will be updated as well
    this.databaseService.activeList.subscribe(list => {
      if(list) {
        console.log("The list id is: ", list.listId, this.listId)
      }
      //If there is no activeList object, then query the database for the List by listId
      if(list == null){
        console.log('THE ACTIVE LIST IS NULL')
        this.getUpdatedList();
        //Change listId to invalid to prevent the subscriptions from getting stuck in a loop
        this.listId = this.genRef.LIST_TEXT_LIST_ID_INVALID
      } else if(list.listId == this.listId || this.listId == this.genRef.LIST_TEXT_LIST_ID_INVALID) { 
        //If there is an activeList and the listId is the same as the listId from the route parameter, set that as the current list
        //Change listId to blocked to prevent the subscriptions from getting stuck in a loop
        this.listId = this.genRef.LIST_TEXT_LIST_ID_BLOCKED;
        console.log('THE LIST ID MATCHES*****')
        //Set the returned List object to the list variable
        this.list = list;
        //Determine what the current user's permission/privilege level is for this particular list/item
        this.setListPrivilege();
        //Get the listView settings from the activeListView to determine if the user is viewing in Giver View Mode
        this.databaseService.activeListView.subscribe(listView => {
          if(this.editItemImage == true) {
            this.alertsService.showNewAlert({
              message: "The image has been updated.",
              duration: this.genRef.ALERTS_DURATION_STANDARD,
              class: this.genRef.ALERTS_CLASS_SUCCESS
            })
            this.editItemImage = false;
            window.scrollTo(0,0);
          }
          this.listView = listView;
          if(this.listView == null) {
            this.listView = {};
            this.listView.giverView = false;
          }
        });
        
        //If the item is new, set default values
        if(this.isNewItem) {
          this.editedItem = {
            price: this.genRef.ITEM_DEFAULT_PRICE,
            category: this.dbRef.LIST_TEXT_DEFAULT_CATEGORY,
            quantityRequested: this.genRef.ITEM_DEFAULT_QUANTITY_REQUESTED,
            quantityPurchased: this.genRef.ITEM_DEFAULT_QUANTITY_PURCHASED,
            quantityRemaining: this.genRef.ITEM_DEFAULT_QUANTITY_REMAINING,
            availabilityIndex: this.genRef.ITEM_DEFAULT_AVAILABILITY_INDEX,
            thumbnailUrl: this.genRef.ITEM_DEFAULT_THUMBNAIL_URL,
          };
        } else {
          this.editedItem = null;
          //If the item is not new, copy it and nested objects/arrays to the editedItem object
          if(list.items) {
            for (let i = 0; i < this.list.items.length; i++) {
              if(this.itemId == this.list.items[i].itemId) {
                //Set the index number of this item in the list for use throughout the component
                this.itemIndex = i;
                this.editedItem = Object.assign({}, this.list.items[this.itemIndex]);
                if(this.list.items[this.itemIndex].purchasedBy){
                  this.editedItem.purchasedBy = [];
                  for (let x = 0; x < this.list.items[this.itemIndex].purchasedBy.length; x++) {
                    this.editedItem.purchasedBy[x] = Object.assign({}, this.list.items[this.itemIndex].purchasedBy[x]);
                  }
                }
                break;
              }
            }
          }
          //Determine if the current user has already purchased this item and the quantity purchased
          if(this.editedItem) {
            if(this.list.items[this.itemIndex].purchasedBy){
              for (let i = 0; i < this.list.items[this.itemIndex].purchasedBy.length; i++) {
                if(this.user.userId == this.list.items[this.itemIndex].purchasedBy[i].userId) {
                  this.userHasPurchased = true;
                  this.userQtyAlreadyPurchased = this.list.items[this.itemIndex].purchasedBy[i].quantityPurchased;
                  break;
                }
              }
            }
          }
        }
      } else if(list.listId !== this.listId 
          && this.listId !== this.genRef.LIST_TEXT_LIST_ID_BLOCKED 
          && this.listId !== this.genRef.LIST_TEXT_LIST_ID_INVALID) {
        //If there is an activeList but it is not the same as the listId from the route parameter, and is not blocked or invalid,
        //query the database for the List by listId
        console.log('THE LIST ID DOES NOT MATCH**********')
        this.getUpdatedList();
      }
    })
  }

  /**
   * Queries the database for a list by list ID
   */
  getUpdatedList() {
    //Check and make sure the listId is not blocked or invalid to prevent getting stuck in a loop
    if(this.listId !== this.genRef.LIST_TEXT_LIST_ID_BLOCKED && this.listId !== this.genRef.LIST_TEXT_LIST_ID_INVALID){
      this.databaseService.getListByListId(this.listId).subscribe(list => {
        //Set the listId back to the actual list id 
        // this.listId = list.listId;
        //Update the activeList behavior subject with the latest list
        this.databaseService.updateActiveList(list);
      });
    }
  }

  /**
   * Determines the privilege level of the current user for the current list
   * Can be either Owner, Admin, Giver, or NotAuth
   */
  setListPrivilege() {
    //Check if the current userId is equal to the list owner userId and if so set the privilege to Owner
    if(this.user.userId === this.list.owner.userId) {
      this.listPrivilege = this.genRef.LIST_PRIVILEGES_OWNER;
      return;
    } else { 
    //Check if the current userId is equal to any of the list admin userId's and if so set the privilege to Admin
      let isAdmin: boolean = false;
      if(this.list.admin) {
        for (let i = 0; i < this.list.admin.length; i++) {
          if (this.user.userId === this.list.admin[i].userId) {
            isAdmin = true;
            break;
          }
        }
      }
      if (isAdmin) {
        this.listPrivilege = this.genRef.LIST_PRIVILEGES_ADMIN;
        return;
      } else {  
      //Check if the current userId is equal to any of the list Givers userId's and if so set the privilege to Giver
        let isGiver: boolean = false;
        if(this.list.givers) {
          for (let i = 0; i < this.list.givers.length; i++) {
            if (this.user.userId === this.list.givers[i].userId) {
              isGiver = true;
              break;
            }
          }
        }
        if (isGiver) {
          this.listPrivilege = this.genRef.LIST_PRIVILEGES_GIVER;
          return;
        } else {  
        //If the userId does not match any of the above privileges, set to NotAuth which will not allow the list to be shown
          this.listPrivilege = this.genRef.LIST_PRIVILEGES_NOT_AUTH;
          return;
        }
      }
    }
  }

  /**
   * Creates a random 9 digit item ID consisting of letters and numbers
   */
  generateItemId(): string {
    if(this.isNewItem) {
      return Math.random().toString(36).substr(2, 9);
    }
  }

  /**
   * Changes the category of the editedItem when the user selects a new one from the dropdown
   * Toggles the dropdown element
   * @param index is the index number of the category that the user selected
   */
  selectCategory(index: number) {
    this.editedItemCategoryDropdownCollapsed = !this.editedItemCategoryDropdownCollapsed;
    this.editedItem.category = this.list.categories[index];
  }

  /**
   * Changes the availability of the editedItem when the user selects a new one from the dropdown
   * Toggles the dropdown element
   * @param index is the index number of the selected availability of the `dbRef.ITEM_TEXT_AVAILABILITY_OPTIONS` array
   */
  selectNewAvailability(index: number) {
    this.availabilityDropdownCollapsed = !this.availabilityDropdownCollapsed;
    this.editedItem.availabilityIndex = index;
  }

  /**
   * When the user indicates they have purchased an item, this function calculates new quantities purchased/needed for the
   * item, and updates the list as well as the appropriate user objects
   * this.quantityPurchased variable is the quantity that the user has indicated they have purchased
   */
  markItemPurchased() {
    this.listId = this.list.listId;
    //Set variables for the quantity of the item that was already purchased/remaining 
    let qtyPurchased: number = this.list.items[this.itemIndex].quantityPurchased;
    let qtyRemaining: number = this.list.items[this.itemIndex].quantityRemaining;
    //Set variables for the total quantity and cost already purchased for the list
    let qtyTotalListItemsPurchased: number = this.list.numQuantityItemsPurchased;
    let amountPurchased: number = this.list.costPurchased; 

    //If the user has purchased more than qty 0, then increment the existing quantities by the purchased amount
    if(this.quantityPurchased > 0){
      qtyPurchased += this.quantityPurchased;
      qtyTotalListItemsPurchased += this.quantityPurchased;
      amountPurchased += (this.quantityPurchased * this.list.items[this.itemIndex].price);
      //decrement the existing quantities remaining by the purchased amount
      qtyRemaining -= this.quantityPurchased;
      if(qtyRemaining < 0) {
        qtyRemaining = 0;
      };
    };

    //Set the items quantities purchased and remaining to the newly calculated quantities
    this.list.items[this.itemIndex].quantityPurchased = qtyPurchased;
    this.list.items[this.itemIndex].quantityRemaining = qtyRemaining;
    //Set the total quantity and cost purchased for the list to the newly calculated amounts
    this.list.numQuantityItemsPurchased = qtyTotalListItemsPurchased;
    this.list.costPurchased = amountPurchased;

    //Create a user object variable to hold the details of the current user making the purchase
    let purchasingUser: {      
      userId?,
      name?,
      email?,
      nickName?,
      quantityPurchased?
    };

    //If the current user is a giver on the list, then pull their info from the givers array and set it to the purchasingUser variable
    if(this.listPrivilege == this.genRef.LIST_PRIVILEGES_GIVER) {
      for (let i = 0; i < this.list.givers.length; i++) {
        if(this.list.givers[i].userId == this.user.userId){
          let nickName: string = null;
          if(this.list.givers[i].nickName) {
            nickName = this.list.givers[i].nickName;
          }
          purchasingUser = {
            userId: this.list.givers[i].userId,
            name: this.list.givers[i].name,
            email: this.list.givers[i].email,
            nickName: nickName,
            quantityPurchased: this.quantityPurchased
          }
          break;
        }
      }
    } else if(this.listPrivilege == this.genRef.LIST_PRIVILEGES_ADMIN) {
    //If the current user is an admin on the list, then pull their info from the admin array and set it to the purchasingUser variable
      for (let i = 0; i < this.list.givers.length; i++) {
        if(this.list.admin[i].userId == this.user.userId){
          let nickName: string = null;
          if(this.list.admin[i].nickName) {
            nickName = this.list.admin[i].nickName;
          }
          purchasingUser = {
            userId: this.list.admin[i].userId,
            name: this.list.admin[i].name,
            email: this.list.admin[i].email,
            nickName: nickName,
            quantityPurchased: this.quantityPurchased
          }
          break;
        }
      }
    } else if (this.listPrivilege == this.genRef.LIST_PRIVILEGES_OWNER) {
    //If the current user is the owner on the list, then pull their info from the current user and set it to the purchasingUser variable
      purchasingUser = {
        userId: this.user.userId,
        name: this.user.name,
        email: this.user.email,
        quantityPurchased: this.quantityPurchased
      }
    }

    //If the current user has already purchased the item, update their quantity purchased with the new quantity
    if(this.userHasPurchased) {
      for (let i = 0; i < this.list.items[this.itemIndex].purchasedBy.length; i++) {
        if(purchasingUser.userId == this.list.items[this.itemIndex].purchasedBy[i].userId) {
          this.list.items[this.itemIndex].purchasedBy[i].quantityPurchased += this.quantityPurchased;
          break;
        }

      }
    } else if(this.list.items[this.itemIndex].purchasedBy) {
    //If the current user has not already purchased the item, add them to the purchased by array or create it if it doesn't exist
      this.list.items[this.itemIndex].purchasedBy.unshift(purchasingUser);      
    } else {
      this.list.items[this.itemIndex].purchasedBy = [purchasingUser];            
    }

    //Update the list with the new item and quantity data, show confirmations
    this.databaseService.updateListField(
      this.list.listId, 
      {
      items: this.list.items,
      numQuantityItemsPurchased: this.list.numQuantityItemsPurchased,
      costPurchased: this.list.costPurchased
      },
      {
        message: this.quantityPurchased + this.dbRef.ITEM_ALERT_ITEM_PURCHASED,
        duration: this.genRef.ALERTS_DURATION_STANDARD,
        class: this.genRef.ALERTS_CLASS_SUCCESS
      },
      {
        message: this.dbRef.ITEM_ALERT_ITEM_NOT_PURCHASED,
        duration: this.genRef.ALERTS_DURATION_STANDARD,
        class: this.genRef.ALERTS_CLASS_DANGER
      }
    );

    //Update list owner's myLists with numQuantityItemsPurchased
    //If the current user is not the owner, get the owner's user doc from the database
    if(this.user.userId !== this.list.owner.userId) {
      let ownerUserSub = this.databaseService.getUserByUID(this.list.owner.userId).subscribe(user => {
        for (let i = 0; i < user.myLists.length; i++) {
          if(this.list.listId == user.myLists[i].listId) {
            user.myLists[i].numQuantityItemsPurchased = this.list.numQuantityItemsPurchased;
            break;
          };
        }
        this.databaseService.updateUserField(this.list.owner.userId, {myLists: user.myLists});
        ownerUserSub.unsubscribe();
      });
    } else {
    //If the current user is the owner, update their myLists array
      for (let i = 0; i < this.user.myLists.length; i++) {
        if(this.list.listId == this.user.myLists[i].listId) {
          this.user.myLists[i].numQuantityItemsPurchased = this.list.numQuantityItemsPurchased;
          break;
        };
      }
      this.databaseService.updateUserField(this.list.owner.userId, {myLists: this.user.myLists});
    }

    //Close the showPurchaseItemForm
    this.showPurchaseItemForm = false;
    //Show the initial purchase button
    this.showInitialPurchaseButtton = true;
    //Close the modal
    this.modalService.dismissAll('confirmed');
  }

  /**
   * When the user indicates they have unpurchased an item, this function calculates new quantities purchased/needed for the
   * item, and updates the list as well as the appropriate user objects
   * this.quantityUnpurchased variable is the quantity that the user has indicated they have unpurchased
   */
  markItemUnpurchased() {
    this.listId = this.list.listId;
    //Set variables for the quantity of the item that was already purchased/remaining 
    let qtyPurchased: number = this.list.items[this.itemIndex].quantityPurchased;
    let qtyRemaining: number = this.list.items[this.itemIndex].quantityRemaining;
    //Set variables for the total quantity and cost already purchased for the list
    let qtyTotalListItemsPurchased: number = this.list.numQuantityItemsPurchased;
    let amountPurchased: number = this.list.costPurchased; 

    //If the user has unpurchased more than qty 0, then decrement the existing quantities by the unpurchased amount
    if(this.quantityUnpurchased > 0){
      qtyPurchased -= this.quantityUnpurchased;
      qtyTotalListItemsPurchased -= this.quantityUnpurchased;
      amountPurchased -= (this.quantityUnpurchased * this.list.items[this.itemIndex].price);
      //increment the existing quantities remaining by the unpurchased amount
      qtyRemaining = this.list.items[this.itemIndex].quantityRequested - qtyPurchased;
      if(qtyRemaining < 0) {
        qtyRemaining = 0;
      };
    };

    //Set the items quantities purchased and remaining to the newly calculated quantities
    this.list.items[this.itemIndex].quantityPurchased = qtyPurchased;
    this.list.items[this.itemIndex].quantityRemaining = qtyRemaining;
    //Set the total quantity and cost purchased for the list to the newly calculated amounts
    this.list.numQuantityItemsPurchased = qtyTotalListItemsPurchased;
    this.list.costPurchased = amountPurchased;

    //Loop through the purchasedBy array for the item
    for (let i = 0; i < this.list.items[this.itemIndex].purchasedBy.length; i++) {
      if(this.user.userId == this.list.items[this.itemIndex].purchasedBy[i].userId) {
        //If the user unpurchased the same amount that they had previously purchased, remove them from the purchasedBy array entirely
        if(this.list.items[this.itemIndex].purchasedBy[i].quantityPurchased == this.quantityUnpurchased) {
          this.list.items[this.itemIndex].purchasedBy.splice(i,1);
          this.userHasPurchased = false;
          this.userQtyAlreadyPurchased = null
        } else {
        //If the user only unpurchased a partial quantity of what they had already purchased, decrement the quantity purchased by the unpurchased amount
          this.list.items[this.itemIndex].purchasedBy[i].quantityPurchased -= this.quantityUnpurchased;
        }
        break;
      }
    }

    //Update the list with the new item and quantity data, show confirmations
    this.databaseService.updateListField(
      this.list.listId, 
      {
        items: this.list.items,
        numQuantityItemsPurchased: this.list.numQuantityItemsPurchased,
        costPurchased: this.list.costPurchased
      },
      {
        message: this.quantityPurchased + this.dbRef.ITEM_ALERT_ITEM_UNPURCHASED,
        duration: this.genRef.ALERTS_DURATION_STANDARD,
        class: this.genRef.ALERTS_CLASS_SUCCESS
      },
      {
        message: this.dbRef.ITEM_ALERT_ITEM_NOT_UNPURCHASED,
        duration: this.genRef.ALERTS_DURATION_STANDARD,
        class: this.genRef.ALERTS_CLASS_DANGER
      }
    );

    //Update list owner's myLists with numQuantityItemsPurchased
    //If the current user is not the owner, get the owner's user doc from the database
    if(this.user.userId !== this.list.owner.userId) {
      let ownerUserSub = this.databaseService.getUserByUID(this.list.owner.userId).subscribe(user => {
        for (let i = 0; i < user.myLists.length; i++) {
          if(this.list.listId == user.myLists[i].listId) {
            user.myLists[i].numQuantityItemsPurchased = this.list.numQuantityItemsPurchased;
            break;
          };
        }
        this.databaseService.updateUserField(this.list.owner.userId, {myLists: user.myLists});
        ownerUserSub.unsubscribe();
      });
    } else {
    //If the current user is the owner, update their myLists array
      for (let i = 0; i < this.user.myLists.length; i++) {
        if(this.list.listId == this.user.myLists[i].listId) {
          this.user.myLists[i].numQuantityItemsPurchased = this.list.numQuantityItemsPurchased;
          break;
        };
      }
      this.databaseService.updateUserField(this.list.owner.userId, {myLists: this.user.myLists});
    }

    //Close the showPurchaseItemForm
    this.showUnpurchaseItemForm = false;
    //Show the initial purchase button
    this.showInitialUnpurchaseButtton = true;
    //Close the modal
    this.modalService.dismissAll('confirmed');
  }

  /**
   * Enables editMode, which allows the owner or admin to update the list details in the UI
   */
  enableEditMode() {
    this.editMode = true;
    this.showPurchaseItemForm = false;
    this.showInitialPurchaseButtton = true;
    this.showUnpurchaseItemForm = false;
    this.showInitialUnpurchaseButtton = true;
  }

  /**
   * Cancels edit mode and resets variables
   */
  cancelEdit() {
    if(this.isNewItem) {
      //If working on a new item, go back to list
      this.router.navigate([this.genRef.ROUTES_LIST + '/' + this.list.listId]);
    } else {
      //If not a new item, then cancel edit mode and recopy item
      this.editMode = false;
      this.editedItem = Object.assign({}, this.list.items[this.itemIndex]);
      if(this.list.items[this.itemIndex].purchasedBy){
        this.editedItem.purchasedBy = [];
        for (let x = 0; x < this.list.items[this.itemIndex].purchasedBy.length; x++) {
          this.editedItem.purchasedBy[x] = Object.assign({}, this.list.items[this.itemIndex].purchasedBy[x]);
        }
      }
    }
  }

  /**
   * Saves any edits made to the item, or saves the new item if new.  Updates list quantities and user objects as well as needed.
   * @param nextStepIndex a number indicating what action to take after all saves are complete, depending on which button was pressed.
   */
  saveEdit(nextStepIndex: number) {
    //If the title of the item was left blank, do not proceed - must have a name/title.
    if(this.editedItem.title == null || this.editedItem.title == '') {
      return;
    }
    //If the price was left null, set it to the default price (zero)
    if(this.editedItem.price == null) {
      this.editedItem.price = this.genRef.ITEM_DEFAULT_PRICE;
    }
    //Set variables for the total quantity of items and cost of items for the entire list
    let changeInNumQuantityItemsTotal: number;
    let changeInCostTotal: number;
    let changeInCostPurchased: number;
    //If the current item is a new item, execute special processes for new items only
    if(this.isNewItem) {
      //Generate a new item ID
      this.editedItem.itemId = this.generateItemId();
      //The item is new so whatever quantity was requested = the change in quantity
      changeInNumQuantityItemsTotal = this.editedItem.quantityRequested;
      //The item is new so the quantity remaining = the quantity requested
      this.editedItem.quantityRemaining = this.editedItem.quantityRequested;
      //Add 1 to the number of unique items included on the list
      this.list.numUniqueItemsTotal += 1;
      //The change in total cost for the list is equal to the price/ea of the item times the quantity requested
      changeInCostTotal = this.editedItem.price * this.editedItem.quantityRequested;
      //Add the new item to the list items array or create it if it doesn't exist
      if(this.list.items){
        this.list.items.unshift(this.editedItem);
      } else {
        this.list.items = [this.editedItem];
      }
    } else {
    //If the current item is not new, execute processes for editing an existing item only
      //If there is an existing image uploaded to storage, check and see if the image URL was edited manually, which would mean
      //the storage image is no longer being used and it can be deleted
      if(this.list.items[this.itemIndex].imageUrlStoragePath) {
        if(this.list.items[this.itemIndex].thumbnailUrl !== this.editedItem.thumbnailUrl) {
          this.storageService.deleteFile(this.list.items[this.itemIndex].imageUrlStoragePath);
          this.list.items[this.itemIndex].imageUrlStoragePath = null;
        }
      }
      //The change in the total quantity of items on the list is equal to the new requested quantity minus the old requested quantity
      changeInNumQuantityItemsTotal = this.editedItem.quantityRequested - this.list.items[this.itemIndex].quantityRequested;
      //The quantity remaining for this item is equal to the new quantity requested minus the quantity purchased
      this.editedItem.quantityRemaining = this.editedItem.quantityRequested - this.editedItem.quantityPurchased;
      //The change in total cost of the items on the list is equal to the new price times quantity requested minues the old price times quantity requested
      changeInCostTotal = (this.editedItem.price * this.editedItem.quantityRequested) - (this.list.items[this.itemIndex].price * this.list.items[this.itemIndex].quantityRequested)
      //the change in total amount purchased of the items on the lis is equal to the new price times quantity purchased minus the old price times quantity purchased
      changeInCostPurchased = (this.editedItem.price * this.editedItem.quantityPurchased) - (this.list.items[this.itemIndex].price * this.list.items[this.itemIndex].quantityPurchased)
      //Copy the values for the editedItem to the actual list.items object
      this.list.items[this.itemIndex] = this.editedItem;
    }

    //Add the calculated change in total quantity of items to the original quantity of items for the list
    this.list.numQuantityItemsTotal += changeInNumQuantityItemsTotal;
    //Add the calculated change in total cost of items to the original cost of items for the list
    this.list.costTotal += changeInCostTotal;
    //Add the calculated change in total amount purchased of items to the original amount purchased of items for the list
    this.list.costPurchased += changeInCostPurchased;

    //Update the list doc with the new items array, number of unique items, total quantity of items, and total cost of items, show confirmation
    this.databaseService.updateListField(
      this.list.listId, 
      {
        items: this.list.items,
        numUniqueItemsTotal: this.list.numUniqueItemsTotal,
        numQuantityItemsTotal: this.list.numQuantityItemsTotal,
        costTotal: this.list.costTotal
      },
      {
        message: this.dbRef.ITEM_ALERT_CHANGES_SAVED,
        duration: this.genRef.ALERTS_DURATION_STANDARD,
        class: this.genRef.ALERTS_CLASS_SUCCESS
      },
      {
        message: this.dbRef.ITEM_ALERT_CHANGES_NOT_SAVED,
        duration: this.genRef.ALERTS_DURATION_STANDARD,
        class: this.genRef.ALERTS_CLASS_DANGER
      }
    );

    //Update owner myLists with numQuantityItemsTotal if it changed
    if(changeInNumQuantityItemsTotal !== 0) {
      //If the current user is not the owner, pull the owner user doc from the database and update it with the new quantity
      if(this.user.userId !== this.list.owner.userId) {
        let ownerUserSub = this.databaseService.getUserByUID(this.list.owner.userId).subscribe(user => {
          for (let i = 0; i < user.myLists.length; i++) {
            if(this.list.listId == user.myLists[i].listId) {
              user.myLists[i].numQuantityItemsTotal = this.list.numQuantityItemsTotal;
              break;
            };
          }
          this.databaseService.updateUserField(this.list.owner.userId, {myLists: user.myLists});
          ownerUserSub.unsubscribe();
        });
      } else {
      //If the current user is the owner, update their user doc with the new quantity
        for (let i = 0; i < this.user.myLists.length; i++) {
          if(this.list.listId == this.user.myLists[i].listId) {
            this.user.myLists[i].numQuantityItemsTotal = this.list.numQuantityItemsTotal;
            break;
          };
        }
        this.databaseService.updateUserField(this.list.owner.userId, {myLists: this.user.myLists});
      }
    }

    //After all saves are complete, determine next action based on index passed in from the button that called the save function
    switch(nextStepIndex) {
      case 0:
        //After saving, return to the list
        this.router.navigate([this.genRef.ROUTES_LIST + '/' + this.list.listId]);
        break;
      case 1:
        //After saving, if the item was not new, exit edit mode and stay on item page
        if(!this.isNewItem) {
          this.editMode = false;
          window.scrollTo(0,0);
        }
        //If the item was new, stay in edit mode (since user may want to continue to make changes like uploading an image), update 
        //this.itemId to the new item ID, indicate the item is no longer new, and reset the route to show the new item id 
        //rather than "new".
        if(this.isNewItem) {
          this.editMode = true;
          this.itemId = this.editedItem.itemId;
          this.isNewItem = false;
          this.productUrls = null;   
          this.listId = this.list.listId; 
          // this.getUpdatedList();      
          this.router.navigate([this.genRef.ROUTES_LIST + '/' + this.list.listId + '/' + this.genRef.ROUTES_ITEM + '/' + this.itemId]);
          window.scrollTo(0,0);        
        }
        break;
      case 2:
        //After saving, keep the itemId as "new" and the route as "new" which will cause the item variables to reset to defaults 
        //after the list is updated and a new observable is pushed to the list subscription in ngOnInit
        this.productUrls = null;
        this.listId = this.list.listId;
        this.itemId = "new";    
        // this.getUpdatedList();
        this.router.navigate([this.genRef.ROUTES_LIST + '/' + this.list.listId + '/' + this.genRef.ROUTES_ITEM + '/' + "new"]);
        window.scrollTo(0,0);        
        break;
      default:
        //Default is to do nothing
        break;

    }
  }

  /**
   * Deletes the item from the list entirely, and updates the quantities and costs for the list as well as the owner user doc
   */
  deleteItem() {
    //The total quantity of items on the list is equal to the original total of items for the list minus the quantity requested for the item being deleted
    let numQuantityItemsTotalNow: number = this.list.numQuantityItemsTotal - this.list.items[this.itemIndex].quantityRequested;
    //The quantity of items purchased on the list is equal to the original quantity of items purchased minus the quantity purchased for the item being deleted
    let numQuantityItemsPurchasedNow: number = this.list.numQuantityItemsPurchased - this.list.items[this.itemIndex].quantityPurchased;
    //The total cost of items on the list is equal to the orignal total cost on the list minus the item being deleted's price times quantity requested
    let costTotalNow: number = this.list.costTotal - (this.list.items[this.itemIndex].price * this.list.items[this.itemIndex].quantityRequested);
    //The total dollar amount of items purchased on the list is equal to the original amount purchased minus the item being deleted's price times quantity purchased
    let costPurchasedNow: number = this.list.costPurchased - (this.list.items[this.itemIndex].price * this.list.items[this.itemIndex].quantityPurchased);
    //The number of unique items on the list is one less after this item is deleted    
    this.list.numUniqueItemsTotal -= 1;    

    //If there is an image uploaded to storage for this item, delete it from storage
    if(this.list.items[this.itemIndex].imageUrlStoragePath){
      this.storageService.deleteFile(this.list.items[this.itemIndex].imageUrlStoragePath);
    }

    //Delete the item from the list.items array
    this.list.items.splice(this.itemIndex,1);

    //Close the delete item confirmation modal
    this.modalService.dismissAll("Delete confirmed");

    //Update the list doc with the new items array, quantities, and costs; show confirmations
    this.databaseService.updateListField(
      this.list.listId, 
      {
        items: this.list.items,
        numUniqueItemsTotal: this.list.numUniqueItemsTotal,
        numQuantityItemsTotal: numQuantityItemsTotalNow,
        numQuantityItemsPurchased: numQuantityItemsPurchasedNow,
        costTotal: costTotalNow,
        costPurchased: costPurchasedNow
      },
      {
        message: this.dbRef.ITEM_ALERT_ITEM_DELETED,
        duration: this.genRef.ALERTS_DURATION_STANDARD,
        class: this.genRef.ALERTS_CLASS_SUCCESS
      },
      {
        message: this.dbRef.ITEM_ALERT_ITEM_NOT_DELETED,
        duration: this.genRef.ALERTS_DURATION_STANDARD,
        class: this.genRef.ALERTS_CLASS_DANGER
      }
    );

    //Update owner myLists with numQuantityItemsTotal and numQuantityitemsPurchased
    if(this.user.userId !== this.list.owner.userId) {
    //If the current user is not the list owner, pull the owner doc from the database and update the myLists array with the new quantities
      let ownerUserSub = this.databaseService.getUserByUID(this.list.owner.userId).subscribe(user => {
        for (let i = 0; i < user.myLists.length; i++) {
          if(this.list.listId == user.myLists[i].listId) {
            user.myLists[i].numQuantityItemsTotal = numQuantityItemsTotalNow;
            user.myLists[i].numQuantityItemsPurchased = numQuantityItemsPurchasedNow;
            break;
          };
        }
        this.databaseService.updateUserField(this.list.owner.userId, {myLists: user.myLists});
        ownerUserSub.unsubscribe();
      });
    } else {
    //If the current user is the owner, update their myLists array with the new quantities
      for (let i = 0; i < this.user.myLists.length; i++) {
        if(this.list.listId == this.user.myLists[i].listId) {
          this.user.myLists[i].numQuantityItemsTotal = numQuantityItemsTotalNow;
          this.user.myLists[i].numQuantityItemsPurchased = numQuantityItemsPurchasedNow;
          break;
        };
      }
      this.databaseService.updateUserField(this.list.owner.userId, {myLists: this.user.myLists});
    }

    //Navigate back to the list
    this.router.navigate([this.genRef.ROUTES_LIST + '/' + this.list.listId]);
  }

  fillProductByAPI() {
    if(this.editedItem.url.includes(" ")) {
      this.showInvalidUrlExp = true;
    } else {
      this.showInvalidUrlExp = false;
      this.requestingProductApi = true;
      this.productApiService.getProductDetails(this.editedItem.url).then(product => {
        if(product) {
          if(product.title) {
            this.editedItem.title = product.title;
          }
          if(product.description) {
            this.editedItem.description = product.description;
          }
          if(product.price) {
            this.editedItem.price = product.price;
          }
          if(product.imageUrls) {
            this.editedItem.thumbnailUrl = product.imageUrls[0];
            this.productUrls = product.imageUrls;
          }
        } else {
          this.alertsService.showNewAlert({
            message: "Sorry, but we were unable to auto-fill the item based on this URL. This could be because the URL is invalid or the merchant is blocking our attempt.",
            duration: 10000,
            class: this.genRef.ALERTS_CLASS_WARNING
          });
        }
        this.requestingProductApi = false;
      }).catch(err => {
        this.alertsService.showNewAlert({
          message: "Sorry, but we were unable to auto-fill the item based on this URL. This could be because the URL is invalid or the merchant is blocking our attempt.",
          duration: 10000,
          class: this.genRef.ALERTS_CLASS_WARNING
        });
        this.requestingProductApi = false;
      });
    }
  }

  setImageToDefault() {
    this.editedItem.thumbnailUrl = this.genRef.ITEM_DEFAULT_THUMBNAIL_URL;
    this.alertsService.showNewAlert({
      message: "The image has been updated. Click cancel edit to undo.",
      duration: this.genRef.ALERTS_DURATION_STANDARD,
      class: this.genRef.ALERTS_CLASS_SUCCESS
    })
  }

  decrementProductUrlIndex() {
    if(this.currentProductUrlIndex !== 0) {
      this.currentProductUrlIndex -= 1;
      this.editedItem.thumbnailUrl = this.productUrls[this.currentProductUrlIndex];
    }
  }

  incrementProductUrlIndex() {
    if(this.currentProductUrlIndex !== this.productUrls.length - 1) {
      this.currentProductUrlIndex += 1;
      this.editedItem.thumbnailUrl = this.productUrls[this.currentProductUrlIndex];
    }
  }

  /**
   * MODAL-RELATED FUNCTIONS:
   */
  
   /**
   * Function to open the modal in the UI
   * @param content is the specific modal to be opened
   */
  open(content) {
    this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  /**
   * Creates a string describing how/why the modal was closed
   * @param reason is the reason passed in from the modal, depending on how it was closed
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
