import { Component, OnInit } from '@angular/core';
import { GENERAL_REF } from '../../dbReferences';
import { DatabaseService } from '../..//services/database.service';
import { AlertsService } from '../../services/alerts.service';
import { User } from '../../models/User';
import { Notification } from '../../models/Notification';
import { UserMeta } from '../../models/UserMeta';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

/**
 * Displays the user's friends and invited friends, and allows the user to manage them
 */

@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.css']
})
export class FriendsComponent implements OnInit {
  genRef;  //contains general references needed for the database/app
  dbRef; //contains text, phrases and other referencces needed for the database/app - pulled in the user's preferred language
  user: User;  //the User object for the current user
  userMeta: UserMeta; //A document containing the userId and email address of all active users in the app
  editedFriends: { //An object to hold the user's friend info along with some additional variables for the UI
    userId?: string, 
    name?: string,
    nickName?: string,
    email?: string,
    numLists?: number //the number of lists that the user is subscribed to for a particular friend
    showEditNickName?: boolean  //Indicates if the friend's nickname is being edited and to show the input UI
    newNickName?: string;  //A variable to hold the new nickname given to the friend
  }[];
  closeResult: string;  // Used by modal to indicate why/how it was closed
  friendSelectedToRemove: number;  //the index number of the friends array pointing to the friend to remove
  newInvitedFriendEmail: string;  //the email address of a new friend to invite


  constructor(
    private databaseService: DatabaseService,
    private alertsService: AlertsService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    //Get a reference to the general and language specific database references
    this.genRef = GENERAL_REF;
    this.databaseService.dbRef.subscribe(ref => {
      this.dbRef = ref;
    });

    //Pull the userMeta doc to get a list of all active users within the app
    this.databaseService.userMeta.subscribe(data => {
      this.userMeta = data;
    })

    //get the user Object from the User Behavior Subject
    this.databaseService.activeUser.subscribe(user => {
      if(user !== null) {
        this.user = user;
        if(this.user.friends) {
          //Make a copy of each friend and add it to the editedFriends array along with the variables needed for the UI
          this.editedFriends = [];
          for (let i = 0; i < this.user.friends.length; i++) {
            this.editedFriends.push(Object.assign({}, this.user.friends[i]));
            this.editedFriends[i].newNickName = this.editedFriends[i].nickName;
            this.editedFriends[i].showEditNickName = false;
          }
        } else {
          //If the user does not have any friends, make an empty array for the editedFriends
          this.editedFriends = [];
        }
      }
    });
  }

  /**
   * Toggles the variable indicating whether to show the edit nickname input for a particular friend
   * @param index is the index number of the friend within the editedFriends array
   */
  showEditFriendNickName(index: number) {
    this.editedFriends[index].showEditNickName = true;
  }

  /**
   * Saves the new nickname of the friend as inputted by the user
   * @param index is the index number of the friend within the editedFriends array
   */
  saveNewNickName(index: number){
    //If the nick name was changed, update it in the user's friends array
    if(this.user.friends[index].nickName !== this.editedFriends[index].newNickName){
      this.user.friends[index].nickName = this.editedFriends[index].newNickName;
      this.databaseService.updateUserField(this.user.userId, {friends: this.user.friends});
    }
    //Toggle the showEditNickName variable so the edit input is no longer shown
    this.editedFriends[index].showEditNickName = false;
  }

  /**
   * Deletes a friend from the user's friends array
   * Does not delete this user from the friend's friends array
   */
  removeFriend() {
    //Log the name and user Id of the friend that was selected to be removed by the user
    //the friendSelectedToRemove variable is set by the modal function
    let removedFriendUserId = this.user.friends[this.friendSelectedToRemove].userId;
    let removedFriendName = this.user.friends[this.friendSelectedToRemove].name;
    //Remove the selected friend from the user's friends array
    this.user.friends.splice(this.friendSelectedToRemove,1);
    //Update the user's database doc with the updated friends array, show confirmation
    this.databaseService.updateUserField(
      this.user.userId, 
      {friends: this.user.friends},
      {
        message: removedFriendName + this.dbRef.FRIENDS_ALERT_FRIEND_REMOVED,
        duration: this.genRef.ALERTS_DURATION_STANDARD,
        class: this.genRef.ALERTS_CLASS_SUCCESS
      },
      {
        message: removedFriendName + this.dbRef.FRIENDS_ALERT_FRIEND_NOT_REMOVED,
        duration: this.genRef.ALERTS_DURATION_STANDARD,
        class: this.genRef.ALERTS_CLASS_DANGER
      }
    );
    //Remove the user's userId from the friend's listedAsFriend array since they are no longer listed as their friend
    let removedFriendSub = this.databaseService.getUserByUID(removedFriendUserId).subscribe(user => {
      for (let i = 0; i < user.listedAsFriend.length; i++) {
        if(user.listedAsFriend[i] == this.user.userId) {
          user.listedAsFriend.splice(i,1);
          break;
        };
      }
      this.databaseService.updateUserField(user.userId, {listedAsFriend: user.listedAsFriend});
      removedFriendSub.unsubscribe();
    });
    //Close the modal
    this.modalService.dismissAll('Remove confirmed');
    //Reset the friendSelectedToRemove variable
    this.friendSelectedToRemove = null;
  }

  /**
   * Adds a new invited admin by email address to the existing array or creates one if it doesn't exist.
   * Database is immediately updated with new invited admin, so user does not need to save changes to list to take effect
   * @param newAdminEmail is the email address entered by the user 
   * @param valid indicates whether or not the submitted value is valid based on the form rules
   */
  inviteFriend({value, valid}: {value: {newFriendEmail: string}, valid: boolean}):void {
    //change the email address to be in all lowercase for consistency
    let newFriendEmailAddress: string = value.newFriendEmail.toLowerCase()

    //Check if the invited email address is already an existing user
    let invitedUserId: string;
    for (let i = 0; i < this.userMeta.users.length; i++) {
      if(this.userMeta.users[i].email == newFriendEmailAddress) {
        invitedUserId = this.userMeta.users[i].userId;
        break;
      };
    }

    //If the invited email is already a user...
    if(invitedUserId) {      
      //Check if invited user is already on friends list
      let alreadyFriend: boolean = false;
      if(this.user.friends) {
        for (let i = 0; i < this.user.friends.length; i++) {
          if(invitedUserId == this.user.friends[i].userId) {
            alreadyFriend = true;
            //If invited user is already a friend, do not add them, show alert
            this.alertsService.showNewAlert({
              message: this.user.friends[i].name + ' (' + this.user.friends[i].email + ')' + this.dbRef.FRIENDS_ALERT_ALREADY_FRIEND,
              duration: this.genRef.ALERTS_DURATION_STANDARD,
              class: this.genRef.ALERTS_CLASS_WARNING
            });
            break;
          }
        }
      }


      //If the invited user is not already on friends list, add them to friends list and send notification
      if(!alreadyFriend) {
        //Get the invited user's information from the database
        let invitedUserSub = this.databaseService.getUserByUID(invitedUserId).subscribe(user => {
          //Create new friend object with the database info
          let newFriend = {
            userId: user.userId,
            name: user.name,
            email: user.email,
            numLists: 0 
          }

          //Add them to friends array or create it if it doesn't exist
          if(this.user.friends){
            this.user.friends.unshift(newFriend);
          } else {
            this.user.friends = [newFriend];
          }

          //Add the new friends array to the user and show confirmation
          this.databaseService.updateUserField(
            this.user.userId, 
            {friends: this.user.friends},
            {
              message: newFriend.name + ' (' + newFriend.email + ')' + this.dbRef.FRIENDS_ALERT_FRIEND_ADDED,
              duration: this.genRef.ALERTS_DURATION_STANDARD,
              class: this.genRef.ALERTS_CLASS_SUCCESS
            },
            {
              message: newFriend.name + ' (' + newFriend.email + ')' + this.dbRef.FRIENDS_ALERT_FRIEND_NOT_ADDED,
              duration: this.genRef.ALERTS_DURATION_STANDARD,
              class: this.genRef.ALERTS_CLASS_DANGER
            }
          );

          //Create the notification object 
          let notificationId = this.databaseService.generateNotificationId();
          let newNotification: Notification = {
            notificationId: notificationId,
            date: new Date(),
            message: this.user.name 
              + " (" 
              + this.user.email 
              + ")" 
              + this.databaseService.getDbRefByLang(user.languageIndex).NOTIFICATIONS_INVITED_FRIEND,
            routerLink: this.genRef.ROUTES_INVITE
              + '/' + notificationId
              + '/' + this.genRef.ROUTES_FRIEND 
              + '/' + this.user.userId 
              + '/' + newFriend.userId,
            email: newFriendEmailAddress,
            languageIndex: user.languageIndex,
            notificationIndex: user.notificationIndex
          }
    
          //Add a notification to the invited user's notifications array or create it if it doesn't exist
          if(user.notifications) {
            user.notifications.unshift(newNotification);
            //If after adding this notification, there are more than 3 notifications, remove the oldest one from the array
            if(user.notifications.length > 3) {
              user.notifications.pop();
            }
          } else {
            user.notifications = [newNotification];
          }

          //Add this user's id to the invited user's listedAsFriend array, if it exists, or create it if it doesn't
          if(user.listedAsFriend) {
            user.listedAsFriend.push(this.user.userId);
          } else {
            user.listedAsFriend = [this.user.userId];
          }

          user.numTotalNotifications += 1;

          //Add the new notifications and listedAsFriend arrays to the invited user
          this.databaseService.updateUserField(invitedUserId, {
            notifications: user.notifications,
            numTotalNotifications: user.numTotalNotifications,
            listedAsFriend: user.listedAsFriend
          });

          //Add the new notification to the invited user's notifications collection
          this.databaseService.addNotification(invitedUserId, newNotification);

          //Unsubscribe from the invited user's database object
          invitedUserSub.unsubscribe();
        });
      }
    } else {
    //If invited email is not already a user, add them to invitedUsers list

      //Create the notification object 
      let notificationId = this.databaseService.generateNotificationId();
      let newNotification: Notification = {
        notificationId: notificationId,
        date: new Date(),
        message: this.user.name 
          + " (" 
          + this.user.email 
          + ")" 
          + this.databaseService.getDbRefByLang(this.genRef.DB_DEFAULT_LANGUAGE_PREF).NOTIFICATIONS_INVITED_FRIEND,
        routerLink: this.genRef.ROUTES_INVITE
        + '/' + notificationId
        + '/' + this.genRef.ROUTES_FRIEND 
        + '/' + this.user.userId 
        + '/' + newFriendEmailAddress,
        email: newFriendEmailAddress,
        languageIndex: this.genRef.DB_DEFAULT_LANGUAGE_PREF,
        notificationIndex: this.genRef.PROFILE_DEFAULT_NOTIFICATION_PREF_INDEX
      }

      //Check if the invited email is already on invitedFriends list
      let alreadyInvitedFriend: boolean = false;
      if(this.user.invitedFriends && valid) {
        for (let i = 0; i < this.user.invitedFriends.length; i++) {
          if(this.user.invitedFriends[i] == newFriendEmailAddress) {
            alreadyInvitedFriend = true;
            // If invited email is already on invitedFriends list, then do not add them, show alert
            this.alertsService.showNewAlert({
              message: newFriendEmailAddress + this.dbRef.FRIENDS_ALERT_ALREADY_INVITED,
              duration: this.genRef.ALERTS_DURATION_STANDARD,
              class: this.genRef.ALERTS_CLASS_WARNING
            });
            break;
          };
        }

        //If invited email is not already on invitedFriends list, then add them and invite the new email
        if(!alreadyInvitedFriend) {
          this.databaseService.addInvitedUser(newFriendEmailAddress, newNotification, this.user.userId);
          this.user.invitedFriends.unshift(newFriendEmailAddress);
        }
      } else if (valid) {
      //If this user does not have any invitedFriends yet, create the array and invite the new email
        this.databaseService.addInvitedUser(newFriendEmailAddress, newNotification, this.user.userId);
        this.user.invitedFriends = [newFriendEmailAddress];
      }

      //If the invited email was not already invited, add the new invitedFriends array to the user and show confirmation
      if(!alreadyInvitedFriend) {
        this.databaseService.updateUserField(
          this.user.userId, 
          {invitedFriends: this.user.invitedFriends},
          {
            message: newFriendEmailAddress + this.dbRef.FRIENDS_ALERT_FRIEND_ADDED,
            duration: this.genRef.ALERTS_DURATION_STANDARD,
            class: this.genRef.ALERTS_CLASS_SUCCESS
          },
          {
            message: newFriendEmailAddress + this.dbRef.FRIENDS_ALERT_FRIEND_NOT_ADDED,
            duration: this.genRef.ALERTS_DURATION_STANDARD,
            class: this.genRef.ALERTS_CLASS_DANGER
          }
        );
      }
    }
  }

  /**
   * Deletes an invited friend from the user's invitedFriends array
   */
  removeInvitedFriend() {
    //the friendSelectedToRemove variable is set by the modal function    
    let removedFriendEmail: string = this.user.invitedFriends[this.friendSelectedToRemove];
    
    //Remove the invited friend from the invitedFriends array based on the index number given
    this.user.invitedFriends.splice(this.friendSelectedToRemove,1);
    //Update the user with the updated invitedFriends array
    this.databaseService.updateUserField(
      this.user.userId, 
      {invitedFriends: this.user.invitedFriends},
      {
        message: removedFriendEmail + this.dbRef.FRIENDS_ALERT_FRIEND_REMOVED,
        duration: this.genRef.ALERTS_DURATION_STANDARD,
        class: this.genRef.ALERTS_CLASS_SUCCESS
      },
      {
        message: removedFriendEmail + this.dbRef.FRIENDS_ALERT_FRIEND_NOT_REMOVED,
        duration: this.genRef.ALERTS_DURATION_STANDARD,
        class: this.genRef.ALERTS_CLASS_DANGER
      }
    );
    //Close the modal
    this.modalService.dismissAll('Remove confirmed');
    //Reset the friendSelectedToRemove variable to null
    this.friendSelectedToRemove = null;
  }
  
  /**
   * MODAL-RELATED FUNCTIONS:
   */
  
  /**
   * Function to open the modal in the UI
   * @param content is the specific modal to be opened
   * @param index is the index number of the friend that was selected from the array
   */
  open(content, index) {
    this.friendSelectedToRemove = index;
    this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.friendSelectedToRemove = null;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.friendSelectedToRemove = null;
    });
  }

  /**
   * Creates a string describing how/why the modal was closed
   * @param reason is the reason passed in from the modal, depending on how it was closed
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
