import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import {Observable, BehaviorSubject } from 'rxjs';
import { AlertsService } from '../services/alerts.service';
import { map } from 'rxjs/operators';
import { User } from '../models/User';
import { List } from '../models/List';
import { Notification } from '../models/Notification';
import { Alert } from '../models/Alert';
import { ListView } from '../models/ListView';
import { UserMeta } from '../models/UserMeta';
import { GENERAL_REF } from '../dbReferences';
import { EN_DBREFERENCES } from '../dbReferences';
import { ES_DBREFERENCES } from '../dbReferences';




@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  private userSource: BehaviorSubject<User> = new BehaviorSubject<User>(null); // the BehaviorSubject to create user observables
  activeUser: Observable<User> = this.userSource.asObservable(); // the current User object, given as an observable
  private dbRefSource: BehaviorSubject<{}> = new BehaviorSubject<{}>(EN_DBREFERENCES); // the BehaviorSubject to create the database reference observables
  dbRef: Observable<{}> = this.dbRefSource.asObservable(); // the current dbRef object, given as an observable
  usersCol: AngularFirestoreCollection<User>;  // represents a collection of User objects
  userDoc: AngularFirestoreDocument<User>;  // represents a document of a User object
  users$: Observable<User[]>; // represents an array of User objects as an observable
  user$: Observable<User>; // represents a User object as an observable
  private listSource: BehaviorSubject<List> = new BehaviorSubject<List>(null); // the BehaviorSubject to create List observables
  activeList: Observable<List> = this.listSource.asObservable(); // the current List object, given as an observable
  listsCol: AngularFirestoreCollection<List>;  // represents a collection of List objects
  listDoc: AngularFirestoreDocument<List>;  // represents a document of a List object
  lists$: Observable<List[]>; // represents an array of List objects as an observable
  list$: Observable<List>; // represents a List object as an observable
  private userMetaSource: BehaviorSubject<UserMeta> = new BehaviorSubject<UserMeta>(null); // the BehaviorSubject to create userMeta observables
  userMeta: Observable<UserMeta> = this.userMetaSource.asObservable(); // the current User object, given as an observable
  private listViewSource: BehaviorSubject<ListView> = new BehaviorSubject<ListView>(null); // the BehaviorSubject to create List observables
  activeListView: Observable<ListView> = this.listViewSource.asObservable(); // the current List object, given as an observable

  constructor(
    private afs: AngularFirestore,
    private alertsService: AlertsService
    ) { }

  // Pushes a new ListView object to the activeListView observable
  // This function allows list view options to be saved when navigating from list to items component and back
  updateListView(listView: ListView): void {
    console.log("updateListView being called here...")
    this.listViewSource.next(listView);
  }

  // Pushes a new database reference object to the dbRef observable
  // This function allows changes which dbRef object is used, based on the user's language preference
  updateDbRef(languageIndex: number): void {
    let currentDbRef;
    switch(languageIndex) {
      case GENERAL_REF.DB_LANGUAGE_PREF_ENGLISH:
        currentDbRef = EN_DBREFERENCES;
        break;
      case GENERAL_REF.DB_LANGUAGE_PREF_SPANISH:
        currentDbRef = ES_DBREFERENCES;
        break;
      default:
        currentDbRef = EN_DBREFERENCES;
    }
    this.dbRefSource.next(currentDbRef);
  }

  getDbRefByLang(languageIndex: number) {
    switch(languageIndex) {
      case GENERAL_REF.DB_LANGUAGE_PREF_ENGLISH:
        return EN_DBREFERENCES;
        break;
      case GENERAL_REF.DB_LANGUAGE_PREF_SPANISH:
        return ES_DBREFERENCES;
        break;
      default:
        return EN_DBREFERENCES;
    }
  }

  getUserMeta() {
    console.log("FB QUERY: getUserMeta");
    this.afs.collection(GENERAL_REF.DB_COLLECTION_USERS).doc('userMeta').get().subscribe(userMetaDoc => {
      let userMetaData = userMetaDoc.data() as UserMeta;
      this.userMetaSource.next(userMetaData);
    });
  }

  addUserMeta(newUserMeta) {
    console.log("FB QUERY: addUserMeta");
    let addUserMetaSub = this.userMeta.subscribe(userMetaData => {
      if(userMetaData) {
        userMetaData.users.push(newUserMeta);
        this.afs.collection(GENERAL_REF.DB_COLLECTION_USERS).doc('userMeta').update({users: userMetaData.users}).then(res => {
          console.log("The new user has been added to the userMeta doc");
        }).catch(err => {
          console.log(err);
        }); 
        addUserMetaSub.unsubscribe();
      } else {
        this.getUserMeta();
      }
    });
  }

  updateUserMeta(userId: string, newEmail: string) {
    console.log("FB QUERY: updateUserMeta");
    let updateUserMetaSub = this.userMeta.subscribe(userMetaData => {
      if(userMetaData) {
        for (let i = 0; i < userMetaData.users.length; i++) {
          if(userMetaData.users[i].userId == userId) {
            userMetaData.users[i].email = newEmail;
            break;
          };
        }
        this.afs.collection(GENERAL_REF.DB_COLLECTION_USERS).doc('userMeta').update({users: userMetaData.users}).then(res => {
          console.log("The user's email has been updated in the userMeta doc");
        }).catch(err => {
          console.log(err);
        });
      }
    });
    updateUserMetaSub.unsubscribe();
  }

  // Pushes a new User object to the activeUser observable
  // This function allows user data to be utilized throughout app without querying Firestore
  //This function is automatically called from getUserByUID any time the database user object is updated
  updateActiveUser(newUser: User): void {
    console.log("updateActiveUser being called here...")
    this.userSource.next(newUser);
    if(newUser !== null && newUser !== undefined) {
      this.updateDbRef(newUser.languageIndex);
    }
  }

  // Queries Firestore for User object based on given userID and returns it as an observable User object
  getUserByUID(userId: string) {
    console.log("FB QUERY: getUserByUID");
    // Get a reference to the User Document
    this.userDoc = this.afs.collection(GENERAL_REF.DB_COLLECTION_USERS).doc(userId);
    // Create the observable User object and return it
    this.user$ = this.userDoc.snapshotChanges().pipe(map(action => {
      if(action.payload.exists === false) {
          return null;
      } else {
        let data: User = action.payload.data() as User;

        //Format the dates of each notifications to a javascript Date object from a firebase.Timestamp object
        if(data.notifications) {
          for (let i = 0; i < data.notifications.length; i++) { 
            let formattedDate = data.notifications[i].date.toDate();
            data.notifications[i].date = formattedDate;
          }
        }

        if(data.friends) {
          //Sort alphabetically
          data.friends.sort(function(a, b){
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
          });
        }

        if(data.invitedFriends) {
          //Sort alphabetically
          data.invitedFriends.sort();
        }

        //return the new user object
        return data;
      }
    }));
    //return the user as an observable
    return this.user$;
  }

  // Creates a new User Document based on the given User object
  addUser(user: User) {
    console.log("FB QUERY: addUser")
    this.afs.collection(GENERAL_REF.DB_COLLECTION_USERS).doc(user.userId).set(user)
    .then(function() {
      //The user was successfully added
    }).catch(function(error) {
      console.error("Error writing document: ", error);
    });
  }

  // Update the List Document in Firestore with only the specified fields given in the object. 
  // Any fields that already exist will be updated and any that do not exist will be created.
  updateUserField(userId: string, userFieldObject: any, alertSuccess?: Alert, alertFailure?: Alert) {
    console.log("FB QUERY: updateUserField");
    this.afs.collection(GENERAL_REF.DB_COLLECTION_USERS).doc(userId).update(userFieldObject).then(res => {
        //Show confirmation
        if(alertSuccess) {
          this.alertsService.showNewAlert({
            message: alertSuccess.message, 
            duration: alertSuccess.duration,
            class: alertSuccess.class
          });
        }

    }).catch(err => {
      if(alertFailure) {
        this.alertsService.showNewAlert({
          message: alertFailure.message, 
          duration: alertFailure.duration,
          class: alertFailure.class
        });
      }
    });
  }

  updateUserWithFeedback(userId: string, userFieldObject: any): Promise<boolean> {
    console.log("FB QUERY: updateUserField");
    return this.afs.collection(GENERAL_REF.DB_COLLECTION_USERS).doc(userId).update(userFieldObject).then(res => {
      return true;
    }).catch(err => {
      return false;
    });
  }

  // Deletes a User Document based on the given User id
  deleteUser(userId: string, deletedUserDoc) {
    console.log("FB QUERY: deleteUser")
    return this.afs.collection(GENERAL_REF.DB_COLLECTION_DELETED_USERS).add(deletedUserDoc);
  }

  // Pushes a new List object to the activeList observable
  // This function allows user data to be utilized throughout app without querying Firestore
  updateActiveList(newList: List): void {
    console.log("updateActiveList being called here...")
    this.listSource.next(newList);
  }

  // Queries Firestore for List object based on given listID and returns it as an observable List object
  getListByListId(listId: string) {
    console.log("FB QUERY: getListByListId");
    // Get a reference to the List Document
    this.listDoc = this.afs.collection(GENERAL_REF.DB_COLLECTION_LISTS).doc(listId);
    // Create the observable List object and return it
    this.list$ = this.listDoc.snapshotChanges().pipe(map(action => {
      if(action.payload.exists === false) {
          return null;
      } else {
        let data: List = action.payload.data() as List;
        data.listId = listId;


        //Format Expiration Date from Firebase Timestamp to JS Date object
        if (data.eventDate){
          data.eventDate = data.eventDate.toDate();
        }

        //Format Expiration Date from Firebase Timestamp to JS Date object
        // if (data.expirationDate) {
        //   data.expirationDate = data.expirationDate.toDate();
        // }

        //return the new list object
        return data;
      }
    }));
    return this.list$
  }

  getListByShareCode(shareCode: string) {
    let listArray: List[] = [];
    return this.afs.collection(GENERAL_REF.DB_COLLECTION_LISTS, ref => 
      ref.where(GENERAL_REF.ROUTES_PARAM_SHARE_CODE,"==",shareCode)).get().toPromise().then(results => {
        if(results) {
          results.docs.forEach(docSnapshot => {
            listArray.push(docSnapshot.data() as List);
          });
          return listArray;
        } else {
          return listArray;
        }
      }).catch(err => {
        console.log(err);
        return listArray;
      });
  }

  // Creates a new List Document based on the given List object
  addList(list: List) {
    console.log("FB QUERY: addList")
    return this.afs.collection(GENERAL_REF.DB_COLLECTION_LISTS).add(list).then(function(docRef){
      return docRef.id;
    }).catch(function(error){
      return error;
    });
  }

  // Update the entire List Document in Firestore with the new List object data (this will overwrite any existing data) 
  updateList(list: List) {
    console.log("FB QUERY: updateList")
    return this.afs.collection(GENERAL_REF.DB_COLLECTION_LISTS).doc(list.listId).set(list, {merge: true});
  }

  // Update the List Document in Firestore with only the specified fields given in the object. 
  // Any fields that already exist will be updated and any that do not exist will be created.
  updateListField(listID: string, listFieldObject: any, alertSuccess?: Alert, alertFailure?: Alert) {
    console.log("FB QUERY: updateListField");
    this.afs.collection(GENERAL_REF.DB_COLLECTION_LISTS).doc(listID).update(listFieldObject).then(res => {
      //Show confirmation
      if(alertSuccess) {
        this.alertsService.showNewAlert({
          message: alertSuccess.message, 
          duration: alertSuccess.duration,
          class: alertSuccess.class
        });
      }
    }).catch(err => {
      if(alertFailure) {
        this.alertsService.showNewAlert({
          message: alertFailure.message, 
          duration: alertFailure.duration,
          class: alertFailure.class
        });
      }
    });
  }

  // Deletes a List Document based on the given List id
  deleteList(listId: string, successMessage?: string, errorMessage?: string) {
    console.log("FB QUERY: deleteList")
    this.afs.collection(GENERAL_REF.DB_COLLECTION_LISTS).doc(listId).delete().then(res => {
      //Show confirmation
      if(successMessage) {
        this.alertsService.showNewAlert({
          message: successMessage, 
          duration: GENERAL_REF.ALERTS_DURATION_STANDARD,
          class: GENERAL_REF.ALERTS_CLASS_SUCCESS
        });
      }
    }).catch(err => {
      if(errorMessage) {
        this.alertsService.showNewAlert({
          message: errorMessage, 
          duration: GENERAL_REF.ALERTS_DURATION_STANDARD,
          class: GENERAL_REF.ALERTS_CLASS_DANGER
        });
      }
    });
  }

  addInvitedUser(email: string, notification: Notification, invitingFriendUserId: string) {
    console.log("FB QUERY: addInvitedUser");
    let invitedUserSub = this.afs.collection(GENERAL_REF.DB_COLLECTION_INVITED_USERS).doc(email).get().subscribe(doc => {
      if(doc.exists) {
        let notificationsArray: Notification[] = doc.data().notifications;
        notificationsArray.unshift(notification);
        let invitedAsFriendOfArray: string[] = doc.data().invitedAsFriendOf;
        let alreadyInvited = false;
        for (let i = 0; i < invitedAsFriendOfArray.length; i++) {
          if(invitedAsFriendOfArray[i] == invitingFriendUserId) {
            alreadyInvited = true;
            break;
          }
        }
        if(!alreadyInvited) {
          invitedAsFriendOfArray.unshift(invitingFriendUserId);
        }
        this.afs.collection(GENERAL_REF.DB_COLLECTION_INVITED_USERS).doc(email).set({
          notifications: notificationsArray,
          invitedAsFriendOf: invitedAsFriendOfArray
        });
      } else {
        this.afs.collection(GENERAL_REF.DB_COLLECTION_INVITED_USERS).doc(email).set({
          notifications: [notification],
          invitedAsFriendOf: [invitingFriendUserId]
        });
      }
      invitedUserSub.unsubscribe();
    });
  }

  getInvitedUser(email: string) {
    console.log("FB QUERY: getInvitedUser");
    return this.afs.collection(GENERAL_REF.DB_COLLECTION_INVITED_USERS).doc(email).get();
  }

  deleteInvitedUser(email: string) {
    console.log("FB QUERY: deleteInvitedUser");
    this.afs.collection(GENERAL_REF.DB_COLLECTION_INVITED_USERS).doc(email).delete().then(res => {
      console.log(email + " was successfully deleted from invitedUsers collection.");
    }).catch(err => {
      console.log(err);
    })
  }

  getUserNotifications(userId: string, startAfterNotificationDoc?): Observable<Notification[]> {
    console.log("FB QUERY: getUserNotifications");

    let notificationsCollection: AngularFirestoreCollection<Notification>;

    if(startAfterNotificationDoc) {
      //Get a reference to the notifications colleciton
      notificationsCollection = this.afs.collection(GENERAL_REF.DB_COLLECTION_USERS).doc(userId)
        .collection(GENERAL_REF.DB_COLLECTION_NOTIFICATIONS, ref => 
          ref.orderBy('date','desc')
          .startAfter(startAfterNotificationDoc)
          .limit(10)
        );
    } else {
      //Get a reference to the notifications colleciton
      notificationsCollection = this.afs.collection(GENERAL_REF.DB_COLLECTION_USERS).doc(userId)
        .collection(GENERAL_REF.DB_COLLECTION_NOTIFICATIONS, ref => 
          ref.orderBy('date','desc')
          .limit(10));
    }

    return notificationsCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as Notification;
        data.notificationId = action.payload.doc.id;
        data.date = data.date.toDate();
        return data;
      })
    }));

  }

  getNotificationDocSnapshot(userId: string, notificationId: string) {
    return this.afs.collection(GENERAL_REF.DB_COLLECTION_USERS).doc(userId)
    .collection(GENERAL_REF.DB_COLLECTION_NOTIFICATIONS).doc(notificationId).get();
  }

  addNotification(userId: string, notification: Notification) {
    console.log("FB QUERY: addNotification");
    this.afs.collection(GENERAL_REF.DB_COLLECTION_USERS).doc(userId).collection(GENERAL_REF.DB_COLLECTION_NOTIFICATIONS).doc(notification.notificationId).set(notification);
  }

  /**
   * Creates a random 10 digit item ID consisting of letters and numbers
   */
  generateNotificationId(): string {
    return Math.random().toString(36).substr(2, 10);
  }

}
