import { Injectable } from '@angular/core';
import {Observable, BehaviorSubject } from 'rxjs';
import { Alert } from '../models/Alert';

/**
 * Provides alert messages throughout the app
 */

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  private alertSource: BehaviorSubject<Alert> = new BehaviorSubject<Alert>(null); // the BehaviorSubject to create alerts
  currentAlert: Observable<Alert> = this.alertSource.asObservable(); //the current alert object to be shown - default is null and object is populated only for the time it should be displayed
  emptyAlert: Alert = null;

  constructor() { }
  
  // Public function used to display alerts as needed
  showNewAlert(alert: Alert): void {
    // Pass the new alert object to the BehaviorSubject
    this.alertSource.next(alert);
    // After specified amount of time, reset the BehaviorSubject to a null object so alert component will not be displayed
    setTimeout(() => {
      this.alertSource.next(this.emptyAlert);
    }, alert.duration);
  }

}
