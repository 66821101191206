import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//Import Angular Fire modules
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire'
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';

//Import Services
import { AngularFireFunctions } from '@angular/fire/functions';
import { AppRoutingModule } from './app-routing.module';
import { DatabaseService } from './services/database.service';
import { AuthService } from './services/auth.service';
import { StorageService } from './services/storage.service';
import { AlertsService } from './services/alerts.service';
import { ProductApiService } from './services/product-api.service';


//Import Components
import { HomeComponent } from './components/home/home.component';
import { EnterAppComponent } from './components/enter-app/enter-app.component';
import { AboutComponent } from './components/about/about.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ProfileComponent } from './components/profile/profile.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { FriendsComponent } from './components/friends/friends.component';
import { ListComponent } from './components/list/list.component';
import { ItemComponent } from './components/item/item.component';
import { ListsComponent } from './components/lists/lists.component';
import { InviteComponent } from './components/invite/invite.component';
import { GiveComponent } from './components/give/give.component';
import { ThankYouListComponent } from './components/thank-you-list/thank-you-list.component';
import { UploadsComponent } from './components/uploads/uploads.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { HelpComponent } from './components/help/help.component';
import { ServiceWorkerModule } from '@angular/service-worker';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    EnterAppComponent,
    AboutComponent,
    DashboardComponent,
    NotFoundComponent,
    NavbarComponent,
    ProfileComponent,
    NotificationsComponent,
    FriendsComponent,
    ListComponent,
    ItemComponent,
    ListsComponent,
    InviteComponent,
    GiveComponent,
    ThankYouListComponent,
    UploadsComponent,
    AlertsComponent,
    VerifyEmailComponent,
    HelpComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase, 'presently'),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    FormsModule,
    NgbModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [AngularFireStorage, AngularFireFunctions, DatabaseService, AuthService, 
    StorageService, AlertsService, ProductApiService],
  bootstrap: [AppComponent]
})
export class AppModule { }
