import { Component, OnInit } from '@angular/core';
import { GENERAL_REF } from '../../dbReferences';
import { DatabaseService } from 'src/app/services/database.service';
import { User } from '../../models/User';
import { Notification } from '../../models/Notification';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  genRef;  //contains general references needed for the database/app
  dbRef; //contains text, phrases and other referencces needed for the database/app - pulled in the user's preferred language
  user: User;  //the User object for the current user
  notifications: Notification[];
  lastNotificationId: string;


  constructor(
    private databaseService: DatabaseService
  ) { }

  ngOnInit() {
    //Get a reference to the general and language specific database references
    this.genRef = GENERAL_REF;
    this.databaseService.dbRef.subscribe(ref => {
      this.dbRef = ref;
    });
    //Load the current User object
    this.databaseService.activeUser.subscribe(user => {
      if(user) {
        this.user = user;
        if(this.user.numTotalNotifications !== 0) {
          this.databaseService.getUserNotifications(this.user.userId).subscribe(notificationArray => {
            if(notificationArray) {
              this.notifications = notificationArray;
              this.lastNotificationId = this.notifications[this.notifications.length - 1].notificationId;
            }
          });
        } 
      }
    });
  }

  showMoreNotifications() {
    if(this.notifications.length !== this.user.numTotalNotifications) {
      this.databaseService.getNotificationDocSnapshot(this.user.userId, this.lastNotificationId).subscribe(doc => {
        this.databaseService.getUserNotifications(this.user.userId, doc).subscribe(notificationsArray => {
          this.notifications = this.notifications.concat(notificationsArray);
        });
      });
    }
  }

}
