import { Component, OnInit } from '@angular/core';
import { GENERAL_REF } from '../../dbReferences';
import { DatabaseService } from '../../services/database.service';
import { AlertsService } from '../../services/alerts.service';
import { StorageService } from '../../services/storage.service';
import { User } from '../../models/User';
import { UserMeta } from '../../models/UserMeta';
import { Router, ActivatedRoute } from '@angular/router';
import { List } from '../../models/List';
import { Notification } from '../../models/Notification';
import { ListView } from '../../models/ListView';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class ListComponent implements OnInit {
  genRef;  //contains general references needed for the database/app
  dbRef; //contains text, phrases and other referencces needed for the database/app - pulled in the user's preferred language
  user: User;  //the User object for the current user
  listId: string; //the unique ID of the list that is being displayed.  Equal to the firebase doc ID for this list document.
  list: List; //the original List object directly from the database that can't be changed via the UI unless changes are sent to the database first.
  editedList: List;  //a copy of the original list object that can be manipulated as needed with the UI and any updates can be submitted to the database to update the original.
  isNewList: boolean = false;  //indicates if this is a new list being added or not
  listView: ListView;  //Contains settings related to how user is viewing/filtering the list so it can be persisted when navigating from list to items page and back
  displayItems; //used for showing the list.items array so it can be sorted/filtered without changing the original in case of edit saves.
  ownerAdminList: string;  //a string variable used to display a readable list combining the owner name and any admin names
  editMode: boolean = false;  //indicates if the component is in edit mode, which allows the user to make changes to editedList
  editAdminMode: boolean = false; //indicates if the admin section is in edit mode, which allows the user to add or remove them
  editGiverMode: boolean = false; //indicates if the giver section is in edit mode, which allows the user to add or remove them  
  privateDetailsHidden: boolean = true; //indicates if the private list details section show be hidden or not
  listTypeDropdownCollapsed: boolean = false;  //toggles the List Type dropdown element
  privacyDropdownCollapsed: boolean = false;  //toggles the Privacy dropdown element
  surpriseDropdownCollapsed: boolean = false;  //toggles the Surprise Level dropdown element
  privacyDescriptions: string[];  //combines each privacy type with a brief description of its meaning so they can be displayed together in the Privacy selection drowdown elemnet.
  surpriseDescriptions: string[];  //combines each surprise level with a brief description of its meaning so they can be displayed together in the Surprise Level selection drowdown elemnet.
  newCategory: string;  //a variable to hold a new category entered by the user
  categoriesExist: boolean = false;  //indicates whether or not any categories exist for use with the html doc to show "none" if none exist.
  selectAllCategoriesChecked: boolean = true;
  adminExists: boolean = false;  //indicates whether or not any admin exist for use with the html doc to show "none" if none exist.
  friendsForAdmin: {  //an array that only includes friends of the user that are eligible to be added as admin (i.e. not already an admin or an invited admin)
    userId?: string, 
    name?: string,
    nickName?: string,
    email?: string,
    numLists?: number
  }[];
  friendsForAdminDropdownCollapsed: boolean = false;  //toggles the Friends dropdown element in the admin section
  selectedAdminFriend: {  //The friend object that is selected by the user to be added as an admin
    userId?: string,
    name?: string,
    nickName?: string,
    email?: string
  };  
  giversExist: boolean = false;  //indicates whether or not any givers exist for use with the html doc to show "none" if none exist.
  friendsForGiver: {  //an array that only includes friends of the user that are eligible to be added as Givers (i.e. not already a Giver or an invited Giver)
    userId?: string,
    name?: string,
    nickName?: string,
    email?: string,
    numLists?: number
  }[];
  friendsForGiverDropdownCollapsed: boolean = false;  //toggles the Friends dropdown element in the Giver section
  selectedGiverFriend: {  //The friend object that is selected by the user to be added as a giver
    userId?: string,
    name?: string,
    nickName?: string,
    email?: string
  }; 
  formattedExpirationDate: NgbDateStruct; //a variable to hold the expiration date in an appropriately formatted format
  formattedEventDate: NgbDateStruct; //a variable to hold the event date in an appropriately formatted format
  listPrivilege: string;  //a variable that indicates the current privilege level of the current user for the current list (Owner, Admin, Giver, or NotAuth)
  keywordSearch: string;  //a variable to hold the text input from the user for use with searching by keyword through list items
  sortDropdownCollapsed: boolean = false; //toggles the Sort dropdown element
  filterDropdownCollapsed: boolean = false;  //toggles the Filter dropdown element
  closeResult: string; //string used for modal to indicate how/why it was closed
  selectedPriceRange: number; //the current price range index that is selected by the user from the select element
  selectedPurchaseOption: number; //the current purchase option index that is selected by the user from the select element
  displayPurchaseOptions: string[]; //an array of purchase options that the user can choose from to filter the list - different for owner/admin vs giver
  userMeta: UserMeta;  //an object containing an array of all active users in the app

  constructor(
    private databaseService: DatabaseService,
    private alertsService: AlertsService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    //Get a reference to the general and language specific database references
    this.genRef = GENERAL_REF;
    let dbRefSub = this.databaseService.dbRef.subscribe(ref => {
      this.dbRef = ref;
    });
    dbRefSub.unsubscribe();

    //Pull the userMeta doc to get a list of all app active users
    let userMetaSub = this.databaseService.userMeta.subscribe(data => {
      this.userMeta = data;
    });
    userMetaSub.unsubscribe();

    //Get the relevant listId from the route parameter
    this.listId = this.route.snapshot.params[this.genRef.ROUTES_PARAM_LIST_ID];
    

    //get the user Object from the User Behavior Subject
    let activeUserSub = this.databaseService.activeUser.subscribe(user => {
      if(user !== null) {
        this.user = user;

        //If this is a new list, then set editMode to true, set defaults, and do not pull list from database
        if(this.listId == this.genRef.ROUTES_NEW){
          this.isNewList = true;
          this.editMode = true;
          this.privateDetailsHidden = false;
          this.list = {
            listId: this.genRef.LIST_DEFAULT_LIST_ID,
            name: this.user.name + this.dbRef.LIST_TEXT_DEFAULT_LIST_NAME_APPEND,
            owner: {
              userId: this.user.userId,
              name: this.user.name,
              email: this.user.email
            },
            categories: [this.dbRef.LIST_TEXT_DEFAULT_CATEGORY],
            shippingAddress: {},
            typeIndex: this.genRef.LIST_DEFAULT_TYPE_INDEX,
            privacyIndex: this.genRef.LIST_DEFAULT_PRIVACY_INDEX,
            surpriseIndex: this.genRef.LIST_DEFAULT_SURPRISE_LEVEL_INDEX,
            numUniqueItemsTotal: this.genRef.LIST_DEFAULT_NUM_UNIQUE_ITEMS,
            numQuantityItemsTotal: this.genRef.LIST_DEFAULT_NUM_QUANTITY_ITEMS,
            numQuantityItemsPurchased: this.genRef.LIST_DEFAULT_NUM_QUANTITY_PURCHASED,
            costTotal: this.genRef.LIST_DEFAULT_COST_TOTAL,
            costPurchased: this.genRef.LIST_DEFAULT_COST_PURCHASED
          }
          //Run new list processes to set privileges, list view, filters, and other defaults
          this.runNewListProcesses();
        } else {
          //Pull the List object from the Behavior Subject, if it exists or query the database if needed
          //Anytime the database list is updated, the activeList Behavior Subject will be updated and these lines will be executed as well
          let activeListSub = this.databaseService.activeList.subscribe(list => {
            //If the list is being deleted, do not execute any code as list doc is being 'updated'
            if(this.listId == this.genRef.LIST_TEXT_LIST_ID_DELETED){
              console.log("the list is being deleted");
            } else if(list == null 
              && this.listId !== this.genRef.LIST_TEXT_LIST_ID_BLOCKED 
              && this.listId !== this.genRef.LIST_TEXT_LIST_ID_INVALID){
            //If there is no activeList object, then query the database for the List by listId            
              console.log('THE ACTIVE LIST IS NULL')
              this.getUpdatedList();
              //Set the listId to invalid to prevent observable from looping
              this.listId = this.genRef.LIST_TEXT_LIST_ID_INVALID;
            } else if(list.listId == this.listId  || this.listId == this.genRef.LIST_TEXT_LIST_ID_INVALID) { 
            //If there is an activeList and the listId is the same as the listId from the route parameter, set that as the current list
              //Set the listId to blocked to prevent observable from looping
              this.listId = this.genRef.LIST_TEXT_LIST_ID_BLOCKED;
              console.log('THE LIST ID MATCHES*****')
              //Set the returned List object to the list variable
              this.list = list;
            //Run new list processes to set privileges, list view, filters, and other defaults that should be executed every time the list is updated           
              this.runNewListProcesses();
            } else if(list.listId !== this.listId 
              && this.listId !== this.genRef.LIST_TEXT_LIST_ID_BLOCKED 
              && this.listId !== this.genRef.LIST_TEXT_LIST_ID_DELETED 
              && this.listId !== this.genRef.LIST_TEXT_LIST_ID_INVALID) {
            //If there is an activeList but it is not the same as the listId from the route parameter, query the database for the List by listId
              console.log('THE LIST ID DOES NOT MATCH**********')
              this.getUpdatedList();
            }
          });
        }
      }
    });

    //Set the Admin and Givers add friends dropdown defaults as a placeholder for the toggle button - adding an admin or Giver is disabled if the dropdown element still shows this option
    this.selectedAdminFriend = {
      nickName: this.dbRef.LIST_TEXT_FRIENDS_DROPDOWN_PLACEHOLDER
    };
    this.selectedGiverFriend = {
      nickName: this.dbRef.LIST_TEXT_FRIENDS_DROPDOWN_PLACEHOLDER
    };
    //Create the privacy descriptions array for use in the UI
    this.privacyDescriptions = [
      this.dbRef.LIST_TEXT_PRIVACY_TYPES[this.genRef.LIST_PRIVACY_INDEX_PRIVATE] + " (" + this.dbRef.LIST_TEXT_PRIVACY_DESCRIPTIONS[this.genRef.LIST_PRIVACY_INDEX_PRIVATE] + ")",
      this.dbRef.LIST_TEXT_PRIVACY_TYPES[this.genRef.LIST_PRIVACY_INDEX_PUBLIC] + " (" + this.dbRef.LIST_TEXT_PRIVACY_DESCRIPTIONS[this.genRef.LIST_PRIVACY_INDEX_PUBLIC] + ")"
    ]
    //Create the surprise level descriptions array for use in the UI
    this.surpriseDescriptions = [
      this.dbRef.LIST_TEXT_SURPRISE_LEVELS[this.genRef.LIST_SURPRISE_INDEX_TOTALLY] + " (" + this.dbRef.LIST_TEXT_SURPRISE_DESCRIPTIONS[this.genRef.LIST_SURPRISE_INDEX_TOTALLY] + ")",
      this.dbRef.LIST_TEXT_SURPRISE_LEVELS[this.genRef.LIST_SURPRISE_INDEX_MILDLY] + " (" + this.dbRef.LIST_TEXT_SURPRISE_DESCRIPTIONS[this.genRef.LIST_SURPRISE_INDEX_MILDLY] + ")",
      this.dbRef.LIST_TEXT_SURPRISE_LEVELS[this.genRef.LIST_SURPRISE_INDEX_NOT] + " (" + this.dbRef.LIST_TEXT_SURPRISE_DESCRIPTIONS[this.genRef.LIST_SURPRISE_INDEX_NOT] + ")",
    ]
  }

  /**
   * Queries the database for a list by list ID - this function will not execute if the listId is set to blocked, deleted
   * or invalid to prevent the observable from looping upon updating
   */
  getUpdatedList() {
    if(this.listId !== this.genRef.LIST_TEXT_LIST_ID_BLOCKED 
      && this.listId !== this.genRef.LIST_TEXT_LIST_ID_DELETED 
      && this.listId !== this.genRef.LIST_TEXT_LIST_ID_INVALID){
      let listSub = this.databaseService.getListByListId(this.listId).subscribe(list => {
        //Set the listId to the newly queried list ID so the correct code can be executed in ngOnInit
        //Set the newly queried list to the active list behavior subject so it can be used throughout app without requerying database
        //This will also be updated any time the list is updated from firebase
        this.databaseService.updateActiveList(list);
        // listSub.unsubscribe();
      });
    }
  }

  /**
   * Should be called anytime the database List object is updated
   * Sets the current user's permission/privilege, the listView, creates the owner admin list, and sets filter options as needed
   * Updates the edited list with the new list pushed from the database
   */
  runNewListProcesses() {
    //Determine the privilege level the current user has for the current list
    this.setListPrivilege();
    //Get the current listView object
    let activeListViewSub = this.databaseService.activeListView.subscribe(listView => {
      this.listView = listView;
      //If there is an existing listView...
      if(this.listView) {
        //If the existing listview is for a different list than the active list, reset the listView
        if(this.listView.listId !== this.list.listId) {
          this.resetListView();
        } else {
        //If the existing listView is for the active list...
          //If there is already an ownerAdminList, the list ID is TBD, or the edited list is not the same as the active list
          //then the page has not be refreshed or otherwise doesn't need the new list processes updated - this prevents
          //the observable from getting stuck in a loop
          //Otherwise, run the processes required to update the list or listView
          if(this.ownerAdminList == null || this.editedList.listId == this.genRef.LIST_DEFAULT_LIST_ID || this.editedList !== this.list){
            console.log("THE PAGE WAS JUST LOADED");
            //Create owner and admin list for display            
            this.createOwnerAdminList();
            //Set the filter options to the options as set in the listView object
            this.selectedPriceRange = this.listView.filterPriceRangeIndex;
            this.selectedPurchaseOption = this.listView.filterPurchasedIndex;
            this.keywordSearch = this.listView.keyword;
            //If the giverView is false or null then set the filter options based on actual permissions
            if(!this.listView.giverView) {
              this.setFilterOptions(false);
            } else {
            //If the giverView is true then set the filter options special as if user is a giver and not owner/admin
              this.setFilterOptions(true);
            }
            //Create the editedList object for use throughout the UI
            this.updateEditedListWithList();
            //Filter and sort the items based on the selected filter/sort options
            this.filterSortItems()
          } 
        }
      } else {
      //If there is not an existing listView, then reset it to defaults
        this.resetListView();
      }
    });
    activeListViewSub.unsubscribe();
  }

  /**
   * Resets the listView options to the defaults
   */
  resetListView() {
    //Set selected price range to default
    this.selectedPriceRange = this.genRef.LIST_DEFAULT_PRICE_RANGE_INDEX;
    //Create the listView.filterCategories array based on the available list categories and add a property to indicate if the category is checked or not
    let filterCategories = [];
    if(this.list.categories) {
      for (let i = 0; i < this.list.categories.length; i++) {
        filterCategories.push({
          name: this.list.categories[i],
          isChecked: true
        });
      }
    }

    //If a category is assigned to an item, and later is deleted from the categories array, it could prevent that item from being
    //able to be displayed; therefore, need to loop through all items and ensure all categories are included in filterCategories array
    if(this.list.items) {
      //For each item, get the category
      for (let i = 0; i < this.list.items.length; i++) {
        let currentItemCategory = this.list.items[i].category;
        let categoryIncluded: boolean = false;
        //For each category, check if it is already on the filterCategories array and if so set the categoryIncluded boolean to true
        for (let x = 0; x < filterCategories.length; x++) {
          if(currentItemCategory == filterCategories[x].name) {
            categoryIncluded = true;
            break;
          }
        }
        //If the categoryIncluded boolean is still false, then the category is not included in the filterCategories array, and needs to be added
        if(!categoryIncluded) {
          filterCategories.push({
            name: currentItemCategory,
            isChecked: true
          });
        }
      }
    }

    //Sort the filterCategories array in alphabetical order to make it easier to find
    filterCategories.sort(function(a, b){
      var x = a.name.toLowerCase();
      var y = b.name.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });

    //Set up filter options based on permissions/privilege of current user
    this.setFilterOptions(false);

    //Update the listView object with the newly constructed options
    //The filterCategories array is what is used strictly for the filter dropdown/view of items
    this.databaseService.updateListView({
      listId: this.list.listId,
      giverView: false,
      keyword: null,
      sortIndex: this.genRef.LIST_DEFAULT_SORT_INDEX,
      filterCategories: filterCategories,
      filterPriceRangeIndex: this.selectedPriceRange,
      filterPurchasedIndex: this.selectedPurchaseOption
    });
  }

  /**
   * Determines the privilege level of the current user for the current list
   * Can be either Owner, Admin, Giver, or NotAuth
   */
  setListPrivilege() {
    //If this is a new list, then the current user is the owner
    if(this.isNewList) {
      this.listPrivilege = this.genRef.LIST_PRIVILEGES_OWNER;
      return;
    } else if(this.user.userId === this.list.owner.userId) {
    //Check if the current userId is equal to the list owner userId and if so set the privilege to Owner
      this.listPrivilege = this.genRef.LIST_PRIVILEGES_OWNER;
      return;
    } else { 
    //Check if the current userId is equal to any of the list admin userId's and if so set the privilege to Admin
      let isAdmin: boolean = false;
      if(this.list.admin) {
        for (let i = 0; i < this.list.admin.length; i++) {
          if (this.user.userId === this.list.admin[i].userId) {
            isAdmin = true;
            break;
          }
        }
      }
      if (isAdmin) {
        this.listPrivilege = this.genRef.LIST_PRIVILEGES_ADMIN;
        return;
      } else {  
      //Check if the current userId is equal to any of the list Givers userId's and if so set the privilege to Giver
        let isGiver: boolean = false;
        if(this.list.givers) {
          for (let i = 0; i < this.list.givers.length; i++) {
            if (this.user.userId === this.list.givers[i].userId) {
              isGiver = true;
              break;
            }
          }
        }
        if (isGiver) {
          this.listPrivilege = this.genRef.LIST_PRIVILEGES_GIVER;
          return;
        } else {  
        //If the userId does not match any of the above privileges, set to NotAuth which will not allow the list to be shown
          this.listPrivilege = this.genRef.LIST_PRIVILEGES_NOT_AUTH;
          return;
        }
      }
    }
  }

  /**
   * Sets the filter options to the defaults associated with the user's privilege level or based on the type of view selected
   * @param setForGiverView indicates if this function was called while the list owner/admin wants to view as if they are a giver
   * so the filter options need to be set according to giver defaults, not based on actual privileges
   */
  setFilterOptions(setForGiverView: boolean) {
    //If the function was called in order to set the filters based on the list being toggled in/out of giverView mode...
    if(setForGiverView) {
      if(!this.listView.giverView) {
      //If the user is NOT viewing in giverView mode...
        //Set the purchase options array to the full list of options
        this.displayPurchaseOptions = [...this.dbRef.LIST_TEXT_PURCHASED_OPTIONS];
        //Set the selected purchase option to the default for the owner/admin view
        this.selectedPurchaseOption = this.genRef.LIST_DEFAULT_PURCHASED_OPTION_OWNER_ADMIN;
        //Set the listView selected purchase option to the default for the owner/admin view
        this.listView.filterPurchasedIndex = this.selectedPurchaseOption;
      } else {
      //If the owner/admin user is viewing in giverView mode...
        //Set the purchase options array to include only the options available for givers to view
        this.displayPurchaseOptions = [...this.dbRef.LIST_TEXT_PURCHASED_OPTIONS];
        this.displayPurchaseOptions = this.displayPurchaseOptions.slice(0,2);
        //If the listView already has a filterPurchaseIndex set, set the selected purchase option to that value
        //This will only be set if in giverView and navigate away from list page and then back to it
        if(this.listView.filterPurchasedIndex) {
          this.selectedPurchaseOption = this.listView.filterPurchasedIndex;
        } else {
        //Otherwise, set the selected purchase option to the default for a giver and update the listView as the same
          this.selectedPurchaseOption = this.genRef.LIST_DEFAULT_PURCHASED_OPTION_GIVER;
          this.listView.filterPurchasedIndex = this.selectedPurchaseOption;
        }
      }
    } else {
    //If the function was called in order to set the filters based on the user's actual list privileges...
      if(this.listPrivilege !== this.genRef.LIST_PRIVILEGES_GIVER) {
      //If the user is an owner/admin, set the purchase options array to the full list of options
        this.displayPurchaseOptions = [...this.dbRef.LIST_TEXT_PURCHASED_OPTIONS];
        //If the selected purchase option is not already set, set it to the default option for owner/admin
        if(this.selectedPurchaseOption == null) {
          this.selectedPurchaseOption = this.genRef.LIST_DEFAULT_PURCHASED_OPTION_OWNER_ADMIN;
        }
      } else {
      //If the user is a giver, set the purchase options array to include only the options available for givers to view
        this.displayPurchaseOptions = [...this.dbRef.LIST_TEXT_PURCHASED_OPTIONS];
        this.displayPurchaseOptions = this.displayPurchaseOptions.slice(0,2);
        //If the selected purchase option is not alraedy set, set it to the default option for givers
        if(this.selectedPurchaseOption == null) {
          this.selectedPurchaseOption = this.genRef.LIST_DEFAULT_PURCHASED_OPTION_GIVER;
        }
      }
    }
  }

  /**
   * Called any time the list object is updated to ensure that the editedList object doesn't show unsaved changes
   */
  updateEditedListWithList() {
    // Any embedded arrays or objects need to be "copied" here so any edits are not automatically transposed to original array
    console.log("The edited list has been updated with database list data");
    // Make a copy of the List Object to create a "working" editedList object so changes aren't saved until all 
    // are complete and edited list values replace list values (to reduce firebase writes).
    this.editedList = Object.assign({}, this.list);

    //If there are categories, then make a copy of the array specifically, or it will be linked back to original user and make it look like there are changes saved that aren't actually saved.
    if (this.list.categories){
      this.editedList.categories = [...this.list.categories];
      //If there are categories to display, then set the categoriesExist boolean to true
      if(this.editedList.categories.length > 0) {
        this.categoriesExist = true;
      }
    }
    //If there are admin, then make a copy of the array specifically, or it will be linked back to original user and make it look like there are changes saved that aren't actually saved.
    if (this.list.admin){
      this.editedList.admin = [];
      for (let i = 0; i < this.list.admin.length; i++) {
        this.editedList.admin[i] = Object.assign({}, this.list.admin[i]);
      };
    }
    //If there are invited Admin, then make a copy of the array specifically, or it will be linked back to original user and make it look like there are changes saved that aren't actually saved.
    if (this.list.invitedAdmin){
      this.editedList.invitedAdmin = [];
      for (let i = 0; i < this.list.invitedAdmin.length; i++) {
        this.editedList.invitedAdmin[i] = Object.assign({}, this.list.invitedAdmin[i]);
      };
    }
    //If there are Givers, then make a copy of the array specifically, or it will be linked back to original user and make it look like there are changes saved that aren't actually saved.
    if (this.list.givers){
      this.editedList.givers = [];
      for (let i = 0; i < this.list.givers.length; i++) {
        this.editedList.givers[i] = Object.assign({}, this.list.givers[i]);
      };
    }
    //If there are invited Givers, then make a copy of the array specifically, or it will be linked back to original user and make it look like there are changes saved that aren't actually saved.
    if (this.list.invitedGivers){
      this.editedList.invitedGivers = [];
      for (let i = 0; i < this.list.invitedGivers.length; i++) {
        this.editedList.invitedGivers[i] = Object.assign({}, this.list.invitedGivers[i]);
      };
    }
    //If there are items, call setDisplayList function to make a copy of those properly
    if (this.list.items){
      this.setDisplayList();
    }
    //If there is an event date, then format it for the NgbDatePicker
    if (this.editedList.eventDate){
      this.formattedEventDate = this.dateToDatePicker(this.editedList.eventDate);
    }
    //If there is an expiration date, then format it for the NgbDatePicker
    // if (this.editedList.expirationDate) {
    //   this.formattedExpirationDate = this.dateToDatePicker(this.editedList.expirationDate);
    // }
    //Create the list of friends eligible to be added as admin (not already admin)
    this.createFriendsForAdminList();
    //Create the list of friends eligible to be added as a Giver (not already a Giver)
    this.createFriendsForGiverList();

    //Determine if an admin or invited admin needs to be displayed and set the adminExists boolean accordingly
    if(this.editedList.admin){
      if(this.editedList.admin.length == 0){
        if(this.editedList.invitedAdmin){
          if(this.editedList.invitedAdmin.length == 0){
            this.adminExists = false;
          } else {
            this.adminExists = true;
          }
        } else {
          this.adminExists = false;
        }
      } else {
        this.adminExists = true;
      }
    } else if(this.editedList.invitedAdmin) {
        if(this.editedList.invitedAdmin.length == 0){
          this.adminExists = false;
        } else {
          this.adminExists = true;
        }
    } else {
      this.adminExists = false;
    }
    //Determine if a Giver or invited Giver needs to be displayed and set the giversExist boolean accordingly
    if(this.editedList.givers){
      if(this.editedList.givers.length == 0){
        if(this.editedList.invitedGivers){
          if(this.editedList.invitedGivers.length == 0){
            this.giversExist = false;
          } else {
            this.giversExist = true;
          }
        } else {
          this.giversExist = false;
        }
      } else {
        this.giversExist = true;
      }
    } else if(this.editedList.invitedGivers) {
        if(this.editedList.invitedGivers.length == 0){
          this.giversExist = false;
        } else {
          this.giversExist = true;
        }
    } else {
      this.giversExist = false;
    }
    
  }

  /**
   * Copies the list.items array and all nested objects/arrays to the displayItems variable which can be manipulated for display purposes
   */
  setDisplayList() {
    this.displayItems = [];
    for (let i = 0; i < this.list.items.length; i++) {
      this.displayItems[i] = Object.assign({}, this.list.items[i]);
      if(this.list.items[i].purchasedBy) {
        for (let x = 0; x < this.list.items[i].purchasedBy.length; x++) {
          this.displayItems[i].purchasedBy[x] = Object.assign({}, this.list.items[i].purchasedBy[x]); 
          if(this.displayItems[i].purchasedBy[x].userId == this.user.userId) {
            this.displayItems[i].isPurchasedByUser = true;            
          }
        }
      }
    }
  }

  /**
   * Changes the list type of the editedList when the user selects a new one from the dropdown
   * Toggles the dropdown element
   * @param index is the index number of the selected list type of the `dbRef.LIST_TEXT_LIST_TYPES` array
   */
  selectNewListType(index: number) {
    this.listTypeDropdownCollapsed = !this.listTypeDropdownCollapsed;
    this.editedList.typeIndex = index;
  }

  /**
   * Changes the privacy of the editedList when the user selects a new one from the dropdown
   * Toggles the dropdown element
   * @param index is the index number of the selected privacy of the `dbRef.LIST_TEXT_PRIVACY_TYPES` array
   */
  selectNewPrivacy(index: number) {
    this.privacyDropdownCollapsed = !this.privacyDropdownCollapsed;
    this.editedList.privacyIndex = index;
  }

  /**
   * Changes the surprise level of the editedList when the user selects a new one from the dropdown
   * Toggles the dropdown element
   * @param index is the index number of the selected surprise level of the `dbRef.LIST_TEXT_SURPRISE_LEVELS` array
   */
  selectNewSurpriseLevel(index: number) {
    this.surpriseDropdownCollapsed = !this.surpriseDropdownCollapsed;
    this.editedList.surpriseIndex = index;
  }

  /**
   * Adds a new category to the `editedList.categories` array or creates the array if it doesn't exist.
   */
  addCategory() {
    // If there is aleady a category array, then add it to the array and clear input field
    if (this.editedList.categories){
      //Before adding, check and make sure the newCategory isn't already on the categories array to prevent duplicates
      let categoryAlreadyExists: boolean = false;
      for (let i = 0; i < this.editedList.categories.length; i++) {
        if(this.editedList.categories[i] == this.newCategory) {
          categoryAlreadyExists = true;
          break;
        }
      }
      if(!categoryAlreadyExists) {
        this.editedList.categories.push(this.newCategory);
      } 
      this.newCategory = null;
      this.categoriesExist = true;
    } else {
      // If there is not an existing array, then create one with the new category and clear input field
      this.editedList.categories = [this.newCategory]
      this.newCategory = null;
      this.categoriesExist = true;
    }
  }

  /**
   * Deletes the specified category from the `editedList.categories` array and if there are no categories left, sets the categoriesExist boolean to false
   * @param index is the index number of the selected category from the `editedList.categories` array
   */
  deleteCategory(index: number) {
    if (this.editedList.categories){
      this.editedList.categories.splice(index, 1);
      if (this.editedList.categories.length == 0){
        this.categoriesExist = false;
      };
    }
  }

  /**
   * Adds a new invited admin by email address to the existing array or creates one if it doesn't exist.
   * Database is immediately updated with new invited admin, so user does not need to save changes to take effect
   * @param newAdminEmail is the email address entered by the user 
   * @param valid indicates whether or not the submitted value is valid based on the form rules
   */
  inviteAdmin({value, valid}: {value: {newAdminEmail: string}, valid: boolean}):void {
    let newAdminEmailAddress = value.newAdminEmail.toLowerCase();
    let alreadyInvited: boolean = false;
    
    if (this.editedList.invitedAdmin && valid){
      if(this.user.friends) {
        for (let i = 0; i < this.user.friends.length; i++) {
          if(this.user.friends[i].email == newAdminEmailAddress) {
            let nickName: string = null;
            if(this.user.friends[i].nickName) {
              nickName = this.user.friends[i].nickName;
            }
            this.selectedAdminFriend = {
              name: this.user.friends[i].name,
              nickName: nickName,
              email: this.user.friends[i].email,
              userId: this.user.friends[i].userId,            
            };
            //The user is already a friend, so invite via the inviteFriendForAdmin method
            this.inviteFriendForAdmin();
            alreadyInvited = true;
            return;
          }  
        }
      }

      for (let i = 0; i < this.editedList.invitedAdmin.length; i++) {
        if(this.editedList.invitedAdmin[i].email == newAdminEmailAddress){
          alreadyInvited = true;
          break;
        };
      }
      if(!alreadyInvited){
        this.editedList.invitedAdmin.unshift({email: newAdminEmailAddress});
      }
    } else if(valid) {
      // If there is not an existing array, then create one with the new email and clear input field
      this.editedList.invitedAdmin = [{email: newAdminEmailAddress}]
    }

    let alreadyInvitedFriend: boolean = false;
    if(this.user.invitedFriends && valid) {
      for (let i = 0; i < this.user.invitedFriends.length; i++) {
        if(this.user.invitedFriends[i] == newAdminEmailAddress) {
          alreadyInvitedFriend = true;
          break;
        };
      }
      if(!alreadyInvitedFriend) {
        this.user.invitedFriends.unshift(newAdminEmailAddress);
      }
    } else if (valid) {
      this.user.invitedFriends = [newAdminEmailAddress];
    }

    if(!alreadyInvited) {
      //Update the lists admin list
      this.databaseService.updateListField(
        this.list.listId, 
        {
          invitedAdmin: this.editedList.invitedAdmin
        },
        {
          message: newAdminEmailAddress + this.dbRef.LIST_ALERT_ADMIN_INVITED,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_SUCCESS
        },
        {
          message: this.dbRef.LIST_ALERT_ADMIN_NOT_INVITED,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_DANGER
        }
      );
      if(!alreadyInvitedFriend) {
        this.databaseService.updateUserField(this.user.userId, {invitedFriends: this.user.invitedFriends});
      }

      let invitedUserId: string;
      for (let i = 0; i < this.userMeta.users.length; i++) {
        if(this.userMeta.users[i].email == newAdminEmailAddress) {
          invitedUserId = this.userMeta.users[i].userId;
          break;
        };
      }

      if(invitedUserId) {
        let invitedUser = this.databaseService.getUserByUID(invitedUserId).subscribe(user => {

          //Create the notification object 
          let notificationId = this.databaseService.generateNotificationId();
          let newNotification: Notification = {
            notificationId: notificationId,
            date: new Date(),
            message: this.user.name 
              + "(" 
              + this.user.email 
              + ") has invited you to be an admin on " 
              + this.list.name 
              + "!",
            routerLink: this.genRef.ROUTES_INVITE 
              + '/' + notificationId
              + '/' + this.genRef.ROUTES_ADMIN
              + '/' + this.list.listId 
              + '/' + user.email,
            email: user.email,
            languageIndex: user.languageIndex,
            notificationIndex: user.notificationIndex
          }
      
          if(user.notifications) {
            user.notifications.unshift(newNotification);
            if(user.notifications.length > 3) {
              user.notifications.pop();
            }
          } else {
            user.notifications = [newNotification];
          }
          user.numTotalNotifications += 1;
          this.databaseService.updateUserField(invitedUserId, {
            notifications: user.notifications,
            numTotalNotifications: user.numTotalNotifications
          });

          this.databaseService.addNotification(invitedUserId, newNotification);
          
          invitedUser.unsubscribe();
        });
      } else {
        //If they are not already a user, add them to invitedUsers list
        
        //Create the notification object 
        let notificationId = this.databaseService.generateNotificationId();
        let newNotification: Notification = {
          notificationId: notificationId,
          date: new Date(),
          message: this.user.name 
            + "(" 
            + this.user.email 
            + ") has invited you to be an admin on " 
            + this.list.name 
            + "!",
          routerLink: this.genRef.ROUTES_INVITE 
            + '/' + notificationId
            + '/' + this.genRef.ROUTES_ADMIN
            + '/' + this.list.listId 
            + '/' + newAdminEmailAddress,
          email: newAdminEmailAddress,
          languageIndex: this.genRef.DB_DEFAULT_LANGUAGE_PREF,
          notificationIndex: this.genRef.PROFILE_DEFAULT_NOTIFICATION_PREF_INDEX
        }
        
        this.databaseService.addInvitedUser(newAdminEmailAddress, newNotification, this.user.userId);
      }
    }
  }

  /**
   * Deletes the specified invited admin from the `editedList.invitedAdmin` array
   * Database is immediately updated with deleted invited admin, so user does not need to save changes to take effect   * 
   * @param index is the index number of the selected invited admin from the `editedList.invitedAdmin` array
   */
  deleteInvitedAdmin(index: number) {
    if (this.editedList.invitedAdmin){

      let deletedAdminName: string = this.editedList.invitedAdmin[index].name;
      let deletedAdminEmail: string = this.editedList.invitedAdmin[index].email;
      let deletedAdmin: string;
      if(deletedAdminName) {
        deletedAdmin = deletedAdminName + ' (' + deletedAdminEmail + ')';
      } else {
        deletedAdmin = deletedAdminEmail;
      }

      this.editedList.invitedAdmin.splice(index, 1);
      this.databaseService.updateListField(
        this.list.listId, 
        {
          invitedAdmin: this.editedList.invitedAdmin
        },
        {
          message: deletedAdmin + this.dbRef.LIST_ALERT_ADMIN_UNINVITED,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_SUCCESS
        },
        {
          message: this.dbRef.LIST_ALERT_ADMIN_NOT_UNINVITED,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_DANGER
        }
      );
      if (this.editedList.invitedAdmin.length == 0){
        if(this.editedList.admin == null){
          this.adminExists = false;
        } else if(this.editedList.admin.length == 0) {
          this.adminExists = false;
        }
      };
    }
  }
  
  /**
   * Selects a friend from the `friendsForAdmin` array when the user selects one from the dropdown
   * Toggles the dropdown element
   * @param index is the index number of the selected friend of the `friendsForAdmin` array
   */
  selectFriendForAdmin(index: number){
    this.friendsForAdminDropdownCollapsed = !this.friendsForAdminDropdownCollapsed;
    this.selectedAdminFriend = this.friendsForAdmin[index];
  }

  /**
   * Adds a new invited friend admin to the existing array or creates one if it doesn't exist.
   * Database is immediately updated with new invited admin, so user does not need to save changes to take effect   * 
   */
  inviteFriendForAdmin():void {
    let eligibleToInvite: boolean = false;
    //Need to check and make sure the friend isn't already invited or an admin, since this method can also be called from
    //the user inviting by email
    for (let i = 0; i < this.friendsForAdmin.length; i++) {
      if(this.friendsForAdmin[i].userId == this.selectedAdminFriend.userId){
        //The friend is on the friends for admin list, which means they are not already an admin or invited admin
        eligibleToInvite = true;
        break;
      }
    }
    if(eligibleToInvite) {
    // If there is aleady an invited admin array, then add it to the array and clear input field
      if (this.editedList.invitedAdmin){
        this.editedList.invitedAdmin.unshift(this.selectedAdminFriend);
      } else {
        // If there is not an existing array, then create one with the new friend object and reset input field
        this.editedList.invitedAdmin = [this.selectedAdminFriend];
      }

      let invitedUserSub = this.databaseService.getUserByUID(this.selectedAdminFriend.userId).subscribe(user => {

        //Create the notification object 
        let notificationId = this.databaseService.generateNotificationId();
        let newNotification: Notification = {
          notificationId: notificationId,
          date: new Date(),
          message: this.user.name 
            + "(" 
            + this.user.email 
            + ") has invited you to be an admin on " 
            + this.list.name 
            + "!",
          routerLink: this.genRef.ROUTES_INVITE 
            + '/' + notificationId
            + '/' + this.genRef.ROUTES_ADMIN
            + '/' + this.list.listId 
            + '/' + user.userId,
          email: user.email,
          languageIndex: user.languageIndex,
          notificationIndex: user.notificationIndex
        }

        if(user.notifications) {
          user.notifications.unshift(newNotification);
          if(user.notifications.length > 3) {
            user.notifications.pop();
          }
        } else {
          user.notifications = [newNotification];
        }
        user.numTotalNotifications += 1;
        this.databaseService.updateUserField(user.userId, {
          notifications: user.notifications,
          numTotalNotifications: user.numTotalNotifications
        });
        
        this.databaseService.addNotification(user.userId, newNotification);

        invitedUserSub.unsubscribe();
      });

      this.databaseService.updateListField(
        this.list.listId, 
        {
          invitedAdmin: this.editedList.invitedAdmin
        },
        {
          message: this.selectedAdminFriend.name + this.dbRef.LIST_ALERT_ADMIN_INVITED,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_SUCCESS
        },
        {
          message: this.dbRef.LIST_ALERT_ADMIN_NOT_INVITED,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_DANGER
        }
      );
      this.createFriendsForAdminList();
    }
    this.selectedAdminFriend = {
      nickName: this.dbRef.LIST_TEXT_FRIENDS_DROPDOWN_PLACEHOLDER
    }
  }

  /**
   * Deletes the specified admin from the `editedList.admin` array
   * Database is immediately updated with deleted admin, so user does not need to save changes to take effect   * 
   * @param index is the index number of the selected admin from the `editedList.admin` array
   */
  deleteAdmin(index: number) {
    if (this.editedList.admin){
      let adminSub = this.databaseService.getUserByUID(this.editedList.admin[index].userId).subscribe(user => {
        for (let i = 0; i < user.adminLists.length; i++) {
          if(user.adminLists[i].listId == this.editedList.listId) {
            user.adminLists.splice(i,1);
            break;
          }
        };
        this.databaseService.updateUserField(user.userId, {adminLists: user.adminLists});
        adminSub.unsubscribe();
      });
      let deletedAdminName = this.editedList.admin[index].name;
      this.editedList.admin.splice(index, 1);
      this.databaseService.updateListField(
        this.editedList.listId, 
        {
          admin: this.editedList.admin
        },
        {
          message: deletedAdminName + this.dbRef.LIST_ALERT_ADMIN_UNINVITED,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_SUCCESS
        },
        {
          message: this.dbRef.LIST_ALERT_ADMIN_NOT_UNINVITED,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_DANGER
        }
      );
      if (this.editedList.admin.length == 0){
        if(this.editedList.invitedAdmin == null){
          this.adminExists = false;
        } else if(this.editedList.invitedAdmin.length == 0) {
          this.adminExists = false;
        }
      };
    }
  }

  /**
   * Creates a list of the user's friends that are eligible to be added as admin (not already admin or invited admin)
   */
  createFriendsForAdminList() {
    //If the user has friends, create a copy of the user's friends list
    if(this.user.friends) {
      this.friendsForAdmin = [];
      for (let i = 0; i < this.user.friends.length; i++) {
        this.friendsForAdmin[i] = Object.assign({},this.user.friends[i]);
      }
      //Create a checklist that will hold all admin and invited admin to check against user's friends list
      let checklist = [];
      let adminChecklist = [];
      //If there are existing invited admin, loop through them to find only those that 
      //have userId's (indicates they are also a friend of the user) and add them to a new invitedAdminChecklist array 
      //to check against user's friends list
      if (this.editedList.invitedAdmin){
        let invitedAdminChecklist = [];
        for(let y=0; y < this.editedList.invitedAdmin.length; y++){
          if(this.editedList.invitedAdmin[y].userId){
            invitedAdminChecklist.push(this.editedList.invitedAdmin[y]);
          }
        }
        //If there are existing admin, copy that list to a new array of admin to check against friends list
        if(this.editedList.admin){
          for (let i = 0; i < this.editedList.admin.length; i++) {
            adminChecklist[i] = Object.assign({}, this.editedList.admin[i]);
          }
          //Combine the invited admin checklist with the admin checklist for the complete checklist
          checklist = adminChecklist.concat(invitedAdminChecklist);
        } else {
          checklist = invitedAdminChecklist;
        }
      //if there are no existing invited admin and there are admin, then set the checklist to include only the admin
      } else if(this.editedList.admin) {
        for (let i = 0; i < this.editedList.admin.length; i++) {
          adminChecklist[i] = Object.assign({}, this.editedList.admin[i]);
        }
        checklist = adminChecklist;
      } //Otherwise, there are no existing admin or invited admin
      //For each of the admin on the checklist, get the userId
      for(let i=0; i < checklist.length; i++) {
        let currentAdminId = checklist[i].userId;
        //Loop through the userId's of the admin and if they match the userId's of the friendsForAdmin list, 
        //remove them from the list since friends that are already admin should not be re-invited and therefore
        //do not need to be on the friendsForAdmin list.
        for(let x = this.friendsForAdmin.length - 1; x > -1; x--){
          if(currentAdminId == this.friendsForAdmin[x].userId){
            this.friendsForAdmin.splice(x,1);
            break;
          }
        }
      }
      //Loop through the friendsForAdmin list and remove the numLists property so that it doesn't get added back
      //to the database under the admin or invited admin objects in the list
      for(let i=0; i<this.friendsForAdmin.length; i++){
        delete this.friendsForAdmin[i].numLists;
      }
    }
  }

  /**
   * Adds a new invited Giver by email address to the existing array or creates one if it doesn't exist.
   * Database is immediately updated with new invited Giver, so user does not need to save changes to take effect
   * @param newGiverEmail is the email address entered by the user 
   * @param valid indicates whether or not the submitted value is valid based on the form rules
   */
  inviteGiver({value, valid}: {value: {newGiverEmail: string}, valid: boolean}):void {
    let newGiverEmailAddress: string = value.newGiverEmail.toLowerCase();
    let alreadyInvited: boolean = false;
    // If there is aleady an invited Giver array, then add it to the array and clear input field
    if (this.editedList.invitedGivers && valid){
      if(this.user.friends) {
        for (let i = 0; i < this.user.friends.length; i++) {
          if(this.user.friends[i].email == newGiverEmailAddress) {
            let nickName: string = null;
            if(this.user.friends[i].nickName) {
              nickName = this.user.friends[i].nickName;
            }
            this.selectedGiverFriend = {
              name: this.user.friends[i].name,
              nickName: nickName,
              email: this.user.friends[i].email,
              userId: this.user.friends[i].userId,            
            };
            //The user is already a friend, so invite via the inviteFriendForAdmin method
            this.inviteFriendForGiver();
            alreadyInvited = true;
            return;
          }  
        }
      }

      for (let i = 0; i < this.editedList.invitedGivers.length; i++) {
        if(this.editedList.invitedGivers[i].email == newGiverEmailAddress){
          alreadyInvited = true;
          break;
        };
      }
      if(!alreadyInvited){
        this.editedList.invitedGivers.unshift({email: newGiverEmailAddress});
      }
    } else if(valid) {
      // If there is not an existing array, then create one with the new email and clear input field
      this.editedList.invitedGivers = [{email: newGiverEmailAddress}]
    }

    let alreadyInvitedFriend: boolean = false;
    if(this.user.invitedFriends && valid) {
      for (let i = 0; i < this.user.invitedFriends.length; i++) {
        if(this.user.invitedFriends[i] == newGiverEmailAddress) {
          alreadyInvitedFriend = true;
          break;
        };
      }
      if(!alreadyInvitedFriend) {
        this.user.invitedFriends.unshift(newGiverEmailAddress);
      }
    } else if (valid) {
      this.user.invitedFriends = [newGiverEmailAddress];
    }

    if(!alreadyInvited) {
      //Update the lists admin list
      this.databaseService.updateListField(
        this.list.listId, 
        {
          invitedGivers: this.editedList.invitedGivers
        },
        {
          message: newGiverEmailAddress + this.dbRef.LIST_ALERT_GIVER_INVITED,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_SUCCESS
        },
        {
          message: this.dbRef.LIST_ALERT_GIVER_NOT_INVITED,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_DANGER
        }
      );
      if(!alreadyInvitedFriend) {
        this.databaseService.updateUserField(this.user.userId, {invitedFriends: this.user.invitedFriends});
      }

      //Add a notification to the invited user, if they are already a user
      let invitedUserId: string;
      for (let i = 0; i < this.userMeta.users.length; i++) {
        if(this.userMeta.users[i].email == newGiverEmailAddress) {
          invitedUserId = this.userMeta.users[i].userId;
          break;
        };
      }
      if(invitedUserId) {
        let invitedUserSub = this.databaseService.getUserByUID(invitedUserId).subscribe(user => {

          //Create the notification object 
          let notificationId = this.databaseService.generateNotificationId();
          let newNotification: Notification = {
            notificationId: notificationId,
            date: new Date(),
            message: this.user.name 
              + "(" 
              + this.user.email 
              + ") has invited you to join " 
              + this.list.name 
              + "!",
            routerLink: this.genRef.ROUTES_INVITE 
              + '/' + notificationId
              + '/' + this.genRef.ROUTES_GIVER
              + '/' + this.list.listId 
              + '/' + user.email,
            email: user.email,
            languageIndex: user.languageIndex,
            notificationIndex: user.notificationIndex
          }

          if(user.notifications) {
            user.notifications.unshift(newNotification);
            if(user.notifications.length > 3) {
              user.notifications.pop();
            }
          } else {
            user.notifications = [newNotification];
          }
          user.numTotalNotifications += 1;
          this.databaseService.updateUserField(invitedUserId, {
            notifications: user.notifications,
            numTotalNotifications: user.numTotalNotifications
          });
  
          this.databaseService.addNotification(invitedUserId, newNotification);

          invitedUserSub.unsubscribe();
        });
      } else {
        //If they are not already a user, add them to invitedUsers list

        //Create the notification object 
        let notificationId = this.databaseService.generateNotificationId();
        let newNotification: Notification = {
          notificationId: notificationId,
          date: new Date(),
          message: this.user.name 
            + "(" 
            + this.user.email 
            + ") has invited you to join " 
            + this.list.name 
            + "!",
          routerLink: this.genRef.ROUTES_INVITE 
            + '/' + notificationId
            + '/' + this.genRef.ROUTES_GIVER
            + '/' + this.list.listId 
            + '/' + newGiverEmailAddress,
          email: newGiverEmailAddress,
          languageIndex: this.genRef.DB_DEFAULT_LANGUAGE_PREF,
          notificationIndex: this.genRef.PROFILE_DEFAULT_NOTIFICATION_PREF_INDEX
        }

        this.databaseService.addInvitedUser(newGiverEmailAddress, newNotification, this.user.userId);
      }
    }
 
  }

  /**
   * Deletes the specified invited Giver from the `editedList.invitedGivers` array
   * Database is immediately updated with deleted invited Giver, so user does not need to save changes to take effect   * 
   * @param index is the index number of the selected invited Giver from the `editedList.invitedGivers` array
   */
  deleteInvitedGiver(index: number) {
    if (this.editedList.invitedGivers){
      let deletedGiverName: string = this.editedList.invitedGivers[index].name;
      let deletedGiverEmail: string = this.editedList.invitedGivers[index].email;
      let deletedGiver: string;
      if(deletedGiverName) {
        deletedGiver = deletedGiverName + ' (' + deletedGiverEmail + ')';
      } else {
        deletedGiver = deletedGiverEmail;
      }

      this.editedList.invitedGivers.splice(index, 1);
      this.databaseService.updateListField(
        this.list.listId, 
        {
          invitedGivers: this.editedList.invitedGivers
        },
        {
          message: deletedGiver + this.dbRef.LIST_ALERT_GIVER_UNINVITED,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_SUCCESS
        },
        {
          message: this.dbRef.LIST_ALERT_GIVER_NOT_UNINVITED,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_DANGER
        }
      );
      if (this.editedList.invitedGivers.length == 0){
        if(this.editedList.givers == null){
          this.giversExist = false;
        } else if(this.editedList.givers.length == 0) {
          this.giversExist = false;
        }
      };
    }
  }

  /**
   * Selects a friend from the `friendsforGivers` array when the user selects one from the dropdown
   * Toggles the dropdown element
   * @param index is the index number of the selected friend of the `friendsforGivers` array
   */
  selectFriendForGiver(index: number){
    this.friendsForGiverDropdownCollapsed = !this.friendsForGiverDropdownCollapsed;
    this.selectedGiverFriend = this.friendsForGiver[index];
  }

  /**
   * Adds a new invited friend Giver to the existing array or creates one if it doesn't exist.
   * Database is immediately updated with new invited Giver, so user does not need to save changes to take effect 
   */
  inviteFriendForGiver():void {
    let eligibleToInvite: boolean = false;
    //Need to check and make sure the friend isn't already invited or an Giver, since this method can also be called from
    //the user inviting by email
    for (let i = 0; i < this.friendsForGiver.length; i++) {
      if(this.friendsForGiver[i].userId == this.selectedGiverFriend.userId){
        //The friend is on the friends for Giver list, which means they are not already a Giver or invited Giver
        eligibleToInvite = true;
        break;
      }
    }
    if(eligibleToInvite) {
    // If there is aleady an invited Giver array, then add it to the array and clear input field
      if (this.editedList.invitedGivers){
        this.editedList.invitedGivers.unshift(this.selectedGiverFriend);
      } else {
        // If there is not an existing array, then create one with the new friend object and reset input field
        this.editedList.invitedGivers = [this.selectedGiverFriend];
      }

      let invitedUserSub = this.databaseService.getUserByUID(this.selectedGiverFriend.userId).subscribe(user => {

        //Create the notification object 
        let notificationId = this.databaseService.generateNotificationId();
        let newNotification: Notification = {
          notificationId: notificationId,
          date: new Date(),
          message: this.user.name 
            + "(" 
            + this.user.email 
            + ") has invited you to join " 
            + this.list.name 
            + "!",
          routerLink: this.genRef.ROUTES_INVITE 
            + '/' + notificationId
            + '/' + this.genRef.ROUTES_GIVER
            + '/' + this.list.listId 
            + '/' + user.userId,
          email: user.email,
          languageIndex: user.languageIndex,
          notificationIndex: user.notificationIndex
        }

        if(user.notifications) {
          user.notifications.unshift(newNotification);
          if(user.notifications.length > 3) {
            user.notifications.pop();
          }
        } else {
          user.notifications = [newNotification];
        }
        user.numTotalNotifications += 1;

        this.databaseService.updateUserField(user.userId, {
          notifications: user.notifications,
          numTotalNotifications: user.numTotalNotifications
        });

        this.databaseService.addNotification(user.userId, newNotification);

        invitedUserSub.unsubscribe();
      });

      this.databaseService.updateListField(
        this.list.listId, 
        {
          invitedGivers: this.editedList.invitedGivers
        },
        {
          message: this.selectedGiverFriend.name + this.dbRef.LIST_ALERT_GIVER_INVITED,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_SUCCESS
        },
        {
          message: this.dbRef.LIST_ALERT_GIVER_NOT_INVITED,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_DANGER
        }
      );
      this.createFriendsForGiverList();
    }
    this.selectedGiverFriend = {
      nickName: this.dbRef.LIST_TEXT_FRIENDS_DROPDOWN_PLACEHOLDER
    }
  }

  /**
   * Deletes the specified Giver from the `editedList.givers` array
   * Database is immediately updated with deleted Giver, so user does not need to save changes to take effect   * 
   * @param index is the index number of the selected Giver from the `editedList.givers` array
   */
  deleteGiver(index: number) {
    if (this.editedList.givers){

      if(this.user.myLists) {
        for (let i = 0; i < this.user.myLists.length; i++) {
          if(this.user.myLists[i].listId == this.list.listId) {
            this.user.myLists[i].numGivers -= 1;
            break;
          }
        }
      }

      this.databaseService.updateUserField(this.user.userId, {myLists: this.user.myLists});

      let giverSub = this.databaseService.getUserByUID(this.editedList.givers[index].userId).subscribe(user => {
        console.log(user);
        for (let i = 0; i < user.friendsLists.length; i++) {
          if(user.friendsLists[i].listId == this.editedList.listId) {
            user.friendsLists.splice(i,1);
            break;
          }
        };
        for (let i = 0; i < user.friends.length; i++) {
          if(user.friends[i].userId == this.user.userId) {
            user.friends[i].numLists -= 1;
            break;
          }
        }
        this.databaseService.updateUserField(user.userId, {
          friendsLists: user.friendsLists,
          friends: user.friends
        });
        giverSub.unsubscribe();
      });
      
      let deletedGiverName = this.editedList.givers[index].name;
      this.editedList.givers.splice(index, 1);
      this.databaseService.updateListField(
        this.editedList.listId, 
        {
          givers: this.editedList.givers
        },
        {
          message: deletedGiverName + this.dbRef.LIST_ALERT_GIVER_UNINVITED,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_SUCCESS
        },
        {
          message: this.dbRef.LIST_ALERT_GIVER_NOT_UNINVITED,
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_DANGER
        }
      );
      if (this.editedList.givers.length == 0){
        if(this.editedList.invitedGivers == null){
          this.giversExist = false;
        } else if(this.editedList.invitedGivers.length == 0) {
          this.giversExist = false;
        }
      };
    }
  }

  /**
   * Creates a list of the user's friends that are eligible to be added as Givers (not already Givers or invited Givers)
   */
  createFriendsForGiverList() {
    //If the user has friends, create a copy of the user's friends list
    if(this.user.friends) {
      this.friendsForGiver = [];
      for (let i = 0; i < this.user.friends.length; i++) {
        this.friendsForGiver[i] = Object.assign({}, this.user.friends[i]);
      }
      //Create a checklist that will hold all Givers and invited Givers to check against user's friends list
      let checklist = [];
      let giverChecklist = [];
      //If there are existing invited Giver, loop through them to find only those that 
      //have userId's (indicates they are also a friend of the user) and add them to a new invitedGiverChecklist array 
      //to check against user's friends list
      if (this.editedList.invitedGivers){
        let invitedGiverChecklist = [];
        for(let y=0; y < this.editedList.invitedGivers.length; y++){
          if(this.editedList.invitedGivers[y].userId){
            invitedGiverChecklist.push(this.editedList.invitedGivers[y]);
          }
        }
        //If there are existing Givers, copy that list to a new array of Givers to check against friends list
        if(this.editedList.givers){
          for (let i = 0; i < this.editedList.givers.length; i++) {
            giverChecklist[i] = Object.assign({}, this.editedList.givers[i]);
          }
          //Combine the invited Givers checklist with the Givers checklist for the complete checklist
          checklist = giverChecklist.concat(invitedGiverChecklist);
        } else {
          checklist = invitedGiverChecklist;
        }
      //if there are no existing invited Givers and there are Givers, then set the checklist to include only the Givers
      } else if(this.editedList.givers) {
        for (let i = 0; i < this.editedList.givers.length; i++) {
          giverChecklist[i] = Object.assign({}, this.editedList.givers[i]);
        }
        checklist = giverChecklist;
      } //Otherwise, there are no existing Givers or invited Givers
      //For each of the Givers on the checklist, get the userId
      for(let i=0; i < checklist.length; i++) {
        let currentGiverId = checklist[i].userId;
        //Loop through the userId's of the Givers and if they match the userId's of the friendsForGiver list, 
        //remove them from the list since friends that are already Givers should not be re-invited and therefore
        //do not need to be on the friendsForGiver list.
        for(let x = this.friendsForGiver.length - 1; x > -1; x--){
          if(currentGiverId == this.friendsForGiver[x].userId){
            this.friendsForGiver.splice(x,1);
            break;
          }
        }
      }
      //Loop through the friendsForGiver list and remove the numLists property so that it doesn't get added back
      //to the database under the Givers or invited Givers objects in the list
      for(let i=0; i<this.friendsForGiver.length; i++){
        delete this.friendsForGiver[i].numLists;
      }
    }
  }

  /**
   * Takes in a Date object and converts it to a NgbDateStruct object for use with the DatePicker element
   * @param date is the date that needs to be converted
   */
  dateToDatePicker(date: Date): NgbDateStruct {
    if(this.editedList == null){
      return null;
    } else {
      return {
        month: date.getMonth()+1,
        day: date.getDate(),
        year: date.getFullYear()
      }
    }
  }

  /**
   * Takes in an NgbDateStruct object and converts it to a Date object that can be sent back to the database
   * @param datePickerDate is the date that needs to be converted
   */
  datePickerToDate(datePickerDate: NgbDateStruct): Date {
    return new Date(datePickerDate.month + '/' + datePickerDate.day + '/' + datePickerDate.year);
  }

  /**
   * Creates a readable list of the owner and any admin (if appicable) to be used for display in the UI
   */
  createOwnerAdminList() {
    if(this.list.admin){
      //If the admin list is empty, the ownerAdminList should only include the owner name
      if(this.list.admin.length == 0) {
        this.ownerAdminList = this.list.owner.name;
      } else { //If there are admin, the ownerAdminList should include the owner and all admin names
        this.ownerAdminList = this.list.owner.name;
        for( let i = 0; i < this.list.admin.length - 1; i++) {
          this.ownerAdminList += (", " + this.list.admin[i].name);
        }
        this.ownerAdminList += (" & " + this.list.admin[this.list.admin.length - 1].name);
      }
    } else {
      this.ownerAdminList = this.list.owner.name;
    }
  }
  
  /**
   * Enables editMode, which allows the owner or admin to update the list details in the UI
   */
  enableEditMode() {
    this.editMode = true;
    this.privateDetailsHidden = false;
  }

  /**
   * Cancels edit mode and resets variables
   */
  cancelEdit() {
    this.editMode = false;
    this.privateDetailsHidden = true;
    this.updateEditedListWithList();
    this.filterSortItems();
  }
  
  /**
   * Sends the current editedList object and sends it to the database to update the list details
   * Not used for adding/removing admin, Givers, or items
   */
  saveEdit() {
    //Convert any dates back to a Date object from an NgbDateStruct object so they are compatible with database
    if(this.editedList.eventDate){
      this.editedList.eventDate = this.datePickerToDate(this.formattedEventDate);
    }

    if(this.editedList.name == ''){
      this.editedList.name = this.editedList.owner.name + "'s List"
    }

    //If the list has a new name, also need to update the user's myLists and adminLists and friends lists
    if(this.editedList.name !== this.list.name || this.editedList.surpriseIndex !== this.list.surpriseIndex) {

      //Change the list name for the owner's myLists array
      //If the current user is the owner of the list, then update their my lists
      if(this.user.userId == this.editedList.owner.userId){
        for (let i=0; i< this.user.myLists.length; i++){
          if(this.user.myLists[i].listId == this.editedList.listId){
            this.user.myLists[i].listName = this.editedList.name;
            this.user.myLists[i].surpriseIndex = this.editedList.surpriseIndex;
            break;
          }
        }
        this.databaseService.updateUserWithFeedback(this.user.userId, {myLists: this.user.myLists}).then(result => {
          if(result) {
            //update Firebase list details
            this.databaseService.updateListField(
              this.editedList.listId, 
              this.editedList,
              {
                message: this.dbRef.LIST_ALERT_CHANGES_SAVED, 
                duration: this.genRef.ALERTS_DURATION_STANDARD,
                class: this.genRef.ALERTS_CLASS_SUCCESS
              },
              {
                message: this.dbRef.LIST_ALERT_CHANGES_NOT_SAVED, 
                duration: this.genRef.ALERTS_DURATION_STANDARD,
                class: this.genRef.ALERTS_CLASS_DANGER
              }
            );
            this.listId = this.list.listId; 
            // this.getUpdatedList();
          }
        }).catch(err => {
          this.alertsService.showNewAlert({
            message: "There was a problem  updating the list.",
            duration: this.genRef.ALERTS_DURATION_STANDARD,
            class: this.genRef.ALERTS_CLASS_DANGER
          });
        });
      } else { //If the current user is NOT the owner, then pull the owner user object and update their my lists
        let listOwnerSub = this.databaseService.getUserByUID(this.editedList.owner.userId).subscribe(user => {
          for (let i=0; i< user.myLists.length; i++){
            if(user.myLists[i].listId == this.editedList.listId){
              user.myLists[i].listName = this.editedList.name;
              user.myLists[i].surpriseIndex = this.editedList.surpriseIndex;
              break;
            }
          }
          this.databaseService.updateUserField(this.editedList.owner.userId, {myLists: user.myLists}); 
          listOwnerSub.unsubscribe();     
        });
      }

      //Change the list name for the admin's adminLists array
      if(this.editedList.admin){
        for (let i=0; i< this.editedList.admin.length; i++) {
          //If the current user is the admin, then update their adminLists array with the new list name
          if(this.user.userId == this.editedList.admin[i].userId) {
            for (let i=0; i< this.user.adminLists.length; i++){
              if(this.user.adminLists[i].listId == this.editedList.listId){
                this.user.adminLists[i].listName = this.editedList.name;
                this.user.adminLists[i].surpriseIndex = this.editedList.surpriseIndex;
                break;
              }
            }  
            this.databaseService.updateUserWithFeedback(this.user.userId, {adminLists: this.user.adminLists}).then(result => {
              if(result) {
                //update Firebase list details
                this.databaseService.updateListField(
                  this.editedList.listId, 
                  this.editedList,
                  {
                    message: this.dbRef.LIST_ALERT_CHANGES_SAVED, 
                    duration: this.genRef.ALERTS_DURATION_STANDARD,
                    class: this.genRef.ALERTS_CLASS_SUCCESS
                  },
                  {
                    message: this.dbRef.LIST_ALERT_CHANGES_NOT_SAVED, 
                    duration: this.genRef.ALERTS_DURATION_STANDARD,
                    class: this.genRef.ALERTS_CLASS_DANGER
                  }
                );
                this.listId = this.list.listId; 
                // this.getUpdatedList();
              }
            }).catch(err => {
              this.alertsService.showNewAlert({
                message: "There was a problem  updating the list.",
                duration: this.genRef.ALERTS_DURATION_STANDARD,
                class: this.genRef.ALERTS_CLASS_DANGER
              });
            });

          } else {// If the current user is not the admin, then pull the admin user object and update their admin list
            let adminSub = this.databaseService.getUserByUID(this.editedList.admin[i].userId).subscribe(user => {
              for (let i=0; i< user.adminLists.length; i++){
                if(user.adminLists[i].listId == this.editedList.listId){
                  user.adminLists[i].listName = this.editedList.name;
                  user.adminLists[i].surpriseIndex = this.editedList.surpriseIndex;
                  break;
                }
              }
              this.databaseService.updateUserField(this.editedList.admin[i].userId, {adminLists: user.adminLists});
              adminSub.unsubscribe();      
            });
          }
        }
      }

      //Change the list name for the Givers' friendsList array
      if(this.editedList.givers && this.editedList.name !== this.list.name){
        for (let i=0; i< this.editedList.givers.length; i++) {
          let giverSub = this.databaseService.getUserByUID(this.editedList.givers[i].userId).subscribe(user => {
            for (let i=0; i< user.friendsLists.length; i++){
              if(user.friendsLists[i].listId == this.editedList.listId){
                user.friendsLists[i].listName = this.editedList.name;
                break;
              }
            }
            this.databaseService.updateUserField(this.editedList.givers[i].userId, {friendsLists: user.friendsLists});  
            giverSub.unsubscribe();    
          });
        }
      }
    } else {
      //update Firebase list details
      this.databaseService.updateListField(
        this.editedList.listId, 
        this.editedList,
        {
          message: this.dbRef.LIST_ALERT_CHANGES_SAVED, 
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_SUCCESS
        },
        {
          message: this.dbRef.LIST_ALERT_CHANGES_NOT_SAVED, 
          duration: this.genRef.ALERTS_DURATION_STANDARD,
          class: this.genRef.ALERTS_CLASS_DANGER
        }
      );
      this.listId = this.list.listId; 
      // this.getUpdatedList();
    }

    //Leave editMode
    this.editMode = false;
  }

  /**
   * Toggles whether or not the shipping address is shown to the users when not in editMode
   */
  toggleShowShipping() {
    this.editedList.showShippingAddress = !this.editedList.showShippingAddress;
  }


  filterSortItems() {
    console.log("FILTERING OR SORTING ITEMS")
    //FILTER THE ITEMS
    //The priceRangeIndex and purchasedOptionsIndex variables are numbers, but are passed in as strings due to the
    //way the value is passed in from [value]="i" in html.
    //Reset the displayItems array to clear any existing filters and ensure all items are included
    if (this.list.items){
      this.setDisplayList();
    
      //Filter by Purchased Status
      switch(this.listView.filterPurchasedIndex) {
        case this.genRef.LIST_PURCHASED_INDEX_NEEDED:
            for (let i = this.displayItems.length-1; i > -1; i--) {
              if(this.displayItems[i].quantityRemaining == 0) {
                this.displayItems.splice(i,1);
              }
            }
            break;
        case this.genRef.LIST_PURCHASED_INDEX_MY_PURCHASES:
            for (let i = this.displayItems.length-1; i > -1; i--) {
              if(this.displayItems[i].isPurchasedByUser == null) {
                this.displayItems.splice(i,1);
              }
            }
            break;
        case this.genRef.LIST_PURCHASED_INDEX_ALL:
            //Keep all items on display list
            break;
        default:
            //Keep all items on display list
            break;
      }

      //Set the low and high price range values based on the priceRangeIndex passed in
      let priceRangeLow: number = this.genRef.LIST_PRICE_RANGE_VALUES[this.listView.filterPriceRangeIndex].low;
      let priceRangeHigh: number = this.genRef.LIST_PRICE_RANGE_VALUES[this.listView.filterPriceRangeIndex].high;
      //Loop through the display items and remove any items whose price falls outside the selected range
      for(let i = this.displayItems.length-1; i > -1; i--){
        if (this.displayItems[i].price < priceRangeLow || this.displayItems[i].price > priceRangeHigh){
          this.displayItems.splice(i,1);
        }
      }
      //Create an array that will only include items that are in one of the categories selected by the user
      let itemsTempFilterList = []; 
      //Loop through each of the current displayItems items and identify the category
      for(let i = 0; i < this.displayItems.length; i++){
        let currentItemCategory: string = this.displayItems[i].category;
        //Take the category of the current item and if it is one of the categories selected by the user, add it to the itemsTempFilterList array
        for(let x = 0; x < this.listView.filterCategories.length; x++){
          if(currentItemCategory == this.listView.filterCategories[x].name){
            if(this.listView.filterCategories[x].isChecked){
              itemsTempFilterList.push(this.displayItems[i]);
            }
          }
        }
      }
      //Set the displayItems array to equal the array filtered by category
      this.displayItems = itemsTempFilterList;

      //KEYWORD SEARCH
      if (this.listView.keyword) {
        if(this.displayItems.length > 0) {
          let tempDisplayList = [];
          for (let i = 0; i < this.displayItems.length; i++) {
            let searchString = this.listView.keyword;
            let title;
            let merchant;
            let description;
            let notes;
            if (this.displayItems[i].title) {
              title = this.displayItems[i].title.toLowerCase();
            } else {
              title = "";
            }
            if (this.displayItems[i].merchant) {
              merchant = this.displayItems[i].merchant.toLowerCase();
            } else {
              merchant = "";
            }
            if (this.displayItems[i].description) {        
              description = this.displayItems[i].description.toLowerCase();
            } else {
              description = "";
            }
            if (this.displayItems[i].notes) {
              notes = this.displayItems[i].notes.toLowerCase();
            } else {
              notes = "";
            }
    
            if(title.includes(searchString)) {
              tempDisplayList.push(this.displayItems[i]);
            } else if(merchant.includes(searchString)) {
              tempDisplayList.push(this.displayItems[i]);  
            } else if(description.includes(searchString)) {
              tempDisplayList.push(this.displayItems[i]);  
            } else if(notes.includes(searchString)) {
              tempDisplayList.push(this.displayItems[i]);  
            }
          }
          this.displayItems = tempDisplayList;
        }
      }

      //SORT ITEMS
      //Sort the items depending on which sort option was selected
      if(this.displayItems.length > 0) {
        switch(this.listView.sortIndex) {
          case this.genRef.LIST_TEXT_SORT_INDEX_PRICE_LOW_HIGH:
            //Sort by price low to high
            this.displayItems.sort(function(a, b){
              return a.price - b.price;
            });
            break;
          case this.genRef.LIST_TEXT_SORT_INDEX_PRICE_HIGH_LOW:
            //Sort by price high to low
            this.displayItems.sort(function(a, b){
              return b.price - a.price;
            });
            break;
          case this.genRef.LIST_TEXT_SORT_INDEX_ALPHABETICAL:
            //Sort alphabetically
            this.displayItems.sort(function(a, b){
              var x = a.title.toLowerCase();
              var y = b.title.toLowerCase();
              if (x < y) {return -1;}
              if (x > y) {return 1;}
              return 0;
            });
            break;
          case this.genRef.LIST_TEXT_SORT_INDEX_CATEGORY:
            //Sort by category (alphabetically then categorically)
            this.displayItems.sort(function(a, b){
              var x = a.title.toLowerCase();
              var y = b.title.toLowerCase();
              if (x < y) {return -1;}
              if (x > y) {return 1;}
              return 0;
            });
            this.displayItems.sort(function(a, b){
              var x = a.category.toLowerCase();
              var y = b.category.toLowerCase();
              if (x < y) {return -1;}
              if (x > y) {return 1;}
              return 0;
            });
            break;
          default:
            //Sort by category (alphabetically then categorically)
            this.displayItems.sort(function(a, b){
              var x = a.title.toLowerCase();
              var y = b.title.toLowerCase();
              if (x < y) {return -1;}
              if (x > y) {return 1;}
              return 0;
            });
            this.displayItems.sort(function(a, b){
              var x = a.category.toLowerCase();
              var y = b.category.toLowerCase();
              if (x < y) {return -1;}
              if (x > y) {return 1;}
              return 0;
            });
            break;    
        }
      }
    }
  }

  /**
   * Searches through the items by keyword
   */
  searchByKeyword() {
    if (this.keywordSearch && this.keywordSearch !== '') {
      this.listView.keyword = this.keywordSearch.toLowerCase();
    } else {
      this.listView.keyword = null;
    }
    this.filterSortItems();
    this.databaseService.updateListView(this.listView);
  }

  /**
   * Sorts the list of items based on the user's sort selection
   * @param index is the index of the `genRef.LIST_TEXT_SORT_OPTIONS` array that has been selected
   */
  sortList(index: number) {
    this.listView.sortIndex = index;
    //Ensure the sort dropdown element is collapsed
    this.sortDropdownCollapsed = false;
    this.filterSortItems();
    this.databaseService.updateListView(this.listView);
  }

  /**
   * Filters the list of items based on the user's selection(s)
   * @param priceRangeIndex is the index number of the `genRef.LIST_TEXT_PRICE_RANGE_OPTIONS` array that was
   * selected; this index matches with the indexes of the `genRef.LIST_PRICE_RANGE_VALUES` array that is used to 
   * compare item prices against
   */
  filterList(priceRangeIndex, purchasedOptionsIndex) {
    this.listView.filterPriceRangeIndex = parseInt(priceRangeIndex);
    this.listView.filterPurchasedIndex = parseInt(purchasedOptionsIndex);
    //Ensure the filter drop down element is collapsed
    this.filterDropdownCollapsed = false;
    this.filterSortItems();
    this.databaseService.updateListView(this.listView);
  }

  /**
   * When the user checks or unchecks a category box in the Filter dropdown element, this method is called to update
   * the listView.filterCategories array accordingly so it is always updated with the current selection (items will not actually be 
   * filtered until the Filter button is clicked)
   * @param category is the category that was checked or unchecked
   */
  checkCategory(setAll: boolean, index?: number) {
    if (setAll) {
      this.selectAllCategoriesChecked = !this.selectAllCategoriesChecked;
      for (let i = 0; i < this.listView.filterCategories.length; i++) {
        this.listView.filterCategories[i].isChecked = this.selectAllCategoriesChecked;
      }
    } else {
      this.selectAllCategoriesChecked = false;
      this.listView.filterCategories[index].isChecked = !this.listView.filterCategories[index].isChecked;
    }
  }

  viewAsGiver() {
    this.listView.giverView = true;
    this.listView.filterPurchasedIndex = null;
    this.setFilterOptions(true);
    this.databaseService.updateListView(this.listView);
    this.filterSortItems();
  }

  viewAsOwnerAdmin() {
    this.listView.giverView = false;
    this.setFilterOptions(true);
    this.databaseService.updateListView(this.listView);
    this.filterSortItems();
  }

  /**
   * Cancels creating a new list and returns to Lists Component
   */
  cancelNewList() {
    this.router.navigate([this.genRef.ROUTES_LISTS]);    
  }
  
  /**
   * Sends the current editedList object and sends it to the database to update the list details
   * Not used for adding/removing admin, Givers, or items
   */
  saveNewList() {
    //Convert any dates back to a Date object from an NgbDateStruct object so they are compatible with database
    if(this.editedList.eventDate){
      this.editedList.eventDate = this.datePickerToDate(this.formattedEventDate);
    }

    //Create the share code
    this.editedList.shareCode = this.generateShareCode();

    //Save new list to Firebase
    this.databaseService.addList(this.editedList).then(newListId => {
      this.alertsService.showNewAlert({
        message: this.editedList.name + this.dbRef.LIST_ALERT_LIST_ADDED, 
        duration: this.genRef.ALERTS_DURATION_STANDARD,
        class: this.genRef.ALERTS_CLASS_SUCCESS
      });

      this.isNewList = false;
      this.editMode = false;
      this.listId = newListId;

      let myListInfo;
      if(this.editedList.eventDate) {
        myListInfo = {
          eventDate: this.editedList.eventDate,
          listId: newListId,
          listName: this.editedList.name,
          numQuantityItemsPurchased: 0,
          numQuantityItemsTotal: 0,
          numGivers: 0,

        }
      } else {
        myListInfo = {
          listId: newListId,
          listName: this.editedList.name,
          numQuantityItemsPurchased: 0,
          numQuantityItemsTotal: 0,
          numGivers: 0,
          surpriseIndex: this.editedList.surpriseIndex
        }
      }

      if(this.user.myLists) {
        this.user.myLists.unshift(myListInfo);
      } else {
        this.user.myLists = [myListInfo];
      }

      this.router.navigate([this.genRef.ROUTES_LIST + '/' + newListId]); 
      this.databaseService.updateUserField(this.user.userId, {myLists: this.user.myLists});
      this.databaseService.updateListField(newListId, {listId: newListId});

    }).catch(err => {

      this.alertsService.showNewAlert({
        message: this.dbRef.LIST_ALERT_LIST_NOT_ADDED, 
        duration: this.genRef.ALERTS_DURATION_STANDARD,
        class: this.genRef.ALERTS_CLASS_DANGER
      });
    });  
  }

  deleteList() {
    this.modalService.dismissAll("Delete Confirmed");
    this.router.navigate([this.genRef.ROUTES_LIST + '/deleted']);

    //Delete any uploaded images
    if(this.list.items) {
      this.list.items.forEach(item => {
        if(item.imageUrlStoragePath) {
          this.storageService.deleteFile(item.imageUrlStoragePath);          
        }
      });
    }

    //Delete the list from the owner's myLists array
    for (let i=0; i< this.user.myLists.length; i++){
      if(this.user.myLists[i].listId == this.editedList.listId){
        this.user.myLists.splice(i,1);
        break;
      }
    }
    this.databaseService.updateUserField(this.user.userId, {myLists: this.user.myLists});
  
    // Delete the list from the admin's adminLists array
    if(this.editedList.admin){
      for (let i=0; i< this.editedList.admin.length; i++) {
        // If the current user is not the admin, then pull the admin user object and update their admin list
        let adminSub = this.databaseService.getUserByUID(this.editedList.admin[i].userId).subscribe(user => {
          for (let i=0; i< user.adminLists.length; i++){
            if(user.adminLists[i].listId == this.editedList.listId){
              user.adminLists.splice(i,1);            
              break;
            }
          }
          this.databaseService.updateUserField(this.editedList.admin[i].userId, {adminLists: user.adminLists}); 
          adminSub.unsubscribe();
        });
      } 
    }

    //Change the list name for the Givers' friendsList array
    if(this.editedList.givers){
      for (let i=0; i< this.editedList.givers.length; i++) {
        let giverSub = this.databaseService.getUserByUID(this.editedList.givers[i].userId).subscribe(user => {
          for (let i=0; i< user.friendsLists.length; i++){
            if(user.friendsLists[i].listId == this.editedList.listId){
              user.friendsLists.splice(i,1);
              break;
            }
          }
          if(user.friends) {
            for (let i = 0; i < user.friends.length; i++) {
              if(user.friends[i].userId == this.user.userId) {
                user.friends[i].numLists -= 1;
                break;
              }
            }
          }

          this.databaseService.updateUserField(this.editedList.givers[i].userId, {
            friendsLists: user.friendsLists,
            friends: user.friends
          });  
          giverSub.unsubscribe();    
        });
      }
    }
    
    this.databaseService.deleteList(this.editedList.listId, this.dbRef.LIST_ALERT_LIST_DELETED, this.dbRef.LIST_ALERT_LIST_NOT_DELETED);
    this.router.navigate([this.genRef.ROUTES_LISTS]); 
    
  }

  generateShareCode(): string {
    let shareCode = "";
    for (let x = 0; shareCode.length < 10; x++) {
      let code = Math.random().toString(36).substr(2).toUpperCase();
      for (let i = 0; shareCode.length < 10 && i < code.length; i++) {
        if(code[i] !== 'I' && code[i] !== 'L' && code[i] !== '1' && code[i] !== 'O' && code[i] !== '0' && code[i] !== 'Q') {
          shareCode += code[i];
        }
      }
    }
    return shareCode;
  }

  generateThankYouList() {
    this.router.navigate([this.genRef.ROUTES_THANK_YOU_LIST + '/' + this.list.listId])
  }

  addNewItem() {
    this.listId = this.list.listId;
    this.router.navigate([this.genRef.ROUTES_LIST + '/' + this.editedList.listId + '/' + this.genRef.ROUTES_NEW_ITEM]);
  }

  /**
   * MODAL-RELATED FUNCTIONS:
   */
  open(content) {
    this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}