import { Component, OnInit } from '@angular/core';
import { GENERAL_REF } from '../../dbReferences';
import { DatabaseService } from '../../services/database.service';
import { AuthService } from '../../services/auth.service';
import { AlertsService } from '../../services/alerts.service';
import { User } from '../../models/User';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  genRef;  //contains general references needed for the database/app
  dbRef; //contains text, phrases and other referencces needed for the database/app - pulled in the user's preferred language
  user: User;  //the User object for the current user
  editedUser: User;
  newPassword: string;
  confirmPassword: string;
  currentPassword: string;
  editMode: boolean = false;  //indicates if the component is in edit mode, which allows the user to make changes to editedList
  authEditMode: boolean = false;  //indicates if the component is in edit mode, which allows the user to make changes to editedList  
  authorizedEditMode: boolean = false;  //indicates if the component is in edit mode, which allows the user to make changes to editedList    
  changePasswordMode: boolean = false;
  deleteAuthEditMode: boolean = false;  //indicates if the component is in edit mode, which allows the user to make changes to editedList  
  deleteAuthorizedEditMode: boolean = false;
  firebaseUser: firebase.User;
  showUnableToSaveMsg: boolean = false;
  showUnableToSavePasswordMsg: boolean = false;
  notificationPrefDropdownCollapsed: boolean = false;  //toggles the Notification Preference dropdown element
  languagePrefDropdownCollapsed: boolean = false;  //toggles the Language Preference dropdown element
  closeResult: string;  // Used by modal to 


  constructor(
    private databaseService: DatabaseService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private alertsService: AlertsService
  ) { }

  ngOnInit() {
    //Get a reference to the general and language specific database references
    this.genRef = GENERAL_REF;
    this.databaseService.dbRef.subscribe(ref => {
      this.dbRef = ref;
    });

    //get the user Object from the User Behavior Subject
    this.databaseService.activeUser.subscribe(user => {
      if(user !== null) {
        this.user = user;
        
        this.editedUser = Object.assign({}, this.user);      
      }
    });
      
  }

  /**
   * Enables editMode, which allows the owner or admin to update the list details in the UI
   */
  enableEditMode() {
    this.editMode = true;
  }

  /**
   * Cancels edit mode and resets variables
   */
  cancelEdit() {
    this.editMode = false;
    this.authEditMode = false;
    this.authorizedEditMode = false;
    this.showUnableToSaveMsg = false;
    this.editedUser = Object.assign({}, this.user);
  }
  
  /**
   * Sends the current editedList object and sends it to the database to update the list details
   * Not used for adding/removing admin, Givers, or items
   */
  saveEdit() {
    if (this.editedUser.name == '' || this.editedUser.email == '') {
      this.showUnableToSaveMsg = true;
    } else {
      let nameChanged: boolean = false;
      let emailChanged: boolean = false;
      let preferencesChanged: boolean = false;

      if(this.editedUser.name !== this.user.name) {
        nameChanged = true;
      }
      if(this.editedUser.email !== this.user.email && this.authorizedEditMode) {
        emailChanged = true;
      }
      if(this.editedUser.notificationIndex !== this.user.notificationIndex || this.editedUser.languageIndex !== this.user.languageIndex) {
        preferencesChanged = true;
      }

      if(preferencesChanged && !nameChanged && !emailChanged) {
        console.log("Updating preferences only")
        //Only update the user's profile info 
        this.databaseService.updateUserField(
          this.user.userId, 
          {
            notificationIndex: this.editedUser.notificationIndex,
            languageIndex: this.editedUser.languageIndex
          },
          {
            message: this.dbRef.PROFILE_ALERT_PROFILE_UPDATED,
            duration: this.genRef.ALERTS_DURATION_STANDARD,
            class: this.genRef.ALERTS_CLASS_SUCCESS
          },
          {
            message: this.dbRef.PROFILE_ALERT_PROFILE_NOT_UPDATED,
            duration: this.genRef.ALERTS_DURATION_STANDARD,
            class: this.genRef.ALERTS_CLASS_DANGER
          }
        );
      } 

      //Check if the email address has been changed
      if(emailChanged) {  
        console.log("Updating email and name")
        this.authService.changeEmail(this.firebaseUser, this.editedUser.email).then(res => {
          console.log("The email has been updated with firebase: " + this.editedUser.email);
          //Update the userMeta doc with the new email for the user
          this.databaseService.updateUserMeta(this.user.userId, this.editedUser.email);
          //Search the invitedUsers collection to see if this new email is on there & remove if so
          this.databaseService.deleteInvitedUser(this.editedUser.email);
          //Update user email throughout database
          this.updateUserEmailOrName();
          this.databaseService.updateUserField(
            this.user.userId, 
            {
              name: this.editedUser.name,
              email: this.editedUser.email,
              notificationIndex: this.editedUser.notificationIndex,
              languageIndex: this.editedUser.languageIndex
            },
            {
              message: this.dbRef.PROFILE_ALERT_PROFILE_UPDATED,
              duration: this.genRef.ALERTS_DURATION_STANDARD,
              class: this.genRef.ALERTS_CLASS_SUCCESS
            },
            {
              message: this.dbRef.PROFILE_ALERT_PROFILE_NOT_UPDATED,
              duration: this.genRef.ALERTS_DURATION_STANDARD,
              class: this.genRef.ALERTS_CLASS_DANGER
            }
          );
          // Refresh page so that it prompts password keeper on browser to update password/email
          this.refreshComponent(this.genRef.ROUTES_PROFILE);
        }).catch(err => {
          console.log(err);
          return;
        });
      } else if (nameChanged) {
        console.log("Updating name only")
        this.updateUserEmailOrName();
        this.databaseService.updateUserField(
          this.user.userId,
          {
            name: this.editedUser.name,
            notificationIndex: this.editedUser.notificationIndex,
            languageIndex: this.editedUser.languageIndex
          },
          {
            message: this.dbRef.PROFILE_ALERT_PROFILE_UPDATED,
            duration: this.genRef.ALERTS_DURATION_STANDARD,
            class: this.genRef.ALERTS_CLASS_SUCCESS
          },
          {
            message: this.dbRef.PROFILE_ALERT_PROFILE_NOT_UPDATED,
            duration: this.genRef.ALERTS_DURATION_STANDARD,
            class: this.genRef.ALERTS_CLASS_DANGER
          }
        );
      } 

      //Leave editMode
      this.editMode = false;
      this.authorizedEditMode = false;
      this.authEditMode = false;
      this.showUnableToSaveMsg = false;
      this.showUnableToSavePasswordMsg = false;
    }

  }
  

  updateUserEmailOrName() {
    if(this.user.myLists) {
      for (let i = 0; i < this.user.myLists.length; i++) {
        let ownerListsSub = this.databaseService.getListByListId(this.user.myLists[i].listId).subscribe(list => {
          if(list.items) {
            for (let x = 0; x < list.items.length; x++) {
              if(list.items[x].purchasedBy) {
                for (let y = 0; y < list.items[x].purchasedBy.length; y++) {
                  if(list.items[x].purchasedBy[y].userId == this.user.userId) {
                    list.items[x].purchasedBy[y].email = this.editedUser.email;
                    list.items[x].purchasedBy[y].name = this.editedUser.name;
                    break;
                  };
                }
              }
            }
            this.databaseService.updateListField(this.user.myLists[i].listId, {
              owner: {
                email: this.editedUser.email,
                name: this.editedUser.name,
                userId: this.editedUser.userId
              },
              items: list.items
            });
          } else {
            this.databaseService.updateListField(this.user.myLists[i].listId, {
              owner: {
                email: this.editedUser.email,
                name: this.editedUser.name,
                userId: this.editedUser.userId
              }
            });
          }
          ownerListsSub.unsubscribe();
        });
      }
    }

    if(this.user.adminLists) {
      for (let i = 0; i < this.user.adminLists.length; i++) {
        let adminListsSub = this.databaseService.getListByListId(this.user.adminLists[i].listId).subscribe(list => {
          if(list.admin){
            for (let x = 0; x < list.admin.length; x++) {
              if(list.admin[x].userId == this.user.userId) {
                list.admin[x].name = this.editedUser.name;
                list.admin[x].email = this.editedUser.email;
                break;
              };
            }                
          }
          if(list.items) {
            for (let x = 0; x < list.items.length; x++) {
              if(list.items[x].purchasedBy) {
                for (let y = 0; y < list.items[x].purchasedBy.length; y++) {
                  if(list.items[x].purchasedBy[y].userId == this.user.userId) {
                    list.items[x].purchasedBy[y].email = this.editedUser.email;
                    list.items[x].purchasedBy[y].name = this.editedUser.name;
                    break;
                  };
                }
              }
            }
            this.databaseService.updateListField(this.user.adminLists[i].listId, {
              admin: list.admin,
              items: list.items
            }); 
          } else {
            this.databaseService.updateListField(this.user.adminLists[i].listId, {
              admin: list.admin
            }); 
          }
          adminListsSub.unsubscribe();
        });
      }
    }


    if(this.user.friendsLists) {
      for (let i = 0; i < this.user.friendsLists.length; i++) {
        let friendsListsSub = this.databaseService.getListByListId(this.user.friendsLists[i].listId).subscribe(list => {
          if(list.givers){
            for (let x = 0; x < list.givers.length; x++) {
              if(list.givers[x].userId == this.user.userId) {
                list.givers[x].name = this.editedUser.name;
                list.givers[x].email = this.editedUser.email;
                break;
              };
            }
          }
          if(list.items) {
            for (let x = 0; x < list.items.length; x++) {
              if(list.items[x].purchasedBy) {
                for (let y = 0; y < list.items[x].purchasedBy.length; y++) {
                  if(list.items[x].purchasedBy[y].userId == this.user.userId) {
                    list.items[x].purchasedBy[y].email = this.editedUser.email;
                    list.items[x].purchasedBy[y].name = this.editedUser.name;
                    break;
                  };
                }
              }
            }
            this.databaseService.updateListField(this.user.friendsLists[i].listId, {
              givers: list.givers,
              items: list.items
            });  
          } else {
            this.databaseService.updateListField(this.user.friendsLists[i].listId, {
              givers: list.givers
            });  
          }
          friendsListsSub.unsubscribe();
        });
      }
    }

    if(this.user.listedAsFriend) {
      for (let i = 0; i < this.user.listedAsFriend.length; i++) {
        let listedAsFriendSub = this.databaseService.getUserByUID(this.user.listedAsFriend[i]).subscribe(user => {
          for (let x = 0; x < user.friends.length; x++) {
            if(this.user.userId == user.friends[x].userId) {
              user.friends[x].name = this.editedUser.name;
              user.friends[x].email = this.editedUser.email;
              break;
            };
          }
          this.databaseService.updateUserField(user.userId, {friends: user.friends});
          listedAsFriendSub.unsubscribe();
        });
      }
    }
  }

  // A function that basically refreshes the page for use as needed
  refreshComponent(route: string) {
    this.router.navigateByUrl('/RefreshComponent', {skipLocationChange: true}).then(()=> {
      this.router.navigate([route]);
    }); 
  }

  /**
   * Changes the list type of the editedList when the user selects a new one from the dropdown
   * Toggles the dropdown element
   * @param index is the index number of the selected list type of the `dbRef.PROFILE_TEXT_NOTIFICATION_PREFERENCE_OPTIONS` array
   */
  selectNewNotificationPreference(index: number) {
    this.notificationPrefDropdownCollapsed = !this.notificationPrefDropdownCollapsed;
    this.editedUser.notificationIndex = index;
  }

  
  /**
   * Changes the list type of the editedList when the user selects a new one from the dropdown
   * Toggles the dropdown element
   * @param index is the index number of the selected list type of the `dbRef.PROFILE_TEXT_NOTIFICATION_PREFERENCE_OPTIONS` array
   */
  selectNewLanguagePreference(index: number) {
    this.languagePrefDropdownCollapsed = !this.languagePrefDropdownCollapsed;
    this.editedUser.languageIndex = index;
    if(index == this.genRef.DB_LANGUAGE_PREF_SPANISH) {
      this.alertsService.showNewAlert({
        message: "Has elegido el español como tu idioma preferido. Estamos trabajando en el desarrollo de la traducción al español para esta aplicación, y tan pronto como esté disponible, notará el cambio sin que sea necesaria ninguna otra acción. Esperamos que esté disponible en las próximas semanas.",
        duration: 25000,
        class: this.genRef.ALERTS_CLASS_WARNING
      });
    }
  }

  deleteUser() {
    console.log("User being deleted");

    this.databaseService.deleteUser(this.user.userId, {
      email: this.user.email,
      userId: this.user.userId,
      numTotalNotifications: this.user.numTotalNotifications
    }).then(res => {
      console.log("The deleted user data has been added");
      //Delete from Firebase Auth
      this.authService.deleteUser(this.firebaseUser).then(res => {
        console.log("The user has been deleted from firebase Auth");
        this.router.navigate([this.genRef.ROUTES_HOME]);
        this.authService.signout();
      }).catch(err => {
        console.log(err);
      });
    }).catch(err => {
      console.log(err);
    });
    this.modalService.dismissAll("Confirmed Delete");
  }

  /**
   * Enables authEditMode, which allows the owner or admin to update the email or password
   */
  enableAuthEditMode() {
    this.authEditMode = true;
  }

  enableDeleteUserAuthMode() {
    this.deleteAuthEditMode = true;
  }

  cancelAuthEdit() {
    this.authEditMode = false;
    this.deleteAuthEditMode = false;
  }

  enableChangePasswordMode() {
    this.changePasswordMode = true;
    this.editMode = false;
  }

  cancelChangePasswordMode() {
    this.changePasswordMode = false;
    this.editMode = true;
    this.showUnableToSavePasswordMsg = false;
    this.newPassword = null;
    this.currentPassword = null;
  }

  saveNewPassword() {
    if(this.showUnableToSavePasswordMsg){
      return;
    } else {
      console.log("Updating password")
      this.authService.changePassword(this.firebaseUser, this.confirmPassword).then(res => {
        console.log("The password has been updated: " + this.confirmPassword);
        // Refresh page so that it prompts password keeper on browser to update password/email
        this.refreshComponent(this.genRef.ROUTES_PROFILE);
      }).catch(err => {
        console.log(err);
      });
      this.cancelChangePasswordMode();
    }
  }
  

  // Checks if the user's input password is valid and calls reauthentication function if so
  reAuthenticateUser() {
    // Get a FirebaseAuth User Object (firebase.User object) from the authService
    let authSub = this.authService.getAuthState().subscribe(user => {
      this.firebaseUser = user;
      // Call a function that verifies the user's credentials
      this.reAuthUser( user, this.currentPassword);
      this.currentPassword = null;
      authSub.unsubscribe();
    });
  }

  // Sends a FirebaseAuth User object and a user input password to Firebase for reauthentication
  reAuthUser(user: firebase.User, password: string) {
    this.authService.reAuthenticateWithPassword(user, password).then(() => {
      //User re-authorized, allow edits to Account settings by enabling authorizedEditMode
      if(this.authEditMode){
        this.authorizedEditMode = true;
      } else if (this.deleteAuthEditMode) {
        this.deleteAuthorizedEditMode = true;
      }
      console.log("user reauthorized");
    }).catch((err)=> {
      //Error, cancel authEditMode
      this.cancelAuthEdit();
      // Notify user of error

      console.log("there was a problem reauthenticating user" + err);
    });
  }


  // Checks the two new password inputs match exactly to prevent user from miskeying password
  passwordsMatch(): void {
    if(this.newPassword == this.confirmPassword) {
      this.showUnableToSavePasswordMsg = false;
    } else {
      this.showUnableToSavePasswordMsg = true;
    }
  }


  /**
   * MODAL-RELATED FUNCTIONS:
   */
  open(content) {
    this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
