import { Component, OnInit } from '@angular/core';
import { GENERAL_REF } from '../../dbReferences';
import { DatabaseService } from 'src/app/services/database.service';
import { User } from '../../models/User';
import { Router, ActivatedRoute } from '@angular/router';
import { List } from 'src/app/models/List';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.css']
})
export class ListsComponent implements OnInit {

  genRef;  //contains general references needed for the database/app
  dbRef; //contains text, phrases and other referencces needed for the database/app - pulled in the user's preferred language
  user: User;  //the User object for the current user
  list: List; //the original List object directly from the database that can't be changed via the UI unless changes are sent to the database first.
  private listString: string; //variable for singular list for html doc
  private listsString: string; //variable for plural list for html doc
  private forString: string; //variable for html doc
  private giverString: string; //variable for singular giver for html doc
  private giversString: string; //variable for plural giver for html doc
  closeResult: string;  // Used by modal to indicate why/how it was closed
  indexSelectedToRemove: number //The index number of the list selected to be removed from user's admin or friends lists


  constructor(
    private databaseService: DatabaseService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    //Get a reference to the general and language specific database references
    this.genRef = GENERAL_REF;
    this.databaseService.dbRef.subscribe(ref => {
      this.dbRef = ref;
      this.listString = this.dbRef.DASHBOARD_TEXT_LIST_SINGULAR;
      this.listsString = this.dbRef.DASHBOARD_TEXT_LISTS_SINGULAR;
      this.forString = this.dbRef.DASHBOARD_TEXT_FOR;
      this.giverString = this.dbRef.DASHBOARD_TEXT_GIVER_SUBSCRIBED_SINGULAR;
      this.giversString = this.dbRef.DASHBOARD_TEXT_GIVERS_SUBSCRIBED_PLURAL;
    });
    //get the user Object from the User Behavior Subject
    this.databaseService.activeUser.subscribe(user => {
      this.user = user;
    });
  }

    /**
   * Deletes the specified admin from the `editedList.admin` array
   * Database is immediately updated with deleted admin, so user does not need to save changes to take effect   * 
   * @param index is the index number of the selected admin from the `editedList.admin` array
   */
  deleteAdminList() {
    if (this.user.adminLists){

      let listSub = this.databaseService.getListByListId(this.user.adminLists[this.indexSelectedToRemove].listId).subscribe(list => {
        if(list) {
          if(list.admin) {
            for (let i = 0; i < list.admin.length; i++) {
              if(list.admin[i].userId == this.user.userId) {
                list.admin.splice(i,1);
                break;
              }
            }
          }
        }
        this.databaseService.updateListField(
          list.listId, 
          {admin: list.admin},
          {
            message: list.name + " was removed from your admin lists.",
            duration: this.genRef.ALERTS_DURATION_STANDARD,
            class: this.genRef.ALERTS_CLASS_SUCCESS
          },
          {
            message: "There was a problem removing the list.",
            duration: this.genRef.ALERTS_DURATION_STANDARD,
            class: this.genRef.ALERTS_CLASS_DANGER
          }
        );
        listSub.unsubscribe();
      });

      this.user.adminLists.splice(this.indexSelectedToRemove,1);
      this.databaseService.updateUserField(this.user.userId, {adminLists: this.user.adminLists});
      this.modalService.dismissAll();
    }
  }

      /**
   * Deletes the specified giver from the `editedList.givers` array
   * Database is immediately updated with deleted giver, so user does not need to save changes to take effect   * 
   * @param index is the index number of the selected giver from the `editedList.givers` array
   */
  deleteFriendsList() {
    if (this.user.friendsLists){
      let listSub = this.databaseService.getListByListId(this.user.friendsLists[this.indexSelectedToRemove].listId).subscribe(list => {
        if(list) {
          if(list.givers) {
            for (let i = 0; i < list.givers.length; i++) {
              if(list.givers[i].userId == this.user.userId) {
                list.givers.splice(i,1);
                break;
              }
            }
          }
        }

        this.databaseService.updateListField(
          list.listId, 
          {givers: list.givers},
          {
            message: list.name + " was removed from your friends lists.",
            duration: this.genRef.ALERTS_DURATION_STANDARD,
            class: this.genRef.ALERTS_CLASS_SUCCESS
          },
          {
            message: "There was a problem removing the list.",
            duration: this.genRef.ALERTS_DURATION_STANDARD,
            class: this.genRef.ALERTS_CLASS_DANGER
          }
        );
        listSub.unsubscribe();
      });

      let ownerSub = this.databaseService.getUserByUID(this.user.friendsLists[this.indexSelectedToRemove].ownerUserId).subscribe(owner => {
        if(owner.myLists) {
          for (let i = 0; i < owner.myLists.length; i++) {
            if(owner.myLists[i].listId == this.user.friendsLists[this.indexSelectedToRemove].listId) {
              owner.myLists[i].numGivers -= 1;
              break;
            }
          }
        }
        this.databaseService.updateUserField(owner.userId, {myLists: owner.myLists});
        ownerSub.unsubscribe();
      });

      this.user.friendsLists.splice(this.indexSelectedToRemove,1);
      this.databaseService.updateUserField(this.user.userId, {friendsLists: this.user.friendsLists});
      this.modalService.dismissAll();
    }
  }

    /**
   * Function to open the modal in the UI
   * @param content is the specific modal to be opened
   * @param index is the index number of the friend that was selected from the array
   */
  open(content, index) {
    this.indexSelectedToRemove = index;
    this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.indexSelectedToRemove = null;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.indexSelectedToRemove = null;
    });
  }

  /**
   * Creates a string describing how/why the modal was closed
   * @param reason is the reason passed in from the modal, depending on how it was closed
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
